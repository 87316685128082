
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IuC4reC4reC4geC4iOC4suC4geC4o+C4sOC4muC4miJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IuC4reC4reC4geC4iOC4suC4geC4o+C4sOC4muC4miJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IktlbHVhciJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IktlbHVhciJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IuC4reC4reC4geC4iOC4suC4geC4o+C4sOC4muC4miJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IuC4reC4reC4geC4iOC4suC4geC4o+C4sOC4muC4miJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IktlbHVhciJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IktlbHVhciJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlNpZ24gb3V0In0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlvFoMOsw6zDrMSj4LiB4Li14LmJIMO2w7bDtseax5rHmuG5r10ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTSUdOX09VVCI6IlvFoMOsw6zDrMSj4LiB4Li14LmJIMO2w7bDtseax5rHmuG5r10ifQ==!"
        )
      )
      });
  
      export { translations as default };
    