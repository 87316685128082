import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectors as userSelectors } from '../../store/user';
import useACL from '../useACL';

import Context from './Context';
import type { Experiment } from './Experiment';
import type { ExperimentWithBucket, ContextType } from './types';

interface Props {
  experiments: Experiment[];
  children: React.ReactNode;
}

function ExperimentProvider({ children, experiments }: Props) {
  const [experimentsWithBuckets, setExperimentsWithBuckets] = useState<
    ExperimentWithBucket[]
  >([]);

  const { getProductType, getProductConfig } = useACL();

  const hasAllUserContextAvailable = useSelector(
    userSelectors.getHasAllRequiredUserContextSelector,
  );
  const advertiserId = useSelector(userSelectors.getAdvertiserIdSelector);

  useEffect(() => {
    if (!hasAllUserContextAvailable) {
      return;
    }

    const _experiments = experiments.map((experiment) => {
      const isActive = experiment.isActive({
        featureFlags: getProductConfig().featureFlags,
        productType: getProductType(),
      });

      const bucket = experiment.getBucket({ advertiserId });

      return {
        experimentName: experiment.name,
        bucketName: bucket?.name || null,
        template: bucket?.template || null,
        isActive: Boolean(isActive && bucket),
      };
    });

    setExperimentsWithBuckets(_experiments);
  }, [
    advertiserId,
    experiments,
    getProductConfig,
    getProductType,
    hasAllUserContextAvailable,
  ]);

  const context = useMemo<ContextType>(() => {
    const getExperimentByName: ContextType['getExperimentByName'] = (name) => {
      const experiment = experimentsWithBuckets.find(
        ({ experimentName, isActive }) => experimentName === name && isActive,
      );
      return experiment || null;
    };
    const getExperimentValueByName: ContextType['getExperimentValueByName'] = (
      name,
    ) => {
      const experiment = getExperimentByName(name);
      if (!experiment?.isActive) {
        return null;
      }
      const { experimentName, bucketName, template } = experiment;
      return `${experimentName}_${bucketName}${template ? `|${template}` : ''}`;
    };
    const isExperimentRunning: ContextType['isExperimentRunning'] = (
      name,
      bucket,
    ) => {
      const experiment = getExperimentByName(name);
      return experiment?.bucketName === bucket;
    };
    return {
      getExperimentByName,
      getExperimentValueByName,
      isExperimentRunning,
    };
  }, [experimentsWithBuckets]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default ExperimentProvider;
