import { useEffect, useMemo, useRef, useState } from 'react';

import Context from './Context';

const TARGET = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
} as const;

export const MEDIA_QUERY = {
  mobile: '(max-width: 739px)',
  tablet: '(min-width: 740px) and (max-width: 991px)',
  desktop: '(min-width: 992px)',
} as const;

type MediaQueryKeys = keyof typeof MEDIA_QUERY;

interface Props {
  children: React.ReactNode;
}

const initialState = {
  mobile: undefined,
  tablet: undefined,
  desktop: undefined,
};

function listener(
  mediaQueryKey: MediaQueryKeys,
  callback: (mediaQueryKey: MediaQueryKeys) => void,
) {
  return ({ matches }: { matches: boolean }) => {
    if (matches) {
      callback(mediaQueryKey);
    }
  };
}

export const sideEffects = {
  matchMedia: (mediaQuery: string) =>
    typeof window !== 'undefined' ? window.matchMedia(mediaQuery) : undefined,
};

function MediaQueryProvider({ children }: Props) {
  const mediaQueryList = useRef<
    Record<MediaQueryKeys, MediaQueryList | undefined>
  >(
    Object.entries(MEDIA_QUERY).reduce(
      (acc, [key, mediaQuery]) => ({
        ...acc,
        [key]: sideEffects.matchMedia(mediaQuery),
      }),
      initialState,
    ),
  );
  const [match, setMatch] = useState<MediaQueryKeys | null>(null);

  useEffect(() => {
    const _mediaQueryList = mediaQueryList.current;

    (Object.keys(_mediaQueryList) as MediaQueryKeys[]).forEach(
      (mediaQueryKey) => {
        _mediaQueryList[mediaQueryKey]?.addEventListener(
          'change',
          listener(mediaQueryKey, setMatch),
        );

        if (_mediaQueryList[mediaQueryKey]?.matches) {
          setMatch(mediaQueryKey);
        }
      },
    );

    return () => {
      (Object.keys(_mediaQueryList) as MediaQueryKeys[]).forEach(
        (mediaQueryKey) => {
          _mediaQueryList[mediaQueryKey]?.removeEventListener(
            'change',
            listener(mediaQueryKey, setMatch),
          );
        },
      );
    };
  }, []);

  const value = useMemo(
    () => ({
      isMobile: match === TARGET.MOBILE,
      isTablet: match === TARGET.TABLET,
      isDesktop: match === TARGET.DESKTOP,
    }),
    [match],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default MediaQueryProvider;
