import { useEffect } from 'react';

type EventCallback = (event: Event) => void;

interface Props {
  children: React.ReactNode;
  keyMap: Record<string, EventCallback | null>;
}

const DocumentKeyEventHandler = ({ keyMap, children }: Props) => {
  useEffect(() => {
    const handleEvent = (event: Event) => {
      if (!(event instanceof KeyboardEvent)) {
        return;
      }
      return keyMap[event.key]?.(event);
    };

    document.addEventListener('keydown', handleEvent, false);

    return () => {
      document.removeEventListener('keydown', handleEvent, false);
    };
  }, [keyMap]);

  return <>{children}</>;
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default DocumentKeyEventHandler;
