export interface Theme {
  drawerClassName?: string;
  drawerEnterClassName?: string;
  drawerExitClassName?: string;
  overlayClassName?: string;
  overlayEnterClassName?: string;
  overlayExitClassName?: string;
  drawerScrollClassName?: string;
}

export type OpenFrom = 'right' | 'left' | 'top';

export enum AnimationState {
  Exited = 'exited',
  Entered = 'entered',
  Entering = 'entering',
  Exiting = 'exiting',
}

export enum Timeout {
  AppearTimeoutMS = 300,
  ExitTimeoutMS = 600,
  ScrollRestoreMS = 400,
}
