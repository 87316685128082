import type { TalentSearchJobV2 } from '../../types';

import * as actions from './actions';

export const updateJobDetails = (job: TalentSearchJobV2) => ({
  type: actions.UPDATE_JOB_DETAILS,
  value: { jobDetails: job },
});

export const clearJobDetails = () => ({
  type: actions.CLEAR_JOB_DETAILS,
  value: null,
});
