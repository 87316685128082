import { Environment } from 'src/types';

export const defaultDatadogConfig = {
  applicationId: '',
  clientToken: '',
  service: '',
};

export const datadogConfig = {
  [Environment.Production]: {
    applicationId: '304131ae-97b0-45d6-b5ee-c62a5043a0fa',
    clientToken: 'pub8089cca7d121f5e3dca9e09d3e26cf22',
    service: 'talent-search-web-ui',
  },
  [Environment.Staging]: defaultDatadogConfig,
  [Environment.Development]: defaultDatadogConfig,
} as const;
