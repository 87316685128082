import type { MetadataIndustry, MetadataQueryValidations } from '../../types';

import * as actions from './actions';

export const updateIndustries = (industries: MetadataIndustry[]) => ({
  type: actions.UPDATE_INDUSTRIES_DATA,
  value: { industries },
});

export const updateUncoupledQueryValidations = (
  validations: MetadataQueryValidations,
) => ({
  type: actions.UPDATE_UNCOUPLED_QUERY_VALIDATIONS_DATA,
  value: { validations },
});
