export enum Config {
  PROFILES_PER_PAGE = 20,
  SIMILAR_CANDIDATES_PER_PROFILE = 4,
}

export enum CriteriaKey {
  Approachable = 'approachable',
  Keywords = 'keywords',
  CV = 'cv',
  Company = 'company',
  SalaryMin = 'minSalary',
  SalaryMax = 'maxSalary',
  SalaryNation = 'salaryNation',
  SalaryType = 'salaryType',
  SalaryUnspecified = 'salaryUnspecified',
  // DEPRECATED: will be removed when gRTW is released
  Visa = 'visa',
  LastUpdated = 'lastUpdated',
  Industry = 'industry',
  LocationList = 'locationList',
  WillingToRelocate = 'willingToRelocate',
  WorkType = 'workType',
  SortBy = 'sortBy',
  PageNumber = 'pageNumber',
  Nation = 'nation',
  UncoupledFreeText = 'uncoupledFreeText',
  ProfileTypes = 'profileTypes',
  RightToWorkIds = 'rightToWorkIds',
  RightToWorkUnspecified = 'rightToWorkUnspecified',
}
export interface Criteria {
  [CriteriaKey.Approachable]?: boolean;
  [CriteriaKey.Industry]?: string[];
  [CriteriaKey.Company]?: string[];
  [CriteriaKey.CV]?: boolean;
  [CriteriaKey.Keywords]?: string;
  [CriteriaKey.LastUpdated]?: string;
  [CriteriaKey.LocationList]?: string;
  [CriteriaKey.SalaryMax]?: number;
  [CriteriaKey.SalaryMin]?: number;
  [CriteriaKey.Nation]?: number;
  [CriteriaKey.SalaryNation]?: number;
  [CriteriaKey.SalaryType]?: string;
  [CriteriaKey.SalaryUnspecified]?: boolean;
  [CriteriaKey.UncoupledFreeText]?: string;
  // DEPRECATED: will be removed when gRTW is released
  [CriteriaKey.Visa]?: string[];
  [CriteriaKey.WorkType]?: string[];
  [CriteriaKey.WillingToRelocate]?: boolean;
  [CriteriaKey.ProfileTypes]?: string[];
  [CriteriaKey.PageNumber]?: number;
  [CriteriaKey.SortBy]?: string;
  [CriteriaKey.RightToWorkIds]?: string[];
  [CriteriaKey.RightToWorkUnspecified]?: boolean;
}

export type UnparsedCriteria = Record<CriteriaKey, string | null>;

export enum SearchType {
  NewSearch = 'new_search',
  SavedSearch = 'saved_search',
  RecentSearch = 'recent_search',
}

export enum SortType {
  Relevance = 'relevance',
  DateUpdatedAndRelevance = 'freshness',
  DateUpdated = 'modifiedDate',
  DateCreated = 'createdDate',
}

export enum WorkTypeOption {
  FullTime = 'fullTime',
  PartTime = 'partTime',
  Contract = 'contract',
  Casual = 'casual',
  Unspecified = 'unspecified',
}

export enum RightToWorkOption {
  True = 'true',
  False = 'false',
  Unspecified = 'unspecified',
}

export enum LastUpdatedOption {
  ThreeMonths = 3,
  SixMonths = 6,
}

export interface RecentSearch {
  key: string;
  name: string;
  locationsText: string;
  filtersText: string;
  criteria: Record<string, unknown>;
}
