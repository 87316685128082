import canAccessCv from '../../../services/profile/canAccessCv';
import canExportProfile from '../../../services/profile/canExportProfile';
import canSendJob from '../../../services/profile/canSendJob';
import canSendMessage from '../../../services/profile/canSendMessage';
import { selectors as balanceSelectors } from '../../../store/balance';
import { selectors as userSelectors } from '../../../store/user';

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default function getConnectionTypesAvailableSelector(
  snapshot: any,
  profile: any,
) {
  const creditRates = userSelectors.getAllCreditRatesSelector(snapshot);
  const credits = balanceSelectors.getCreditBalanceSelector(snapshot);

  return [
    canSendJob({ ...profile, credits, creditRates })
      ? 'send_job'
      : 'unavailable',
    canSendMessage({ ...profile, credits, creditRates })
      ? 'message'
      : 'unavailable',
    canAccessCv({ ...profile, credits, creditRates })
      ? 'access_cv'
      : 'unavailable',
    canExportProfile({ ...profile, credits, creditRates })
      ? 'profile_export'
      : 'unavailable',
  ];
}
