import { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectors as userSelectors } from '../../store/user';
import useAuth, { type Identity } from '../useAuth';

import Context from './Context';
import type { ContextType, Details } from './types';

interface Props {
  children: React.ReactNode;
}

const extractIdentityDetails = (
  identity: Identity,
): {
  actorEmail: string | null;
  actAsEmail: string | null;
  actAsId: string | null;
} => ({
  actorEmail: identity?.act?.email || null,
  actAsEmail: identity?.sub?.email || null,
  actAsId: identity?.sub?.ID || null,
});

function ImpersonationProvider({ children }: Props) {
  const { getIdentity } = useAuth();

  const hasUserData = useSelector(userSelectors.getInitialDataStateSelector);

  const [isImpersonating, setIsImpersonating] = useState<boolean>(false);

  const detailsRef = useRef<Details | null>(null);

  useEffect(() => {
    (async () => {
      if (!hasUserData) {
        return;
      }

      const { actorEmail, actAsEmail, actAsId } = extractIdentityDetails(
        await getIdentity(),
      );

      //  Ensure we have all of the things we need
      //  before we can safely say the user is being impersonated
      if (!actorEmail || !actAsEmail || !actAsId) {
        return;
      }

      detailsRef.current = {
        actorEmail,
        actAsEmail,
        actAsId,
      };

      setIsImpersonating(true);
    })();
  }, [hasUserData, getIdentity]);

  const context = useMemo<ContextType>(
    () => ({
      isImpersonating,
      getDetails: () => detailsRef.current,
    }),
    [isImpersonating],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default ImpersonationProvider;
