import {
  CUSTOMER_SERVICE_PHONE_NUMBER,
  EMPLOYER_CONTACT_US_URL,
} from '@seek/shared-constants';
import { useTranslations } from '@vocab/react';
import { Heading, Stack, Strong, Text, TextLink } from 'braid-design-system';

import useAppConfig from 'src/hooks/useAppConfig';

import translations from '../.vocab';
import type { ErrorMessageTranslationKey } from '../types';

interface Props {
  titleKey: string;
  translationKey: ErrorMessageTranslationKey;
  errorCode?: string;
}

function ErrorMessageText({ titleKey, translationKey, errorCode }: Props) {
  const { SITE: site } = useAppConfig();
  const CS_LINK = EMPLOYER_CONTACT_US_URL[site];

  const isAnz = site === 'employer-seek-au' || site === 'employer-seek-nz';
  const customerSupportPhoneNumber = isAnz
    ? CUSTOMER_SERVICE_PHONE_NUMBER[site]
    : '';

  const { t } = useTranslations(translations);

  let translatedTitle;

  switch (titleKey) {
    case 'SESSION_TIMED_OUT_TITLE':
      translatedTitle = t('SESSION_TIMED_OUT_TITLE');
      break;
    case 'GENERIC_ERROR_TITLE':
      translatedTitle = t('GENERIC_ERROR_TITLE');
      break;
    case 'UNAUTHORISED_TITLE':
      translatedTitle = t('UNAUTHORISED_TITLE');
      break;
    case 'UNAUTHORISED_GENERIC_TITLE':
      translatedTitle = t('UNAUTHORISED_GENERIC_TITLE');
      break;
    case 'SEARCH_ERROR_TITLE':
      translatedTitle = t('SEARCH_ERROR_TITLE');
      break;
    case 'SEARCH_TALENT_POOL_ERROR_TITLE':
      translatedTitle = t('SEARCH_TALENT_POOL_ERROR_TITLE');
      break;
    default:
      translatedTitle = t('GENERIC_ERROR_TITLE');
  }

  let translatedText;
  switch (translationKey) {
    case 'DEFAULT_SESSION_TIMED_OUT_ERROR_MESSAGE':
      translatedText = t('DEFAULT_SESSION_TIMED_OUT_ERROR_MESSAGE', {
        br: () => <br />,
      });
      break;
    case 'INVALID_STATE_ERROR_MESSAGE':
      translatedText = t('INVALID_STATE_ERROR_MESSAGE', { br: () => <br /> });
      break;
    case 'UNAUTHORIZED_FORBIDDEN_ACCESS_ERROR_MESSAGE':
      translatedText = t('UNAUTHORIZED_FORBIDDEN_ACCESS_ERROR_MESSAGE', {
        br: () => <br />,
        CSLink: (children) => <TextLink href={CS_LINK}>{children}</TextLink>,
        Strong1: () => <Strong>{errorCode!.toUpperCase()}</Strong>,
      });
      break;
    case 'UNAUTHORIZED_DEFAULT_ERROR_MESSAGE':
      translatedText = t('UNAUTHORIZED_DEFAULT_ERROR_MESSAGE', {
        CSLink: (children) => <TextLink href={CS_LINK}>{children}</TextLink>,
        Strong1: () => <Strong>{errorCode!.toUpperCase()}</Strong>,
      });
      break;
    case 'UNKNOWN_ERROR_MESSAGE':
      translatedText = t('UNKNOWN_ERROR_MESSAGE', {
        br: () => <br />,
        CSLink: (children) => <TextLink href={CS_LINK}>{children}</TextLink>,
        Strong1: () => <Strong>{errorCode!.toUpperCase()}</Strong>,
      });
      break;
    case 'SEARCH_ERROR':
      translatedText = isAnz
        ? t('SEARCH_ERROR_ANZ', {
            CSPhone: () => (
              <TextLink href={`tel:${customerSupportPhoneNumber}`}>
                {customerSupportPhoneNumber}
              </TextLink>
            ),
            Strong1: () => <Strong>{errorCode!.toUpperCase()}</Strong>,
          })
        : t('SEARCH_ERROR', {
            CSLink: (children) => (
              <TextLink href={CS_LINK}>{children}</TextLink>
            ),
            Strong1: () => <Strong>{errorCode!.toUpperCase()}</Strong>,
          });

      break;
  }

  return (
    <Stack space="medium" align="center" data={{ testid: 'error-message' }}>
      <Heading level="3" align="center">
        {translatedTitle}
      </Heading>
      <Text align="center" tone="secondary">
        <>{translatedText}</>
      </Text>
    </Stack>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default ErrorMessageText;
