import type { SelectedLocation } from 'src/scenes/Search/SearchPanel/types';

import { TEALIUM_KEY } from '../tealium';
import { Search as SearchLinks, UtagActions } from '../tealium/types';

const actions = {
  saveSearch: () => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = SearchLinks.SaveSearch;

        return {
          action: UtagActions.Link,
          value: {
            eventName,
          },
        };
      },
    },
  ],
  suggestedLocationClick: (
    suggestedLocation: SelectedLocation,
    searchId?: string,
  ) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = SearchLinks.SuggestedLocationClick;

        return {
          action: UtagActions.Link,
          value: {
            eventName,
            searchSuggestedLocation: suggestedLocation.text,
            searchSuggestedLocationId: suggestedLocation.value,
            searchSuggestedLocationLevel: suggestedLocation.type,
            ...(searchId ? { searchId } : {}),
          },
        };
      },
    },
  ],
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default actions;
