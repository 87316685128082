import { useContext } from 'react';

import Context from './Context';
import type { ContextType } from './types';

function useDatadogRum() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useDatadogRum must be called from within DatadogRumProvider',
    );
  }

  return context as ContextType;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default useDatadogRum;
