export const UPDATE_ASSIGNED_POOLS_IN_PROFILE =
  'PROFILES/UPDATE_ASSIGNED_POOLS_IN_PROFILE';
export const UPDATE_ADD_OR_REMOVED_PROFILE_IN_POOLS =
  'UPDATE_ADD_OR_REMOVED_PROFILE_IN_POOLS';
export const UPDATE_LATEST_INTERACTION_IN_PROFILE =
  'PROFILES/UPDATE_LATEST_INTERACTION_IN_PROFILE';
export const REMOVE_PROFILES = 'PROFILES/REMOVE_PROFILES';

export const PROFILES_RECEIVED = 'PROFILES/PROFILES_RECEIVED';
export const UPDATE_ALL_ASYNC_STATE = 'PROFILES/UPDATE_ALL_ASYNC_STATE';
export const UPDATE_ASYNC_ERROR_STATE = 'PROFILES/UPDATE_ASYNC_ERROR_STATE';

export const CONNECTION_REQUESTED = 'PROFILES/CONNECTIONS/CONNECTION_REQUESTED';
export const CONNECTION_REDEEMED = 'PROFILES/CONNECTIONS/CONNECTION_REDEEMED';
export const UNCOUPLED_SEND_JOB_REDEEMED =
  'PROFILES/CONNECTIONS/UNCOUPLED_SEND_JOB_REDEEMED';
export const CONNECTION_FAILED = 'PROFILES/CONNECTIONS/CONNECTION_FAILED';
