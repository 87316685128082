export const PAGE_SIZE = 20;

export interface TalentSearchInteractionsConnection {
  pageInfo: PageInfo;
  edges: TalentSearchInteractionEdge[];
  totalCount: number;
}

export interface PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string;
  endCursor?: string;
}

export interface TalentSearchInteractionEdge {
  node: TalentSearchInteraction;
  cursor: string;
}

export interface TalentSearchInteraction {
  id: string;
  interactionType: string;
  positionProfileId?: string;
  statusCode?: string;
  seekSourceName?: string;
  associatedParties: AssociatedParty[];
  associatedAction: AssociatedAction;
}

export interface AssociatedParty {
  organizationName?: string;
  firstName?: string;
  lastName?: string;
  formattedName?: string;
}

export interface AssociatedAction {
  code: string;
  description?: string;
  date: string;
  subject?: string;
  job?: Job;
}

export interface Job {
  title: string;
  location?: string;
}

export enum InteractionType {
  ATS = 'ATS',
  SEEK = 'SEEK',
}
