import { Environment } from 'src/types';

export const trackingConfig = {
  [Environment.Production]: {
    url: 'https://tracking.engineering.cloud.seek.com.au',
  },
  [Environment.Staging]: {
    url: 'https://tracking.dev-engineering.cloud.seek.com.au',
  },
  [Environment.Development]: { url: 'http://localhost:9003/api/metrics' },
} as const;
