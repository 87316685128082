import canAccessCv from './canAccessCv';
import canExportProfile from './canExportProfile';
import canSendJob from './canSendJob';
import canSendMessage from './canSendMessage';
import getCvPreviewStatus from './getCvPreviewStatus';
import getLastInteractionTimestamp from './getLastInteractionTimestamp';
import getSimilarCandidatesDataFromProfile from './getSimilarCandidatesDataFromProfile';
import hasLocationFoundInCvOnSummary from './hasLocationFoundInCvOnSummary';
import hasRoleFoundInCV from './hasRoleFoundInCV';
import isApproachable from './isApproachable';
import showLocationFoundInCv from './showLocationFoundInCv';

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default {
  canSendJob,
  canSendMessage,
  canAccessCv,
  canExportProfile,
  getCvPreviewStatus,
  isApproachable,
  showLocationFoundInCv,
  getSimilarCandidatesDataFromProfile,
  getLastInteractionTimestamp,
  hasRoleFoundInCV,
  hasLocationFoundInCvOnSummary,
};
