import useDatadogRum from 'src/hooks/useDatadogRum';

import { ErrorTypes } from '../../../../types';
import ErrorMessage from '../../../UI/ErrorMessage';

import ErrorBoundaryHandler from './ErrorBoundaryHandler';

interface Props {
  children: React.ReactNode;
}

function ErrorBoundary({ children }: Props) {
  const { logError } = useDatadogRum();
  return (
    <ErrorBoundaryHandler logError={logError}>
      {({ hasError }) =>
        hasError ? (
          <ErrorMessage isFixedCenter errorType={ErrorTypes.UNKNOWN} />
        ) : (
          children
        )
      }
    </ErrorBoundaryHandler>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default ErrorBoundary;
