import { formatEmptyLegacySearchCriteria } from '../../services/savedSearches';
import type { Action, SavedSearch } from '../../types';

import * as actions from './actions';

interface State {
  searches: SavedSearch[];
  lastFetchedTimestamp: number | null;
}

const initialState: State = {
  searches: [],
  lastFetchedTimestamp: null,
};

export const dateHelper = {
  createTimestamp: () => new Date().getTime(),
};

const filterItemInSearchesArray = (
  { searches }: { searches: SavedSearch[] },
  criteriaHash: string,
): SavedSearch[] =>
  searches.filter((search) => search.criteriaHash !== criteriaHash);

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actions.RECEIVE_SAVED_SEARCHES_DATA:
      const { savedSearches } = action.value;

      return {
        ...state,
        searches: savedSearches,
        lastFetchedTimestamp: dateHelper.createTimestamp(),
      };

    case actions.ADD_SAVED_SEARCH:
      const { savedSearch } = action.value;
      return {
        ...state,
        searches: [
          ...state.searches,
          ...formatEmptyLegacySearchCriteria([savedSearch]),
        ],
      };

    case actions.REMOVE_SAVED_SEARCH: {
      const { criteriaHash } = action.value;
      return {
        ...state,
        searches: filterItemInSearchesArray(state, criteriaHash),
      };
    }

    default:
      return state;
  }
};
