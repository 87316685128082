import { createContext } from 'react';

export interface MediaQuery {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default createContext<MediaQuery>({
  isMobile: false,
  isTablet: false,
  isDesktop: false,
});
