// Default exports can be ignored for this file. sku templates provide default exports as standard here
/* eslint-disable import/no-default-export */

import 'braid-design-system/reset';
import { HelmetProvider } from 'react-helmet-async';
import type loadable from 'sku/@loadable/component';

import { ACLProvider } from '../../hooks/useACL';
import { AuthProvider } from '../../hooks/useAuth';
import { BalanceProvider } from '../../hooks/useBalance';
import { DataServiceProvider } from '../../hooks/useDataService';
import { DatadogRumProvider } from '../../hooks/useDatadogRum';
import { ExperimentProvider, experiments } from '../../hooks/useExperiment';
import { GraphQLProvider } from '../../hooks/useGraphQL';
import { HotjarProvider, polls } from '../../hooks/useHotjar';
import { ImpersonationProvider } from '../../hooks/useImpersonation';
import { MediaQueryProvider } from '../../hooks/useMediaQuery';
import { MetricsProvider } from '../../hooks/useMetrics';
import { NotificationsProvider } from '../../hooks/useNotifications';
import { ScrollbarProvider } from '../../hooks/useScrollbar';
import { ThemedBraidProvider } from '../../hooks/useStyleGuide';
import dataPlatform from '../../metrics/dataPlatform';
import tealium from '../../metrics/tealium';
import type { RouteDefinition } from '../../types';

import AppView from './components/AppView/AppView';
import Providers from './components/Providers/Providers';
import TranslationProvider from './components/TranslationProvider/TranslationProvider';

interface Props {
  routes: {
    unrestricted: RouteDefinition[];
    restricted: RouteDefinition[];
    recommendedCandidates: {
      path: string;
      component: ReturnType<typeof loadable>;
    };
  };
}

function App({ routes }: Props) {
  return (
    <Providers
      components={[
        HelmetProvider,
        TranslationProvider,
        DatadogRumProvider, // <-- in order to track all requests, Datadog RUM should get initialised as early as possible.
        ACLProvider,
        [MetricsProvider, { providers: [tealium, dataPlatform] }],
        AuthProvider,
        GraphQLProvider,
        ThemedBraidProvider,
        NotificationsProvider,
        [ExperimentProvider, { experiments }],
        DataServiceProvider,
        MediaQueryProvider,
        [HotjarProvider, { polls }],
        ScrollbarProvider,
        BalanceProvider,
        ImpersonationProvider,
      ]}
    >
      <AppView routes={routes} />
    </Providers>
  );
}

export default App;
