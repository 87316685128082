import { GET_SAVED_SEARCHES } from '../../../queries/TalentSearchSavedSearch';
import {
  type Services,
  type SavedSearch,
  Nations,
  type Language,
} from '../../../types';

interface Params {
  language?: Language;
}

const dataService: Services.DataService<SavedSearch[], Params> = ({ query }) =>
  async function fetchAllSavedSearches({ language }) {
    const data = await query<{
      talentSearchSavedSearches: SavedSearch[];
    }>({
      query: GET_SAVED_SEARCHES,
      variables: {
        language,
      },
    });

    return (data?.talentSearchSavedSearches ?? []).map((savedSearch) => {
      const { nation, salaryNation } = savedSearch.criteria;

      return {
        ...savedSearch,
        criteria: {
          ...savedSearch.criteria,
          nation: nation ?? salaryNation ?? Nations.AU,
        },
      };
    });
  };

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default dataService;
