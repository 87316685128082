import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as generateUuid } from 'uuid';

import { selectors as routerSelectors } from '../../../../store/router';
import { actionCreators as searchesActionCreators } from '../../../../store/searches';
import { RouteKeys } from '../../../../types';

import { shouldGenerateSearchId } from './shouldGenerateSearchId';

function SearchIdContainer() {
  const dispatch = useDispatch();
  const routeKey = useSelector(routerSelectors.getActiveRouteKeySelector);
  const location = useLocation();

  const prevLocation = useRef<{ pathname: string; search: string } | null>(
    null,
  );

  useEffect(() => {
    //  Since this container sits at App level
    //  it will attempt to generate a searchId on all
    //  routes - we only need it to function on the Search Results view
    if (
      routeKey !== RouteKeys.SearchResults &&
      routeKey !== RouteKeys.RecommendedCandidates
    ) {
      return;
    }

    if (
      !prevLocation.current ||
      shouldGenerateSearchId(prevLocation.current.search, location.search)
    ) {
      const nextSearchId = generateUuid();
      dispatch(searchesActionCreators.updateSearchId(nextSearchId));
    }

    prevLocation.current = location;
  }, [location, routeKey, dispatch]);

  return null;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default SearchIdContainer;
