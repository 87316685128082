import {
  ConnectionTypes,
  type AsyncProfileState,
  type ConnectionMethod,
  SendJobStatus,
  type Action,
} from '../../../types';
import {
  CONNECTION_REQUESTED,
  CONNECTION_REDEEMED,
  UNCOUPLED_SEND_JOB_REDEEMED,
  CONNECTION_FAILED,
} from '../actions';

import getTimestamp from './dateWrapper';

export const getConnectionMethod = (connectionType: ConnectionTypes) =>
  (connectionType === ConnectionTypes.ExportProfileCts
    ? 'exportProfile'
    : connectionType) as keyof ConnectionMethod;

function connectionInProfileReducer(
  asyncProfileState: AsyncProfileState,
  action: Action,
): AsyncProfileState {
  switch (action.type) {
    case CONNECTION_REQUESTED: {
      const { connectionType } = action.value;
      const connectionMethod = getConnectionMethod(connectionType);

      return {
        ...asyncProfileState,
        [connectionMethod]: {
          ...asyncProfileState[connectionMethod],
          isPending: true,
          hasError: false,
        },
      };
    }

    case CONNECTION_REDEEMED: {
      const { connection } = action.value;
      const { connectionId, creditsRedeemedDateUtc, nextAvailableDateUtc } =
        connection;
      const connectionMethod = getConnectionMethod(connection.connectionType);

      return {
        ...asyncProfileState,
        hasConnectionHistory: true,
        [connectionMethod]: {
          ...asyncProfileState[connectionMethod],
          hasError: false,
          isPending: false,
          isUsed: true,
          lastConnectionTimestamp: getTimestamp(creditsRedeemedDateUtc),
          nextAvailableConnectionTimestamp: getTimestamp(nextAvailableDateUtc),
          connectionId,
        },
      };
    }

    case UNCOUPLED_SEND_JOB_REDEEMED: {
      const { status } = action.value;

      return {
        ...asyncProfileState,
        hasConnectionHistory: true,
        sendJob: {
          isPending: false,
          hasError: false,
          isUsed: status === SendJobStatus.AllJobsSent,
          status,
        },
      };
    }

    case CONNECTION_FAILED: {
      const { connectionType } = action.value;
      const connectionMethod = getConnectionMethod(connectionType);

      return {
        ...asyncProfileState,
        [connectionMethod]: {
          ...asyncProfileState[connectionMethod],
          isPending: false,
          hasError: true,
        },
      };
    }

    default: {
      return asyncProfileState;
    }
  }
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default connectionInProfileReducer;
