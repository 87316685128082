import * as actions from './actions';
import type { Route, SceneState } from './types';

export const updateActiveRoute = (nextRoute: Route) => ({
  type: actions.UPDATE_ACTIVE_ROUTE,
  value: { nextRoute },
});

export const updateSceneState = (nextSceneState: SceneState) => ({
  type: actions.UPDATE_SCENE_STATE,
  value: { nextSceneState },
});

export const clearSceneState = () => ({
  type: actions.CLEAR_SCENE_STATE,
  value: null,
});
