import type { Action, TalentSearchJobV2 } from '../../types';

import * as actions from './actions';

type InitialState = TalentSearchJobV2 | null;

const initialState: InitialState = null;

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default (state: InitialState = initialState, action: Action) => {
  switch (action.type) {
    case actions.UPDATE_JOB_DETAILS: {
      const { jobDetails } = action.value;
      return {
        ...state,
        ...jobDetails,
      };
    }

    case actions.CLEAR_JOB_DETAILS: {
      return action.value;
    }

    default:
      return state;
  }
};
