import { Box, Link } from 'braid-design-system';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import useMetrics from '../../../../../../../hooks/useMetrics';
import { globalActions } from '../../../../../../../metrics/actions';

import * as styles from './MenuLink.css';

interface Props {
  href: string | null;
  hasCreditsMetricsEvent?: boolean;
  isExternal?: boolean;
  onClick?: (event: any) => void | Promise<void>;
  isActive?: (match: any, location: any) => boolean;
  children: React.ReactNode;
}

const MenuLink = ({
  href,
  hasCreditsMetricsEvent,
  isExternal,
  onClick,
  isActive,
  children,
}: Props) => {
  const { sendCreditsDashboardEvent } = useMetrics({
    sendCreditsDashboardEvent: globalActions.credits,
  });

  const handleOnClick = () => {
    if (hasCreditsMetricsEvent) {
      sendCreditsDashboardEvent();
    }
  };

  if (href === null) {
    return <Box component="span">{children}</Box>;
  }

  // Todo - move this to a separate component, not a child of MenuLink
  if (isExternal) {
    return (
      <Box
        className={{
          [styles.isSelected]: isActive,
        }}
        display="inlineBlock"
      >
        <Link
          href={href}
          target="_blank"
          rel="noopener"
          onClick={handleOnClick}
        >
          {children}
        </Link>
      </Box>
    );
  }

  return (
    <Box
      className={classNames({
        [styles.isSelected]: isActive,
      })}
      marginRight="medium"
      display="inlineBlock"
    >
      <NavLink
        to={href}
        {...(onClick ? { onClick } : null)}
        {...(isActive ? { isActive } : null)}
        className="navlink-menu"
        activeClassName="active-navlink-menu"
      >
        {children}
      </NavLink>
    </Box>
  );
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default MenuLink;
