import { useMemo, useRef, useContext } from 'react';

import Context from './Context';

type Unmapped = Record<string, (...args: any[]) => any>;

type InferInner<F> = F extends (...args: any[]) => infer R ? R : never;

type Mapped<T extends Unmapped> = {
  [K in keyof T]: InferInner<T[K]>;
};

function useDataService<T extends Unmapped>(dataServices: T): Mapped<T> {
  const unmapped = useRef<T>(dataServices);
  const context = useContext(Context);

  return useMemo(() => {
    const mapped = {} as Mapped<T>;
    for (const key in unmapped.current) {
      if (unmapped.current.hasOwnProperty(key)) {
        mapped[key] = unmapped.current[key](context);
      }
    }
    return mapped;
  }, [context]);
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default useDataService;
