import { selectors as userSelectors } from '../../../store/user';
import type { RootState } from '../../../types';

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default function getAdvertiserTypesSelector(snapshot: RootState) {
  const userId = userSelectors.getUserIdSelector(snapshot);
  const isHirer = userSelectors.getIsHirerSelector(snapshot);
  const isPremiumUser = userSelectors.isPremiumSelector(snapshot);

  const referrerSite = userId ? 'nac' : 'oac';

  const hirerAccessStatus = isHirer ? 'hirer_access_user' : 'standard_user';

  const talentSearchPermission = isPremiumUser
    ? 'talent_search_premium'
    : 'talent_search_standard';

  return [referrerSite, hirerAccessStatus, talentSearchPermission, 'none'];
}
