import { datadogRum } from '@datadog/browser-rum';
import { useRef, useEffect, useMemo } from 'react';

import { Environment } from '../../types';
import { useAppConfig } from '../useAppConfig';

import Context from './Context';
import type { ContextType } from './types';

interface Props {
  children: React.ReactNode;
}

function DatadogRumProvider({ children }: Props) {
  const hasInjected = useRef<boolean>(false);
  const { APP_VERSION, ENVIRONMENT, DATADOG_RUM_CONFIG } = useAppConfig();

  useEffect(() => {
    if (hasInjected.current || ENVIRONMENT !== Environment.Production) {
      return;
    }

    datadogRum.init({
      ...DATADOG_RUM_CONFIG,
      site: 'datadoghq.com',
      env: ENVIRONMENT,
      version: APP_VERSION,
      sampleRate: 100,
      replaySampleRate: 0, // <-- percentage of sessions that will have replaying the session, set to zero for now.
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingOrigins: [
        'https://talentsearch-api.cloud.seek.com.au',
        'https://talent.seek.com.au/graphql',
      ],
    });

    hasInjected.current = true;
  }, [ENVIRONMENT, APP_VERSION, DATADOG_RUM_CONFIG]);

  const context = useMemo<ContextType>(
    () => ({
      setUser: (userConfig) => {
        try {
          datadogRum.setUser(userConfig);
        } catch {}
      },
      setGlobalContext: (globalContext) => {
        try {
          const tags = {
            ...datadogRum.getRumGlobalContext(),
            ...globalContext,
          };
          datadogRum.setRumGlobalContext(tags);
        } catch {}
      },
      logError: (message, errorContext) => {
        try {
          const error = errorContext?.error ?? new Error(message);
          datadogRum.addError(error, {
            ...errorContext,
            message,
          });
        } catch {}
      },
    }),
    [],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default DatadogRumProvider;
