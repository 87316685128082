import type { Action, Pool } from '../../types';

import * as actions from './actions';

export interface ReducerState {
  pools: Pool[];
  hasFetched: boolean;
}

const initialState: ReducerState = {
  pools: [],
  hasFetched: false,
};

const updatePoolByIdAndName = (
  { pools }: ReducerState,
  id: string,
  name: string,
) =>
  pools.map((pool) => {
    if (pool.id === id) {
      pool.name = name;
    }
    return pool;
  });

const updatePoolByIdAndPolicy = (
  { pools }: ReducerState,
  id: string,
  policy: string,
) =>
  pools.map((pool) => {
    if (pool.id === id) {
      return {
        ...pool,
        policy,
      };
    }
    return pool;
  });

const deletePoolById = ({ pools }: ReducerState, id: string) =>
  pools.filter((pool) => pool.id !== id);

const createPoolByName = ({ pools }: ReducerState, pool: Pool) =>
  pools.concat(pool);

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default (state: ReducerState = initialState, action: Action) => {
  switch (action.type) {
    case actions.UPDATE_POOLS:
      return {
        ...state,
        ...action.value,
        hasFetched: true,
      };

    case actions.UPDATE_POOL: {
      const { id, name } = action.value;
      return {
        ...state,
        pools: updatePoolByIdAndName(state, id, name),
      };
    }

    case actions.SHARE_POOL: {
      const { id, policy } = action.value;
      return {
        ...state,
        pools: updatePoolByIdAndPolicy(state, id, policy),
      };
    }

    case actions.DELETE_POOL: {
      const { id } = action.value;
      return {
        ...state,
        pools: deletePoolById(state, id),
      };
    }

    case actions.CREATE_POOL: {
      const { pool } = action.value;
      return {
        ...state,
        pools: createPoolByName(state, pool),
      };
    }

    default:
      return state;
  }
};
