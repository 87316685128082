import { useSelector } from 'react-redux';

import { selectors as settingsSelectors } from '../../store/settings';
import { type AppConfig, Language } from '../../types';

import Context from './Context';
import globals from './globals';
interface Props {
  config: AppConfig;
  children: React.ReactNode;
}

function AppConfigProvider({ config, children }: Props) {
  const value = globals.window.__TS_CONFIG__ ?? config;
  const isEnPseudoMode = useSelector(settingsSelectors.getEnPseudoModeSelector);
  const currentLanguage = isEnPseudoMode ? Language.EN_PSEUDO : value.LANGUAGE;

  const updatedValue = {
    ...value,
    LANGUAGE: currentLanguage,
  };

  return <Context.Provider value={updatedValue}>{children}</Context.Provider>;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default AppConfigProvider;
