import { useTranslations } from '@vocab/react';
import { Text } from 'braid-design-system';

import type { Banner } from 'src/hooks/useNotifications/types';
import type { MessageTranslationKey } from 'src/types';

import translationFile from '../.vocab';

interface Props {
  banner: Banner;
}

interface TranslationProps {
  translation: {
    key: MessageTranslationKey;
  };
}

const TranslationDisplay = ({ translation: { key } }: TranslationProps) => {
  const { t } = useTranslations(translationFile);
  return <Text>{t(key)}</Text>;
};

const MessageDisplay = ({ banner }: Props) => {
  const { translation } = banner;
  if (translation) {
    return <TranslationDisplay translation={translation} />;
  }
  return <Text>{banner.message}</Text>;
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default MessageDisplay;
