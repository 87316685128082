import { createContext } from 'react';

import type { HotjarContext } from './types';

export const initialContext: HotjarContext = {
  trigger: () => {},
  triggerEvent: () => {},
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default createContext(initialContext);
