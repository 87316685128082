import type { ClientConfig } from '@seek/online-identity';

import { Environment } from 'src/types';

import type { Identity } from './types';

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default {
  getToken: (
    environment: Environment,
    { clientId }: ClientConfig,
  ): string | null => {
    if (typeof window !== 'undefined') {
      const token =
        window.localStorage?.getItem(`hirer_sso_access_token-${clientId}`) ||
        null;

      // This is to bypass the login flow in local environment (both legacy and unified) and should be removed as part of legacy pipeline cleanup
      // Please note that if you want to test actual login flows in local, you'll need to remove this condition (When running unified)
      if (
        !token &&
        environment === Environment.Development && // Only run this in development
        process.env.NODE_ENV !== 'test' // Only run this when not testing
      ) {
        const fakeToken = 'fake_token';
        localStorage.setItem(`hirer_sso_access_token-${clientId}`, fakeToken);
        localStorage.setItem(`hirer_sso_identity-${clientId}`, '{}');

        return fakeToken;
      }

      return token;
    }
    return null;
  },
  getIdentity: ({ clientId }: ClientConfig): Identity | null => {
    if (typeof window !== 'undefined') {
      const identity = window.localStorage?.getItem(
        `hirer_sso_identity-${clientId}`,
      );
      return identity ? (JSON.parse(identity) as Identity) : null;
    }
    return null;
  },
};
