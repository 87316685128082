import { gql } from '@apollo/client';

import type { Services, TalentSearchAccounts } from '../../../types';

import { mapToLegacyResponse } from './mappers';
import { type LegacyAccountsResponse, DEFAULT_SIZE } from './types';

export const GET_TOP_ACCOUNTS = gql`
  query GetTalentSearchTopAccounts($size: Int!) {
    talentSearchAccounts(
      input: { size: $size, orderBy: { field: advertiserName, direction: asc } }
    ) {
      items {
        advertiserId
        advertiserName
        seekBillingAccountId
        hasConnectedTalentSearchAccess
        hasPremiumTalentSearchAccess
        companyAddress {
          country
        }
      }
      count
    }
  }
`;

const dataService: Services.DataService<LegacyAccountsResponse> = ({ query }) =>
  async function getTopAccounts() {
    const { talentSearchAccounts } = await query<{
      talentSearchAccounts: TalentSearchAccounts;
    }>({
      query: GET_TOP_ACCOUNTS,
      variables: { size: DEFAULT_SIZE },
    });
    return mapToLegacyResponse(talentSearchAccounts);
  };

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default dataService;
