import type { AbsoluteDateFormat } from '@seek/unified-display-date-time/lib-types/absolute-date-formatter';
import { Box } from 'braid-design-system';

import useAppConfig from 'src/hooks/useAppConfig';

import { formatAbsoluteDate } from '../../../services/date/dateFormatter';

interface Props {
  date: Date | undefined;
  format?: AbsoluteDateFormat;
}

function DateFormat({ date, format = 'long' }: Props) {
  const { LOCALE } = useAppConfig();
  return (
    <Box component="span">
      {formatAbsoluteDate(date, format, undefined, LOCALE)}
    </Box>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default DateFormat;
