import { Box, Loader } from 'braid-design-system';

import * as styles from './RouteLoader.css';

function RouteLoader() {
  return (
    <Box className={styles.container}>
      <Box className={styles.loader}>
        <Loader />
      </Box>
    </Box>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default RouteLoader;
