import type { FriendlyContextMap } from './types';

const FRIENDLY_CONTEXT_MAP: Record<string, FriendlyContextMap> = {
  apiErrorCode: 'FRIENDLY_CONTEXT_MAP_API_ERROR_CODE',
  jobId: 'FRIENDLY_CONTEXT_MAP_JOB_ID',
  advertiserId: 'FRIENDLY_CONTEXT_MAP_ADVERTISER_ID',
  default: 'FRIENDLY_CONTEXT_MAP_DEFAULT',
};

function mapContextToFriendlyMessage(key: string): FriendlyContextMap {
  const createMessage =
    key in FRIENDLY_CONTEXT_MAP
      ? FRIENDLY_CONTEXT_MAP[key]
      : FRIENDLY_CONTEXT_MAP.default;
  return createMessage;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default mapContextToFriendlyMessage;
