import { gql } from '@apollo/client';

import type { Services, TalentSearchAccounts } from '../../../types';

import { mapToLegacyResponse } from './mappers';
import { type LegacyAccountsResponse, DEFAULT_SIZE } from './types';

export const GET_SUGGESTED_ACCOUNTS = gql`
  query GetTalentSearchSuggestedAccounts($query: String!, $size: Int!) {
    talentSearchAccounts(
      input: {
        query: $query
        size: $size
        orderBy: { field: advertiserName, direction: asc }
      }
    ) {
      items {
        advertiserId
        advertiserName
        seekBillingAccountId
        hasConnectedTalentSearchAccess
        hasPremiumTalentSearchAccess
        companyAddress {
          country
        }
      }
      count
    }
  }
`;

const dataService: Services.DataService<
  LegacyAccountsResponse,
  {
    query: string;
    size?: number;
  }
> = ({ query }) =>
  async function getSuggestedAccounts({ query: searchQuery, size }) {
    const { talentSearchAccounts } = await query<{
      talentSearchAccounts: TalentSearchAccounts;
    }>({
      query: GET_SUGGESTED_ACCOUNTS,
      variables: {
        query: searchQuery,
        size: size || DEFAULT_SIZE,
      },
    });
    return mapToLegacyResponse(talentSearchAccounts);
  };

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default dataService;
