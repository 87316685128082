import locationService from '../../../services/location';
import {
  Search,
  FilterDelimiters,
  type RootState,
  Nations,
} from '../../../types';

export const convertBooleanStringToValue = (value: string | null) => {
  if (!value) {
    return '0';
  }
  return value.toLowerCase() === 'true' ? '1' : '0';
};

export const convertBooleanStringToBoolean = (value: string | null) => {
  if (!value) {
    return false;
  }
  return value.toLowerCase() === 'true';
};

const getGranularRightToWork = (
  rightToWorkIds: string,
  rightToWorkUnspecified: string,
): string[] => {
  const ret = rightToWorkIds ? rightToWorkIds.split(',') : [];
  if (rightToWorkUnspecified === 'true') {
    ret.push('unspecified');
  }
  return ret;
};

const getFilterVisa = (visa: string): string[] =>
  visa.split(',').map((item) => {
    if (item === 'unspecified') {
      return item;
    } else if (item.toLowerCase() === 'true') {
      return 'yes';
    }
    return 'no';
  });

const getCountyShortNameByCode = (countryCode: string) => {
  if (Number.isNaN(countryCode)) {
    return '';
  }

  const countryShortName = Nations[Number(countryCode)];
  if (countryShortName) {
    return countryShortName.toLocaleLowerCase();
  }
  return '';
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default (snapshot: RootState) => {
  const params = locationService.queryStringService.parse(
    snapshot.router.search,
  );

  const {
    keywords,
    industry,
    company,
    approachable,
    companyFollow,
    lastUpdated,
    minSalary,
    maxSalary,
    salaryType,
    salaryUnspecified,
    nation,
    locationList,
    willingToRelocate,
    workType,
    visa,
    cv,
    pageNumber = '1',
    sortBy = Search.SortType.Relevance,
    searchType = Search.SearchType.NewSearch,
    rightToWorkIds,
    rightToWorkUnspecified,
  } = params;

  //  Pull the most granular country currently active
  //  Query > Job > Settings

  return {
    searchSecondaryKeyword: keywords || null,
    searchIndustryId: industry ? industry.split(',') : [],
    searchCompanyName: company ? company.split(FilterDelimiters.Company) : [],
    searchIsApproachable: convertBooleanStringToBoolean(approachable),
    searchIsFollower: convertBooleanStringToBoolean(companyFollow),
    searchLastUpdated: lastUpdated || null,
    searchSalaryRangeMin: minSalary ? Number(minSalary) : null,
    searchSalaryRangeMax: maxSalary ? Number(maxSalary) : null,
    searchSalaryType: salaryType?.toLowerCase() || null,
    searchIsSalaryUnspecified: convertBooleanStringToBoolean(salaryUnspecified),
    searchWorkType: workType ? workType.split(',') : [],
    searchRightToWork: visa ? getFilterVisa(visa) : [],
    searchGranularRightToWork: getGranularRightToWork(
      rightToWorkIds,
      rightToWorkUnspecified,
    ),
    searchIsCvIncluded: convertBooleanStringToBoolean(cv),
    searchLocationId: locationList ? locationList.split(',') : [],
    searchLocationCountry: getCountyShortNameByCode(nation),
    searchIsWillingToRelocate: convertBooleanStringToBoolean(willingToRelocate),
    searchSortedBy: sortBy,
    searchType,
    pageNumber,
  };
};
