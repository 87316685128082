import { VocabProvider } from '@vocab/react';

import useAppConfig from '../../../../hooks/useAppConfig';

interface Props {
  children: React.ReactNode;
}

function TranslationProvider({ children }: Props) {
  const { LANGUAGE } = useAppConfig();
  return <VocabProvider language={LANGUAGE}>{children}</VocabProvider>;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default TranslationProvider;
