import type { TalentSearchAccounts } from '../../../types';

import type { LegacyAccountsResponse } from './types';

export const mapToLegacyResponse = (
  talentSearchAccounts: TalentSearchAccounts,
): LegacyAccountsResponse => {
  const { items = [], count = 0 } = talentSearchAccounts;

  return {
    accounts: items.map(
      ({
        advertiserName,
        advertiserId,
        seekBillingAccountId,
        hasConnectedTalentSearchAccess,
        hasPremiumTalentSearchAccess,
        companyAddress,
      }) => ({
        id: advertiserId,
        accountNumber: Number(seekBillingAccountId),
        name: advertiserName,
        hasConnectedTalentSearchAccess,
        hasPremiumTalentSearchAccess,
        companyAddress,
      }),
    ),
    totalAccounts: count,
  };
};
