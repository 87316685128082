import { PrivacyTypes, CVPreviewStates, type ProfileV2 } from '../../types';

function getCvPreviewStatus(profile: ProfileV2): CVPreviewStates {
  const { hasResume, profilePrivacy } = profile;

  if (!hasResume) {
    return CVPreviewStates.NoCv;
  }

  if (profilePrivacy === PrivacyTypes.Limited) {
    return CVPreviewStates.CvPrivate;
  }

  if (!profile.resumeCoreDataAvailable) {
    return CVPreviewStates.CvNotParsed;
  }

  return CVPreviewStates.CvPreview;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default getCvPreviewStatus;
