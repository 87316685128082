/**
 * Returns an Error object from an unknown value that may or may not be an Error.
 * e.g, when catch an error, we can use this function to get an Error object.
 * @param errorAlike - The value that may or may not be an Error.
 * @returns An Error object.
 */
const getCaughtError = (errorAlike: unknown): Error => {
  if (errorAlike instanceof Error) {
    return errorAlike;
  }
  if (isErrorWithMessage(errorAlike)) {
    return new Error(errorAlike.message);
  }
  try {
    return new Error(`Caught error: ${JSON.stringify(errorAlike)}`);
  } catch {
    // fallback to string in case JSON.stringify fails like circular reference
    return new Error(`Caught error: ${String(errorAlike)}`);
  }
};

interface ErrorWithMessage {
  message: string;
}

/**
 * Checks if an unknown value has a "message" property of type string.
 * @param unknown - The value to check.
 * @returns A boolean indicating whether the value is an object with a "message" property of type string.
 */
const isErrorWithMessage = (unknown: unknown): unknown is ErrorWithMessage =>
  typeof unknown === 'object' &&
  unknown !== null &&
  'message' in unknown &&
  typeof (unknown as Record<string, unknown>).message === 'string';

export { getCaughtError };
