import { ErrorTypes, APIErrorCode } from '../../../types';

import puzzlesImageSvg from './images/puzzles.svg';
import type { ErrorMessageTranslationKey } from './types';

interface Message {
  image: string;
  title: string;
  message: ErrorMessageTranslationKey;
  showLogoutAction?: boolean;
  errorCode?: string;
}

interface Context {
  code: string;
  apiErrorCode?: APIErrorCode;
}

function renderSessionTimeout(): Message {
  return {
    image: puzzlesImageSvg,
    title: 'SESSION_TIMED_OUT_TITLE',
    message: 'DEFAULT_SESSION_TIMED_OUT_ERROR_MESSAGE',
  };
}

function renderInvalidState(): Message {
  return {
    image: puzzlesImageSvg,
    title: 'GENERIC_ERROR_TITLE',
    message: 'INVALID_STATE_ERROR_MESSAGE',
    showLogoutAction: true,
  };
}

function renderUnauthorised({ apiErrorCode, code }: Context): Message {
  switch (apiErrorCode) {
    case APIErrorCode.FORBIDDEN_INVALID_ACCESS_TO_RESOURCE:
      return {
        image: puzzlesImageSvg,
        title: 'UNAUTHORISED_TITLE',
        message: 'UNAUTHORIZED_FORBIDDEN_ACCESS_ERROR_MESSAGE',
        showLogoutAction: true,
        errorCode: code,
      };
    default:
      return {
        image: puzzlesImageSvg,
        title: 'UNAUTHORISED_GENERIC_TITLE',
        message: 'UNAUTHORIZED_DEFAULT_ERROR_MESSAGE',
        showLogoutAction: true,
        errorCode: code,
      };
  }
}

function renderUnknownError({ code }: Context): Message {
  return {
    image: puzzlesImageSvg,
    title: 'GENERIC_ERROR_TITLE',
    message: 'UNKNOWN_ERROR_MESSAGE',
    errorCode: code,
  };
}

function renderSearchError({ code }: Context): Message {
  return {
    image: puzzlesImageSvg,
    title: 'SEARCH_ERROR_TITLE',
    message: 'SEARCH_ERROR',
    errorCode: code,
  };
}

const renderSearchTalentPoolError = ({ code }: Context): Message => ({
  image: puzzlesImageSvg,
  title: 'SEARCH_TALENT_POOL_ERROR_TITLE',
  message: 'SEARCH_ERROR',
  errorCode: code,
});

function mapErrorTypeToMessage(errorType: ErrorTypes, context: Context) {
  switch (errorType) {
    case ErrorTypes.SESSION_TIMEOUT:
      return renderSessionTimeout();

    case ErrorTypes.UNAUTHORISED:
      return renderUnauthorised(context);

    case ErrorTypes.INVALID_STATE:
      return renderInvalidState();

    case ErrorTypes.SEARCH_ERROR:
      return renderSearchError(context);

    case ErrorTypes.SEARCH_TALENT_POOL_ERROR:
      return renderSearchTalentPoolError(context);

    default:
      return renderUnknownError(context);
  }
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default mapErrorTypeToMessage;
