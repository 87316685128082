import * as actions from './actions';

export const deductCreditsFromBalance = (creditRate: number) => ({
  type: actions.DEDUCT_USER_BALANCE_AMOUNT,
  value: creditRate,
});

export const updateUserBalanceToAmount = (newCreditsBalance: number) => ({
  type: actions.UPDATE_USER_BALANCE_TO_AMOUNT,
  value: newCreditsBalance,
});
