import { Identifier } from '@seek/indie-object-identifiers';
import { atsJobExternalReferenceId } from '@seek/talentsearch-object-identifiers';

const { decodeString } = Identifier;

const getSchemeId = (id: string): string => id.split(':')[0];

export const decodeAtsJobExternalReferenceId = (
  externalReferenceId: string,
) => {
  try {
    const schemeId = getSchemeId(externalReferenceId);
    const descriptor = atsJobExternalReferenceId(schemeId);
    return decodeString(descriptor, externalReferenceId);
  } catch {
    return null;
  }
};
