import { createContext } from 'react';

import type { Theme } from './types';

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default createContext<{
  theme: Theme;
}>({
  theme: {},
});
