import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface Props {
  children: React.ReactNode;
  onOutsideElementClick: () => void;
}

//  Ensures that the clicked element is still visible on the page
//  otherwise any quick return JSX we write might cause issues with
//  this helper component. I.e. {isCreating && <button onClick={this.updateIsCreatingState}></button>}
export const sideEffects = {
  find: (event: Event) => {
    const node = ReactDOM.findDOMNode(
      (event as any).target,
    ) as HTMLElement | null;
    return !node || node.getBoundingClientRect().top !== 0;
  },
  hasEvent: (refElement: HTMLElement | null, event: Event) =>
    refElement && !refElement.contains((event as any).target),
};

const DocumentClickHandler = ({ children, onOutsideElementClick }: Props) => {
  const element = useRef(null);

  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      if (
        sideEffects.find(event) &&
        sideEffects.hasEvent(element.current, event)
      ) {
        onOutsideElementClick();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [onOutsideElementClick]);

  return <span ref={element}>{children}</span>;
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default DocumentClickHandler;
