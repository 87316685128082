import { Box, Columns, Column, TextLink } from 'braid-design-system';

import { CountryCodes } from 'src/types';

import useAppConfig from '../../../../hooks/useAppConfig';
import useMediaQuery from '../../../../hooks/useMediaQuery';

import TextWrapper from './components/FooterTextWrapper/FooterTextWrapper';
import {
  useFooterDataMap,
  type FooterDataMapType,
  type FooterItemInterface,
} from './useFooterDataMap';

import * as styles from './Footer.css';

function Footer() {
  const { isMobile } = useMediaQuery();
  const { COUNTRY_CODE: countryCode } = useAppConfig();

  const footerDataMap: FooterDataMapType | null = useFooterDataMap();

  if (!footerDataMap) {
    return null;
  }

  const footerItems: FooterItemInterface[] | undefined = footerDataMap.has(
    countryCode,
  )
    ? footerDataMap.get(countryCode)
    : footerDataMap.get(CountryCodes.AU);

  if (!footerItems) {
    return null;
  }

  return (
    <Box
      component="footer"
      background="neutralLight"
      data-cy="talent-search-footer"
    >
      <Box className={styles.footerWrapper} paddingX="gutter">
        <Columns
          align="left"
          alignY="center"
          space={isMobile ? 'small' : 'large'}
          collapseBelow="tablet"
        >
          {footerItems.map((footerData: FooterItemInterface, index: number) => (
            <Box className={styles.footerItemWrapper} key={index}>
              <Column width="content">
                <TextWrapper
                  mobilePaddingTop="gutter"
                  mobilePaddingBottom={
                    index >= footerItems.length - 2 ? 'gutter' : 'small'
                  } // Add bottom padding to last 2 cols on mobile
                  mobileLineBorder={index === footerItems.length - 1} // Add a line above the last col on mobile
                  text={footerData.plainTextLine1}
                >
                  {footerData.link && footerData.linkText && (
                    <TextLink href={footerData.link} weight="weak">
                      {footerData.linkText}
                    </TextLink>
                  )}
                  {footerData.plainTextLine2 && (
                    <Box>{footerData.plainTextLine2}</Box>
                  )}
                </TextWrapper>
              </Column>
            </Box>
          ))}
        </Columns>
      </Box>
    </Box>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default Footer;
