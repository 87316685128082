import { selectors as routerSelectors } from '../../../store/router';
import { RouteKeys, type RootState } from '../../../types';
import { Page } from '../types';

const routeToPageMapper: Record<
  string,
  (params: { isCoupled: boolean; channel: string }) => Page
> = {
  [RouteKeys.Search]: () => Page.Search,
  [RouteKeys.SearchResults]: () => Page.SearchResults,
  [RouteKeys.Pooling]: () => Page.Pooling,
  [RouteKeys.PoolingChannel]: () => Page.PoolingChannelPools,
  [RouteKeys.Profile]: () => Page.Profile,
  [RouteKeys.SimilarCandidates]: () => Page.SimilarCandidates,
  [RouteKeys.RecommendedCandidates]: () => Page.RecommendedCandidates,
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default (snapshot: RootState) => {
  const routeKey = routerSelectors.getActiveRouteKeySelector(snapshot);
  const isCoupled = Boolean(routerSelectors.getActiveJobIdSelector(snapshot));
  const channel = routerSelectors.getChannelFromParams(snapshot);

  return routeKey in routeToPageMapper
    ? routeToPageMapper[routeKey]({ isCoupled, channel })
    : Page.Unknown;
};
