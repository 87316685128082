import { createContext } from 'react';

import type { ContextType } from './types';

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default createContext<ContextType>({
  isImpersonating: false,
  getDetails: () => null,
});
