import type {
  AsyncState,
  AsyncProfileState,
  AssignedPools,
  LatestInteraction,
  ProfileConnectionsAvailability,
  ProfileSendJobStatus,
} from '../../../types';

import getConnectionMethods from './getConnectionMethods';

const getNextEntries = ({
  currentEntries,
  assignedPoolsForProfiles,
  latestInteractionsForProfiles,
  connectionsAvailabilitiesForProfiles,
  sendJobStatusForProfiles,
}: {
  currentEntries: AsyncState['entries'];
  assignedPoolsForProfiles: AssignedPools[];
  latestInteractionsForProfiles: LatestInteraction[];
  connectionsAvailabilitiesForProfiles: ProfileConnectionsAvailability[];
  sendJobStatusForProfiles: ProfileSendJobStatus[];
}) => {
  const nextEntries: Record<string, AsyncProfileState> = {};

  for (const [profileId, profile] of Object.entries(currentEntries)) {
    const { assignedPools } = assignedPoolsForProfiles.find(
      (assignedPool) => profileId === assignedPool.profileId,
    ) || { assignedPools: [] };

    const latestInteraction =
      latestInteractionsForProfiles.find(
        (interaction) => profileId === String(interaction.profileId),
      ) || null;

    let connectionMethods = null;
    if (connectionsAvailabilitiesForProfiles.length) {
      const { connectionsAvailability } =
        connectionsAvailabilitiesForProfiles.find(
          (_connectionsAvailability) =>
            profileId === String(_connectionsAvailability.profileId),
        ) || { connectionsAvailability: [] };

      const sendJobStatusForProfile = sendJobStatusForProfiles.find(
        (_profile) => profileId === String(_profile.profileId),
      );

      connectionMethods = getConnectionMethods({
        connectionsAvailability,
        sendJobStatus: sendJobStatusForProfile,
      });
    }

    nextEntries[profileId] = {
      ...profile,
      assignedPools,
      latestInteraction,
      hasConnectionHistory: latestInteraction
        ? latestInteraction.count > 0
        : false,
      ...connectionMethods,
    };
  }
  return nextEntries;
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default getNextEntries;
