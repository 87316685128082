import { useContext } from 'react';

import type { AppConfig } from '../../types';

import Context from './Context';

function useAppConfig(): AppConfig {
  return useContext(Context);
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default useAppConfig;
