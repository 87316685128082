import { gql } from '@apollo/client';

export const GET_METADATA_COMPANY_SUGGEST = gql`
  query GetTalentSearchMetadataCompanySuggest($query: String!, $size: Int) {
    talentSearchMetadataCompanySuggest(input: { query: $query, size: $size })
  }
`;

export const GET_METADATA_INDUSTRIES = gql`
  query GetTalentSearchMetadataIndustries(
    $input: TalentSearchMetadataIndustriesInput
  ) {
    talentSearchMetadataIndustries(input: $input) {
      id
      name
      subIndustries {
        id
        name
      }
    }
  }
`;

export const GET_METADATA_QUERY_VALIDATIONS = gql`
  query GetTalentSearchMetadataQueryValidations {
    talentSearchMetadataQueryValidations {
      stopwords
      whitelistRegexPattern
    }
  }
`;

export const GET_LOCATIONS_BY_IDS = gql`
  query GetLocationsByIds(
    $ids: [ID!]!
    $contextualCountry: String
    $language: LanguageCodeIso
  ) {
    locationsByIds(
      ids: $ids
      contextualCountry: $contextualCountry
      language: $language
    ) {
      id
      country
      type
      description
      parent {
        id
        country
        type
        description
      }
    }
  }
`;

export const GET_MARKETS = gql`
  query GetMarkets($input: TalentSearchMetadataMarketsInput!) {
    talentSearchMetadataMarkets(input: $input) {
      countryCode
      countryName
    }
  }
`;
