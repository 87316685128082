import { gql } from '@apollo/client';

import type {
  Services,
  UserContext,
  UserContextInput,
  User,
} from '../../../types';

import { mapUserContextToUser } from './mappers';

type Params = UserContextInput;
type Response = User | null;

export const USER_CONTEXT = gql`
  query GetTalentSearchUserContext($input: TalentSearchUserContextInput!) {
    talentSearchUserContextV2(input: $input) {
      user {
        userId
        lastName
        firstName
        email
      }
      claims
      ats {
        id
        name
      }
      advertiser {
        id
        name
        accountNumber
        tags {
          testRecord
          testScope
          recordExpiry
          testBehaviours
        }
      }
      contracts {
        startDate
        endDate
        productType
      }
      productType
    }
  }
`;

const dataService: Services.DataService<Response, Params> = ({ query }) =>
  async function getUserContext(params) {
    const data = await query<{
      talentSearchUserContextV2: UserContext;
    }>({
      query: USER_CONTEXT,
      variables: { input: params },
      context: {
        disableAuthorisationHeaders: true,
      },
    });

    if (!data) {
      return null;
    }

    return data?.talentSearchUserContextV2
      ? mapUserContextToUser(data.talentSearchUserContextV2)
      : null;
  };

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default dataService;
