import { gql } from '@apollo/client';

import type { Services, TalentSearchFeature } from '../../../types';

export const GET_TEALENT_SEARCH_USER_FEATURES = gql`
  query GetTalentSearchUserFeatures {
    talentSearchUserFeatures {
      key
      name
      active
    }
  }
`;

const dataService: Services.DataService<TalentSearchFeature[]> =
  ({ query }) =>
  async () => {
    const { talentSearchUserFeatures } = await query<{
      talentSearchUserFeatures: TalentSearchFeature[];
    }>({
      query: GET_TEALENT_SEARCH_USER_FEATURES,
    });

    return talentSearchUserFeatures;
  };

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default dataService;
