import { CUSTOMER_SERVICE_PHONE_NUMBER } from '@seek/shared-constants';
import { useTranslations } from '@vocab/react';

import { useConstantUrls } from 'src/hooks/useConstUrls/useConstantUrls';
import { useRoutes } from 'src/hooks/useRoutes';
import { CountryCodes } from 'src/types';

import translations from './.vocab';

export const dateProvider = {
  getFullYear: () => new Date().getFullYear(),
};

export interface FooterItemInterface {
  plainTextLine1?: string; // LINE 1
  plainTextLine2?: string; // LINE 2
  linkText?: string;
  link?: string;
}

export type FooterDataMapType = Map<
  keyof typeof CountryCodes,
  FooterItemInterface[]
>;

/**
 * Custom Hook to get footerdata map in a react component
 * @returns a map of country codes and footer items if the translation function is ready and null if not ready
 */
export function useFooterDataMap(): FooterDataMapType | null {
  const { t, ready } = useTranslations(translations);

  const { privacyUrl, tsTermsUrl, contactUsUrl } = useConstantUrls();
  const { routes } = useRoutes();

  if (!ready) {
    return null;
  }

  return new Map<keyof typeof CountryCodes, FooterItemInterface[]>([
    [
      CountryCodes.AU,
      [
        {
          linkText: t('CONTACT_US'),
          link: contactUsUrl,
        },
        {
          plainTextLine1: t('CUSTOMER_SERVICE'),
          linkText: CUSTOMER_SERVICE_PHONE_NUMBER['employer-seek-au'],
          link: `tel:${CUSTOMER_SERVICE_PHONE_NUMBER['employer-seek-au']}`,
        },
        {
          linkText: t('LEARN_MORE'),
          link: routes.help({}),
        },
        {
          linkText: t('PRIVACY'),
          link: 'https://www.seek.com.au/privacy/',
        },
        {
          linkText: t('TERMS_AND_CONDITIONS'),
          link: tsTermsUrl,
        },
        {
          plainTextLine1: t('SIGN_SEEK_YEAR_ALL_RIGHTS_RESERVED', {
            sign: '\u00A9',
            year: dateProvider.getFullYear().toString(),
          }),
        },
      ],
    ],
    [
      CountryCodes.NZ,
      [
        {
          linkText: t('CONTACT_US'),
          link: contactUsUrl,
        },
        {
          plainTextLine1: t('CUSTOMER_SERVICE'),
          linkText: CUSTOMER_SERVICE_PHONE_NUMBER['employer-seek-nz'],
          link: `tel:${CUSTOMER_SERVICE_PHONE_NUMBER['employer-seek-nz']}`,
        },
        {
          linkText: t('LEARN_MORE'),
          link: routes.help({}),
        },
        {
          linkText: t('PRIVACY'),
          link: 'https://www.seek.co.nz/privacy/',
        },
        {
          linkText: t('TERMS_AND_CONDITIONS'),
          link: tsTermsUrl,
        },
        {
          plainTextLine1: t('SIGN_SEEK_YEAR_ALL_RIGHTS_RESERVED', {
            sign: '\u00A9',
            year: dateProvider.getFullYear().toString(),
          }),
        },
      ],
    ],
    [
      CountryCodes.TH,
      [
        {
          linkText: t('CONTACT_US'),
          link: contactUsUrl,
        },
        {
          linkText: t('HELP_CENTRE'),
          link: routes.help({}),
        },
        {
          linkText: t('PRIVACY'),
          link: privacyUrl,
        },
        {
          linkText: t('TERMS_AND_CONDITIONS'),
          link: tsTermsUrl,
        },
        {
          plainTextLine1: t('SIGN_SEEK_YEAR_ALL_RIGHTS_RESERVED', {
            sign: '\u00A9',
            year: dateProvider.getFullYear().toString(),
          }),
        },
      ],
    ],
    [
      CountryCodes.HK,
      [
        {
          linkText: t('CONTACT_US'),
          link: contactUsUrl,
        },
        {
          linkText: t('HELP_CENTRE'),
          link: routes.help({}),
        },
        {
          linkText: t('PRIVACY'),
          link: privacyUrl,
        },
        {
          linkText: t('TERMS_AND_CONDITIONS'),
          link: tsTermsUrl,
        },
        {
          plainTextLine1: t('SIGN_SEEK_YEAR_ALL_RIGHTS_RESERVED', {
            sign: '\u00A9',
            year: dateProvider.getFullYear().toString(),
          }),
        },
      ],
    ],
    [
      CountryCodes.ID,
      [
        {
          linkText: t('CONTACT_US'),
          link: contactUsUrl,
        },
        {
          linkText: t('HELP_CENTRE'),
          link: routes.help({}),
        },
        {
          linkText: t('PRIVACY'),
          link: privacyUrl,
        },
        {
          linkText: t('TERMS_AND_CONDITIONS'),
          link: tsTermsUrl,
        },
        {
          plainTextLine1: t('SIGN_SEEK_YEAR_ALL_RIGHTS_RESERVED', {
            sign: '\u00A9',
            year: dateProvider.getFullYear().toString(),
          }),
        },
      ],
    ],
    [
      CountryCodes.MY,
      [
        {
          linkText: t('CONTACT_US'),
          link: contactUsUrl,
        },
        {
          linkText: t('HELP_CENTRE'),
          link: routes.help({}),
        },
        {
          linkText: t('PRIVACY'),
          link: privacyUrl,
        },
        {
          linkText: t('TERMS_AND_CONDITIONS'),
          link: tsTermsUrl,
        },
        {
          plainTextLine1: 'Agensi Pekerjaan JobStreet.com Sdn Bhd',
          plainTextLine2: t('REGISTRATION_NO', {
            registrationNumber: '199701033623 (449122-K)',
          }),
        },
      ],
    ],
    [
      CountryCodes.PH,
      [
        {
          linkText: t('CONTACT_US'),
          link: contactUsUrl,
        },
        {
          linkText: t('HELP_CENTRE'),
          link: routes.help({}),
        },
        {
          linkText: t('PRIVACY'),
          link: privacyUrl,
        },
        {
          linkText: t('TERMS_AND_CONDITIONS'),
          link: tsTermsUrl,
        },
        {
          plainTextLine1: t('SIGN_SEEK_YEAR_ALL_RIGHTS_RESERVED', {
            sign: '\u00A9',
            year: dateProvider.getFullYear().toString(),
          }),
        },
      ],
    ],
    [
      CountryCodes.SG,
      [
        {
          linkText: t('CONTACT_US'),
          link: contactUsUrl,
        },
        {
          linkText: t('HELP_CENTRE'),
          link: routes.help({}),
        },
        {
          linkText: t('PRIVACY'),
          link: privacyUrl,
        },
        {
          linkText: t('TERMS_AND_CONDITIONS'),
          link: tsTermsUrl,
        },
        {
          plainTextLine1: t('SIGN_SEEK_YEAR_ALL_RIGHTS_RESERVED', {
            sign: '\u00A9',
            year: dateProvider.getFullYear().toString(),
          }),
        },
      ],
    ],
  ]);
}
