import {
  type RootState,
  Search,
  type AsyncProfileState,
  type ProfileV2,
} from '../../types';
import { getAllParamsFromSearchSelector } from '../router/selectors';

import { DEFAULT_STATE } from './constants';

export const getProfileStateSelector =
  (profile: ProfileV2) =>
  ({ profiles }: RootState): AsyncProfileState => {
    const profileId = String(profile.profileId);
    if (profileId in profiles.entries) {
      return profiles.entries[profileId];
    }
    return DEFAULT_STATE;
  };

export const getLoadingSelector = ({ profiles }: RootState) => profiles.loading;
export const getErrorSelector = ({ profiles }: RootState) => profiles.error;

export const getProfilePositionSelector = (
  state: RootState,
  profile: ProfileV2,
  isForMetric: boolean = false,
): number => {
  const profileId = String(profile.profileId);
  const { profilePosition, pageNumber: pageNumberFromUrl } =
    getAllParamsFromSearchSelector(state);

  //  If we have a ?profilePosition in the URL
  //  we should always return it (i.e. Open profile in new tab)
  if (profilePosition) {
    return Number(profilePosition);
  }

  // Set the profilePosition to 0 if the selected profile is not found in the profile.entries
  const { profiles } = state;

  const entries = profiles?.entries || {};

  if (!(profileId in entries)) {
    return 0;
  }

  if (Object.keys(entries).length === 1) {
    if (isForMetric) {
      return 1;
    }
    return 0;
  }

  //  Calculate the profilePosition from the
  //  profile list view (SERP / SimilarCandiates etc)
  const pageNumber = pageNumberFromUrl ? Number(pageNumberFromUrl) : 1;
  const { position } = entries[profileId];

  // eslint-disable-next-line no-mixed-operators
  return (pageNumber - 1) * Search.Config.PROFILES_PER_PAGE + position;
};
