import { siteNames, siteConfig } from '@seek/melways-sites';
import { oauth } from '@seek/oauth-url-builders';
import { useTranslations } from '@vocab/react';
import { TextField, Box, Loader, Inline, Text } from 'braid-design-system';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import LoaderOverlay from 'src/common/UI/LoaderOverlay';
import { TALENTSEARCH_PREFIX } from 'src/constants';
import useAppConfig from 'src/hooks/useAppConfig';
import useCreateMessage from 'src/hooks/useCreateMessage';
import useDataService from 'src/hooks/useDataService';
import useDebounce from 'src/hooks/useDebounce';
import useImpersonation from 'src/hooks/useImpersonation';
import useMetrics from 'src/hooks/useMetrics';
import useNotifications from 'src/hooks/useNotifications';
import useRouter from 'src/hooks/useRouter';
import { globalActions } from 'src/metrics/actions';
import * as accountDataServices from 'src/services/data/account';
import { actionCreators as settingsActionCreators } from 'src/store/settings';
import {
  type Account,
  CountryCodes,
  Environment,
  MessageTemplateKey,
  Tags,
} from 'src/types';

import MenuGroup from '../MenuGroup/MenuGroup';

import translations from './.vocab';

import * as styles from './AccountsList.css';

//  Use "Account" type when data is returned from HG
interface LegacyAccount
  extends Pick<
    Account,
    | 'hasConnectedTalentSearchAccess'
    | 'hasPremiumTalentSearchAccess'
    | 'companyAddress'
  > {
  id: number;
  name: string;
  accountNumber: number;
}

interface Props {
  hasScroll: boolean;
  activeAccountNumber: number;
  onSelect: () => void;
}

const MIN_ACCOUNTS_BEFORE_SUGGEST = 8;
const MIN_QUERY_LENGTH_BEFORE_SUGGEST = 2;
export const DEBOUNCE_TIMEOUT_MS = 500;

function AccountsList({ hasScroll, activeAccountNumber, onSelect }: Props) {
  const { t } = useTranslations(translations);
  const dispatch = useDispatch();

  const prevAccounts = useRef<LegacyAccount[]>([]);
  const { showToast } = useNotifications();
  const { redirectToStartPage, forwardToPath } = useRouter();
  const { getDetails } = useImpersonation();
  const [accounts, setAccounts] = useState<LegacyAccount[]>([]);
  const [totalAccounts, setTotalAccounts] = useState<number>(0);
  const [fieldValue, setFieldValue] = useState<string>('');
  const [isFetching, setIsFetching] = useState(true);
  const [isSwitchingAccounts, setIsSwitchingAccounts] = useState(false);

  const debouncedFieldValue = useDebounce(fieldValue, DEBOUNCE_TIMEOUT_MS);
  const { IS_LEGACY_SITE, ENVIRONMENT } = useAppConfig();

  const { sendAccountSwitchEvent } = useMetrics({
    sendAccountSwitchEvent: globalActions.accountSwitch,
  });

  const { getTopAccounts, getSuggestedAccounts } = useDataService({
    getTopAccounts: accountDataServices.getTopAccounts,
    getSuggestedAccounts: accountDataServices.getSuggestedAccounts,
  });

  const { createMessageFromTemplate } = useCreateMessage();

  const accountsErrorMessage = useRef(
    createMessageFromTemplate({
      key: MessageTemplateKey.GENERIC_FAILURE,
      params: { action: t('FETCH_ACCOUNTS'), dedupingKey: 'accounts' },
    }),
  );

  const { LANGUAGE } = useAppConfig();

  useEffect(() => {
    (async () => {
      try {
        const { accounts: _accounts, totalAccounts: _totalAccounts } =
          await getTopAccounts();
        prevAccounts.current = _accounts;
        setAccounts(_accounts);
        setTotalAccounts(_totalAccounts);
      } catch (err) {
        showToast(accountsErrorMessage.current);
      } finally {
        setIsFetching(false);
      }
    })();
  }, [getTopAccounts, showToast]);

  useEffect(() => {
    async function fetchSuggestedAccounts(query: string) {
      try {
        setIsFetching(true);
        const { accounts: _accounts } = await getSuggestedAccounts({ query });
        setAccounts(_accounts);
      } catch (err) {
        showToast(accountsErrorMessage.current);
      } finally {
        setIsFetching(false);
      }
    }

    //  Only start suggesting accounts when we
    //  have a long enough input string
    if (debouncedFieldValue.trim().length >= MIN_QUERY_LENGTH_BEFORE_SUGGEST) {
      fetchSuggestedAccounts(debouncedFieldValue);
    } else {
      //  Otherwise just restore the top accounts list
      setAccounts(prevAccounts.current);
    }
  }, [debouncedFieldValue, getSuggestedAccounts, showToast]);

  const handleAccountSwitch = async (
    newAdvertiserId: number,
    countryCode: string,
  ) => {
    setIsSwitchingAccounts(true);
    sendAccountSwitchEvent();
    /**
     * Persists the selected advertiserId so that we can
     * use this value on the next log in as a preferred account
     */
    dispatch(
      settingsActionCreators.updatePreferredAdvertiserId(newAdvertiserId),
    );

    if (IS_LEGACY_SITE) {
      redirectToStartPage();
      return;
    }

    const scope = [`advertiser:${newAdvertiserId}`];
    const { actAsEmail } = getDetails() ?? {};

    if (actAsEmail) {
      scope.push(`act:${actAsEmail}`);
    }

    const site =
      siteNames.employer.seek[
        countryCode.toLowerCase() as keyof typeof siteNames.employer.seek
      ];

    const siteConfigObj = siteConfig[site];

    const language = (siteConfigObj.languages as string[]).includes(LANGUAGE)
      ? LANGUAGE
      : undefined;

    if (ENVIRONMENT === Environment.Development) {
      forwardToPath(window.location.href);
      return;
    }

    const loginUrl = oauth.buildLoginWithScopeUrl({
      origin:
        ENVIRONMENT === Environment.Production
          ? siteConfigObj.host
          : siteConfigObj.stagingHost,
      returnUri: `${TALENTSEARCH_PREFIX}/`,
      language,
      scope,
    });

    forwardToPath(`https://${loginUrl}`);
  };

  return (
    <MenuGroup>
      <MenuGroup.Title text={t('ACCOUNTS')} />
      <MenuGroup.Content>
        <>
          {totalAccounts >= MIN_ACCOUNTS_BEFORE_SUGGEST && (
            <Box paddingX="medium" paddingTop="none" paddingBottom="small">
              <TextField
                id="accounts-suggestion"
                aria-label={t('SEARCH_BY_ACCOUNT_NAME_OR_NUMBER')}
                placeholder={t('SEARCH_BY_ACCOUNT_NAME_OR_NUMBER')}
                autoComplete={fieldValue.length > 0 ? 'on' : 'off'}
                value={fieldValue}
                onClear={() => setFieldValue('')}
                onChange={(event) =>
                  setFieldValue((event.target as HTMLInputElement).value)
                }
              />
            </Box>
          )}

          {isFetching ? (
            <Box padding="medium">
              <Inline align="center" space="none">
                <Loader />
              </Inline>
            </Box>
          ) : (
            <Box {...(hasScroll && { className: styles.scrollable })}>
              {isSwitchingAccounts && <LoaderOverlay hasBackground isInline />}
              {accounts.map(({ id, name, accountNumber, companyAddress }) => {
                const isActive = accountNumber === activeAccountNumber;
                return (
                  <Box
                    key={accountNumber}
                    data-cy={Tags.Data.Account}
                    data-name="account"
                    aria-selected={isActive}
                    cursor="pointer"
                    paddingX="medium"
                    paddingY="medium"
                    onClick={() =>
                      isActive
                        ? onSelect()
                        : handleAccountSwitch(
                            id,
                            companyAddress?.country ?? CountryCodes.AU,
                          )
                    }
                    className={[styles.option]}
                  >
                    <Text weight={isActive ? 'strong' : 'regular'}>
                      {name} ({accountNumber})
                    </Text>
                  </Box>
                );
              })}
              {!isFetching && accounts.length === 0 && (
                <Box padding="gutter">
                  <Text>{t('NO_ACCOUNTS_FOUND')}</Text>
                </Box>
              )}
            </Box>
          )}
        </>
      </MenuGroup.Content>
    </MenuGroup>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default AccountsList;
