import type { RootState } from 'src/types';

import tealiumSelectors from '../selectors';

export const getCurrentPage = (snapshot: RootState) =>
  tealiumSelectors
    .getPageSelector(snapshot)
    .replace('-page', '')
    .split('-')
    .map((pagePiece) => (pagePiece.toLowerCase() !== 'page' ? pagePiece : ''))
    .join(' ');
