import {
  type ClientConfig,
  getConfig,
  talent_seek_com_au,
  talent_seek_co_nz,
  my_employer_seek_com,
  hk_employer_seek_com,
  id_employer_seek_com,
  ph_employer_seek_com,
  sg_employer_seek_com,
  th_employer_seek_com,
  talent_staging_seek_co_nz,
  talent_staging_seek_com_au,
  hk_employer_staging_seek_com,
  id_employer_staging_seek_com,
  my_employer_staging_seek_com,
  ph_employer_staging_seek_com,
  sg_employer_staging_seek_com,
  th_employer_staging_seek_com,
} from '@seek/online-identity';

import { type CountryCodes, Environment } from '../../types';

const productionConfig: Record<CountryCodes, ClientConfig> = {
  AU: talent_seek_com_au,
  NZ: talent_seek_co_nz,
  HK: hk_employer_seek_com,
  TH: th_employer_seek_com,
  MY: my_employer_seek_com,
  SG: sg_employer_seek_com,
  ID: id_employer_seek_com,
  PH: ph_employer_seek_com,
};

const stagingConfig: Record<CountryCodes, ClientConfig> = {
  AU: talent_staging_seek_com_au,
  NZ: talent_staging_seek_co_nz,
  HK: hk_employer_staging_seek_com,
  TH: th_employer_staging_seek_com,
  MY: my_employer_staging_seek_com,
  SG: sg_employer_staging_seek_com,
  ID: id_employer_staging_seek_com,
  PH: ph_employer_staging_seek_com,
};

const createClientConfig = (
  environment: Environment,
  countryCode: CountryCodes,
): ClientConfig => {
  if (typeof window !== 'undefined') {
    return getConfig('seekAnz-hirer', window.location);
  }

  if (environment === Environment.Production) {
    return productionConfig[countryCode];
  }

  return stagingConfig[countryCode];
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default createClientConfig;
