import axios from 'axios';
import { v4 as uuid } from 'uuid';

import type { MetricsProviderConfig } from '../../hooks/useMetrics';
import type { AppConfig, RootState } from '../../types';

export const DATAPLATFORM_KEY = 'dataPlatform';

export const sideEffects = {
  getTimestamp: () => new Date().toISOString(),
};

type Handler = (
  { value }: { value: Record<string, unknown> },
  snapshot: RootState,
) => Promise<void>;

const provider: MetricsProviderConfig<Handler> = {
  key: DATAPLATFORM_KEY,
  createHandler: ({ TRACKING_URL }: AppConfig) => ({
    send: async ({ value }) => {
      const { eventName, metrics } = value;
      if (!(eventName && metrics)) {
        return;
      }
      const tracking = window.btoa(JSON.stringify(metrics));
      const timestamp = sideEffects.getTimestamp();
      const trackingId = uuid();

      await axios.get(
        `${TRACKING_URL}/a.png?events=type:${eventName},id:${trackingId},timestamp:${timestamp},tracking:${tracking}`,
        { withCredentials: true },
      );
    },
  }),
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default provider;
