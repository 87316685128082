function formatNumber(input: number | null | undefined): string {
  if (input === null || typeof input === 'undefined') {
    return '';
  }
  return new Intl.NumberFormat('en-AU').format(input);
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default formatNumber;
