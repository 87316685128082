import classNames from 'classnames';
import { useContext } from 'react';

import { ThemedBraidProvider } from '../../../../hooks/useStyleGuide';
import Context from '../Context';
import { type OpenFrom, AnimationState } from '../types';

import * as styles from '../Drawer.css';

interface Props {
  children: React.ReactNode;
  openFrom: OpenFrom;
  state: string;
  isOpen?: boolean;
}

function Content({ children, openFrom, state }: Props) {
  const {
    theme: {
      drawerClassName,
      drawerEnterClassName,
      drawerExitClassName,
      drawerScrollClassName,
    },
  } = useContext(Context);

  return (
    <div
      className={classNames(
        styles.drawer,
        styles[openFrom],
        styles[state as keyof typeof styles],

        //  Theme overrides
        drawerClassName,
        {
          // @ts-expect-error
          [drawerEnterClassName]: state === AnimationState.Entered,
          // @ts-expect-error
          [drawerExitClassName]: state === AnimationState.Exiting,
        },
      )}
    >
      <div className={classNames(styles.scroll, drawerScrollClassName)}>
        <ThemedBraidProvider>{children}</ThemedBraidProvider>
      </div>
    </div>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default Content;
