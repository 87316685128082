import { AccountType } from 'src/metrics/tealium/types';

import {
  ProductType,
  type RootState,
  type CreditRateTypes,
  AccessMethods,
} from '../../types';

/*
 * Used to check whether initial user context is available
 */
export const getHasUserContextSelector = ({ user }: RootState) =>
  user.hasUserContext;

/*
 * Used to check whether initial and extended user context is available
 */
export const getHasAllRequiredUserContextSelector = ({ user }: RootState) =>
  user.hasAllRequiredUserContext;

//  @deprecated - use getHasAllRequiredUserContextSelector
export const getInitialDataStateSelector = getHasAllRequiredUserContextSelector;

/**
 * @returns a flag to denote whether the user is authenticated (with access_token)
 */
export const isUserAuthenticatedSelector = ({ user }: RootState) =>
  Boolean(user.isAuthenticated);

/**
 * @returns the search query string of the current router location.
 */
export const getSearchSelector = ({ router }: RootState) => router.search;
/**
 * @returns credit rates for user
 */
export const getAllCreditRatesSelector = ({ user }: RootState) => user.rates;

/**
 * @returns credit rates by type
 */
export const getCreditRateByTypeSelector = (
  { user }: RootState,
  type: CreditRateTypes,
): number => user.rates[type];

/**
 * @returns the user ID
 */
export const getLastAccessMethodSelector = ({ user }: RootState) =>
  user.accessMethod || AccessMethods.Unknown;

/**
 * @returns the user ID
 */
export const getUserIdSelector = ({ user }: RootState) => user.user.id;

/**
 * @returns the first name of the user
 */
export const getUserFirstNameSelector = ({ user }: RootState) =>
  user.user.firstName;

/**
 * @returns the last name of the user
 */
export const getUserLastNameSelector = ({ user }: RootState) =>
  user.user.lastName;

/**
 * @returns the email of the user
 */
export const getUserEmailSelector = ({ user }: RootState) => user.user.email;

/**
 * @returns the advertiser ID (SEEK ID)
 */
export const getAdvertiserIdSelector = ({ user }: RootState) =>
  user.advertiser.id;

/**
 * @returns the advertiser name
 */
export const getAdvertiserNameSelector = ({ user }: RootState) =>
  user.advertiser.name;

/**
 * @returns the SAP advertiser account number
 */
export const getAdvertiserAccountNumberSelector = ({ user }: RootState) =>
  user.advertiser.accountNumber;

/**
 * @returns a PTS or TSC product type identifier (any premium product)
 */
export const isPremiumSelector = ({ user }: RootState) =>
  user.advertiser.isPremium;

/**
 * Used to generate productType information for data platform and tealium.
 * @returns the product type information of the advertiser.
 */
export const getProducTypeForMetrics = (
  { user }: RootState,
  isDataPlatform: boolean,
) => {
  if (user.user.isConnectedTalentSearchUser) {
    return isDataPlatform ? ProductType.Connected : AccountType.Connected;
  }

  if (user.advertiser.isPremium) {
    return isDataPlatform ? ProductType.Premium : AccountType.Premium;
  }

  return isDataPlatform ? ProductType.Standard : AccountType.Standard;
};

/**
 * @returns an identifier for whether the user is TSC (i.e. JobAdder etc)
 */
export const isConnectedTalentSearchUserSelector = ({ user }: RootState) =>
  user.user.isConnectedTalentSearchUser;

/**
 * @deprecated - Indirect users are no longer a thing
 * @returns an identifier for whether the user has logged in indirectly (JLo - legacy JobAdder)
 */
export const isIndirectUserSelector = ({ user }: RootState) =>
  !Boolean(user.user?.id);

/**
 * @returns the name of the ATS if logged into TSC, otherwise a fallback name if not found
 */
export const getAtsNameSelector = ({ user }: RootState) => user.ats?.name || '';

/**
 * @returns the ID of the ATS if logged into TSC
 */
export const getAtsIdSelector = ({ user }: RootState) =>
  user.ats?.atsId || null;

/**
 * @returns either the ATS name or fallback
 */
export const getDatabaseNameSelector = (state: RootState) => {
  let databaseName;
  if (isConnectedTalentSearchUserSelector(state)) {
    databaseName = getAtsNameSelector(state);
  }
  return databaseName || 'your database';
};

/**
 * @returns the first PTS trial contract (if applicable)
 */
export const getPremiumContract = ({ user }: RootState) =>
  user.advertiser.premiumContract || null;

/**
 * @returns the start date for first PTS trial contract
 */
export const getPremiumContractStartDate = ({ user }: RootState) =>
  user.advertiser.premiumContract?.startDate || null;

/**
 * @deprecated - No longer used, should be removed
 */
export const getIsHirerSelector = ({ user }: RootState) =>
  Boolean(user.user.isHirer);

/**
 * @deprecated - No longer used, should be removed
 */
export const getCompanyIdSelector = ({ user }: RootState) =>
  user.advertiser.companyId;

/**
 * @returns Test tags as specified in RFC039
 */
export const getHirerAccountTagsSelector = ({ user }: RootState) =>
  user.advertiser.tags;
