import { PureComponent } from 'react';

import type { ContextType as DatadogRumContextType } from 'src/hooks/useDatadogRum/types';

interface Props {
  children: ({ hasError }: { hasError: boolean }) => React.ReactNode;
  logError: DatadogRumContextType['logError'];
}

interface State {
  hasError: boolean;
}

class ErrorBoundaryHandler extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.props.logError('Error Boundary caught an error', { error, errorInfo });
    this.setState({ hasError: true });
  }

  render() {
    return this.props.children({
      hasError: this.state.hasError,
    });
  }
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default ErrorBoundaryHandler;
