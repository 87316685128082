import { TEALIUM_KEY } from '../tealium';
import { Global as GlobalLinks, UtagActions } from '../tealium/types';

const actions = {
  accountSwitch: () => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = GlobalLinks.AccountSwitch;

        return {
          action: UtagActions.Link,
          value: {
            eventName,
          },
        };
      },
    },
  ],
  credits: () => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = GlobalLinks.Credits;

        return {
          action: UtagActions.Link,
          value: {
            eventName,
          },
        };
      },
    },
  ],
  toggleEthicalHiring: (ethicalHiringValue: boolean) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = Boolean(ethicalHiringValue)
          ? GlobalLinks.HideNamesToggleOn
          : GlobalLinks.HideNamesToggleOff;

        return {
          action: UtagActions.Link,
          value: {
            eventName,
          },
        };
      },
    },
  ],
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default actions;
