import type { Action } from '../../types';

import * as actions from './actions';

interface State {
  initialAmount: number;
  amount: number;
}

const initialState: State = {
  initialAmount: 0,
  amount: 0,
};

const deductCreditsFromBalance = (
  { amount }: State,
  creditRate: number,
): number => amount - creditRate;

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actions.DEDUCT_USER_BALANCE_AMOUNT: {
      return {
        ...state,
        amount: deductCreditsFromBalance(state, action.value),
      };
    }

    case actions.UPDATE_USER_BALANCE_TO_AMOUNT: {
      return {
        ...state,
        amount: action.value,
      };
    }

    default:
      return state;
  }
};
