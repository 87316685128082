import { useContext } from 'react';

import Context from './Context';

function useImpersonation() {
  return useContext(Context);
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default useImpersonation;
