/**
 * Hotjar SEEK account id
 */
export enum HotjarId {
  Premium = 182779,
  Standard = 195189,
}

export interface HotjarContext {
  trigger: (pollKey: PollKey) => void;
  triggerEvent: (eventName: HotjarEvent) => void;
}

export type PollKey = '';

export interface Params {
  isPremiumUser: boolean;
  advertiserId: number;
}

export interface Poll {
  key: PollKey;
  test: (params: Params) => boolean;
}

export type HotjarEvent = 'profile-drawer-closed';

export type HotjarEventType = 'stateChange' | 'trigger' | 'event';
