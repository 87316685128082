import { getProfilePositionSelector } from '../../store/profiles/selectors';
import { getActiveJobIdSelector } from '../../store/router/selectors';
import {
  getSearchIdSelector,
  getSimilarCandidatesSearchIdSelector,
} from '../../store/searches/selectors';
import { getUserSessionIdSelector } from '../../store/settings/selectors';
import {
  getUserIdSelector,
  getAdvertiserIdSelector,
  getCompanyIdSelector,
  getHirerAccountTagsSelector,
  getProducTypeForMetrics,
} from '../../store/user/selectors';
import type { ProfileV2, RootState } from '../../types';
import { DATAPLATFORM_KEY } from '../dataPlatform';
import dataPlatformSelectors from '../dataPlatform/selectors';
import {
  Source,
  TabLocations,
  EventName as DataPlatformEventName,
} from '../dataPlatform/types';
import getDefaultProfileFields from '../dataPlatform/utils/getDefaultProfileFields';
import { TEALIUM_KEY } from '../tealium';
import { Profile as ProfileLinks, UtagActions } from '../tealium/types';

interface Payload {
  profile: ProfileV2;
}

const actions = {
  profileTabViewed: ({ profile }: Payload) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = ProfileLinks.ProfileTabClick;
        const { profileType, profileId } = profile;

        return {
          action: UtagActions.Link,
          value: {
            eventName,
            profileId,
            profileType,
          },
        };
      },
    },
  ],
  cvPreviewTabViewed: ({ profile }: Payload) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = ProfileLinks.CVPreviewTabClick;
        const { profileType, profileId } = profile;

        return {
          action: UtagActions.Link,
          value: {
            eventName,
            profileId,
            profileType,
          },
        };
      },
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const defaultProfileFields = getDefaultProfileFields(profile);
        const connectionId = null;

        const jobId = getActiveJobIdSelector(snapshot);
        const advertiserId = getAdvertiserIdSelector(snapshot);
        const userId = getUserIdSelector(snapshot);
        const userSessionId = getUserSessionIdSelector(snapshot);
        const organisationId = getCompanyIdSelector(snapshot);
        const searchId = getSearchIdSelector(snapshot);
        const fromProfilePage =
          dataPlatformSelectors.getFromProfilePageSelector(snapshot);
        const site = dataPlatformSelectors.getSiteValueSelector(snapshot);
        const profilePosition = getProfilePositionSelector(snapshot, profile);
        const similarCandidatesSearchId =
          getSimilarCandidatesSearchIdSelector(snapshot);
        const tags = getHirerAccountTagsSelector(snapshot);
        const productType = getProducTypeForMetrics(snapshot, true);

        return {
          value: {
            eventName: DataPlatformEventName.TSCvPreviewed,
            metrics: {
              source: Source.Site,
              site,
              resultListTab: TabLocations.Results,
              jobId,
              advertiserId,
              userId,
              userSessionId,
              organisationId,
              searchId,
              similarCandidatesSearchId,
              productType,
              profile: {
                ...defaultProfileFields,
                profilePosition: profilePosition ?? 0,
                fromProfilePage,
              },
              connectionId,
              tags: tags
                ? { ...tags, testRecord: tags.testRecord === 'true' }
                : undefined,
            },
          },
        };
      },
    },
  ],
  interactionsTabViewed: ({ profile }: Payload) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = ProfileLinks.InteractionsTabClick;

        const { profileType, profileId } = profile;

        return {
          action: UtagActions.Link,
          value: {
            eventName,
            profileId,
            profileType,
          },
        };
      },
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const defaultProfileFields = getDefaultProfileFields(profile);
        const connectionId = null;

        const jobId = getActiveJobIdSelector(snapshot);
        const advertiserId = getAdvertiserIdSelector(snapshot);
        const userId = getUserIdSelector(snapshot);
        const userSessionId = getUserSessionIdSelector(snapshot);
        const organisationId = getCompanyIdSelector(snapshot);
        const searchId = getSearchIdSelector(snapshot);
        const fromProfilePage =
          dataPlatformSelectors.getFromProfilePageSelector(snapshot);
        const site = dataPlatformSelectors.getSiteValueSelector(snapshot);
        const profilePosition = getProfilePositionSelector(snapshot, profile);
        const similarCandidatesSearchId =
          getSimilarCandidatesSearchIdSelector(snapshot);
        const tags = getHirerAccountTagsSelector(snapshot);
        const productType = getProducTypeForMetrics(snapshot, true);
        return {
          value: {
            eventName: DataPlatformEventName.TSInteractionsTabOpened,
            metrics: {
              source: Source.Site,
              site,
              resultListTab: TabLocations.Results,
              jobId,
              advertiserId,
              userId,
              userSessionId,
              organisationId,
              searchId,
              similarCandidatesSearchId,
              productType,
              profile: {
                ...defaultProfileFields,
                profilePosition: profilePosition ?? 0,
                fromProfilePage,
              },
              connectionId,
              tags: tags
                ? { ...tags, testRecord: tags.testRecord === 'true' }
                : undefined,
            },
          },
        };
      },
    },
  ],
  noInteractionsTabViewed: ({ profile }: Payload) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = ProfileLinks.NoInteractionsTabClick;

        const { profileType, profileId } = profile;

        return {
          action: UtagActions.Link,
          value: {
            eventName,
            profileId,
            profileType,
          },
        };
      },
    },
  ],
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default actions;
