import { useMemo, useContext, useRef } from 'react';

import Context from './Context';
import type { ContextType } from './types';

type Unmapped = Record<string, (...args: any[]) => any>;

type Mapper<T extends (...args: any[]) => any> = (
  ...args: Parameters<T>
) => void;

type Mapped<T extends Unmapped> = {
  [K in keyof T]: Mapper<T[K]>;
};

function useMetrics<T extends Unmapped>(actions: T): Mapped<T> {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useMetrics must be called from within MetricsProvider');
  }

  const { dispatchEvent } = context as ContextType;
  const unmapped = useRef<T>(actions);

  return useMemo(() => {
    const mapped = {} as Mapped<T>;
    for (const key in unmapped.current) {
      if (unmapped.current.hasOwnProperty(key)) {
        const action = unmapped.current[key];
        mapped[key] = (...args) => {
          dispatchEvent(action(...args));
        };
      }
    }
    return mapped;
  }, [dispatchEvent]);
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default useMetrics;
