import type * as Apollo from '@apollo/client';
import type { ErrorResponse } from '@apollo/client/link/error';
import { useSelector } from 'react-redux';

import useAuth from 'src/hooks/useAuth';
import useNotifications from 'src/hooks/useNotifications';
import useRouter from 'src/hooks/useRouter';
import { useRoutes } from 'src/hooks/useRoutes';
import errorService from 'src/services/error';
import messageService from 'src/services/message';
import { selectors as userSelectors } from 'src/store/user';
import { ErrorTypes, AccessMethods } from 'src/types';

export const wrapLazyQuery = <
  Query,
  Variable extends Apollo.OperationVariables,
>(
  useExistingHook: (
    params?: Apollo.LazyQueryHookOptions<Query, Variable>,
  ) => Apollo.LazyQueryResultTuple<Query, Variable>,
) => {
  const useWrappedHook = (
    params?: Apollo.LazyQueryHookOptions<Query, Variable>,
  ) => {
    const { navigateToError } = useRouter();
    const { loginWithCallback } = useAuth();
    const { routes } = useRoutes();
    const { showBanner } = useNotifications();
    const lastAccessMethod = useSelector(
      userSelectors.getLastAccessMethodSelector,
    );
    return useExistingHook({
      ...params,
      onError: (err: Apollo.ApolloError) => {
        if (errorService.isUnrecoverableError(err)) {
          return navigateToError(routes, ErrorTypes.UNAUTHORISED, {
            err,
          });
        }

        if (errorService.isUnauthorisedError(err as unknown as ErrorResponse)) {
          return lastAccessMethod === AccessMethods.Direct
            ? loginWithCallback()
            : navigateToError(routes, ErrorTypes.UNAUTHORISED, {
                err,
              });
        }

        const matchingMessageForError =
          messageService.createMessageFromError(err);
        if (matchingMessageForError) {
          showBanner(matchingMessageForError);
        }
      },
    });
  };
  return useWrappedHook;
};
