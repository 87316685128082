
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6IuC4lOC4tuC4h+C4guC5ieC4reC4oeC4ueC4peC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g+C4iuC5iSIsIkFDQ09VTlRTIjoi4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJIiwiU0VBUkNIX0JZX0FDQ09VTlRfTkFNRV9PUl9OVU1CRVIiOiLguITguYnguJnguKvguLLguIjguLLguIHguIrguLfguYjguK3guK3guIfguITguYzguIHguKPguKvguKPguLfguK3guKvguKHguLLguKLguYDguKXguILguKrguKHguLLguIrguLTguIEiLCJOT19BQ0NPVU5UU19GT1VORCI6IuC5hOC4oeC5iOC4nuC4muC4muC4seC4jeC4iuC4tSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6IuC4lOC4tuC4h+C4guC5ieC4reC4oeC4ueC4peC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g+C4iuC5iSIsIkFDQ09VTlRTIjoi4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJIiwiU0VBUkNIX0JZX0FDQ09VTlRfTkFNRV9PUl9OVU1CRVIiOiLguITguYnguJnguKvguLLguIjguLLguIHguIrguLfguYjguK3guK3guIfguITguYzguIHguKPguKvguKPguLfguK3guKvguKHguLLguKLguYDguKXguILguKrguKHguLLguIrguLTguIEiLCJOT19BQ0NPVU5UU19GT1VORCI6IuC5hOC4oeC5iOC4nuC4muC4muC4seC4jeC4iuC4tSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6Im1lbmNhcmkgYWt1biIsIkFDQ09VTlRTIjoiQWt1biIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiQ2FyaSBiZXJkYXNhcmthbiBuYW1hIGF0YXUgbm9tb3IgYWt1biIsIk5PX0FDQ09VTlRTX0ZPVU5EIjoiVGlkYWsgYWRhIGFrdW4geWFuZyBkaXRlbXVrYW4uIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6Im1lbmNhcmkgYWt1biIsIkFDQ09VTlRTIjoiQWt1biIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiQ2FyaSBiZXJkYXNhcmthbiBuYW1hIGF0YXUgbm9tb3IgYWt1biIsIk5PX0FDQ09VTlRTX0ZPVU5EIjoiVGlkYWsgYWRhIGFrdW4geWFuZyBkaXRlbXVrYW4uIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6IuC4lOC4tuC4h+C4guC5ieC4reC4oeC4ueC4peC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g+C4iuC5iSIsIkFDQ09VTlRTIjoi4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJIiwiU0VBUkNIX0JZX0FDQ09VTlRfTkFNRV9PUl9OVU1CRVIiOiLguITguYnguJnguKvguLLguIjguLLguIHguIrguLfguYjguK3guK3guIfguITguYzguIHguKPguKvguKPguLfguK3guKvguKHguLLguKLguYDguKXguILguKrguKHguLLguIrguLTguIEiLCJOT19BQ0NPVU5UU19GT1VORCI6IuC5hOC4oeC5iOC4nuC4muC4muC4seC4jeC4iuC4tSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6IuC4lOC4tuC4h+C4guC5ieC4reC4oeC4ueC4peC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g+C4iuC5iSIsIkFDQ09VTlRTIjoi4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJIiwiU0VBUkNIX0JZX0FDQ09VTlRfTkFNRV9PUl9OVU1CRVIiOiLguITguYnguJnguKvguLLguIjguLLguIHguIrguLfguYjguK3guK3guIfguITguYzguIHguKPguKvguKPguLfguK3guKvguKHguLLguKLguYDguKXguILguKrguKHguLLguIrguLTguIEiLCJOT19BQ0NPVU5UU19GT1VORCI6IuC5hOC4oeC5iOC4nuC4muC4muC4seC4jeC4iuC4tSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6Im1lbmNhcmkgYWt1biIsIkFDQ09VTlRTIjoiQWt1biIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiQ2FyaSBiZXJkYXNhcmthbiBuYW1hIGF0YXUgbm9tb3IgYWt1biIsIk5PX0FDQ09VTlRTX0ZPVU5EIjoiVGlkYWsgYWRhIGFrdW4geWFuZyBkaXRlbXVrYW4uIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6Im1lbmNhcmkgYWt1biIsIkFDQ09VTlRTIjoiQWt1biIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiQ2FyaSBiZXJkYXNhcmthbiBuYW1hIGF0YXUgbm9tb3IgYWt1biIsIk5PX0FDQ09VTlRTX0ZPVU5EIjoiVGlkYWsgYWRhIGFrdW4geWFuZyBkaXRlbXVrYW4uIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6ImZldGNoIGFjY291bnRzIiwiQUNDT1VOVFMiOiJBY2NvdW50cyIsIlNFQVJDSF9CWV9BQ0NPVU5UX05BTUVfT1JfTlVNQkVSIjoiU2VhcmNoIGJ5IGFjY291bnQgbmFtZSBvciBudW1iZXIiLCJOT19BQ0NPVU5UU19GT1VORCI6Ik5vIGFjY291bnRzIGZvdW5kLiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6IlvGkuG6veG6veG6veG5r8On4bipIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua/FoV0iLCJBQ0NPVU5UUyI6IlvhuqzDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvxaFdIiwiU0VBUkNIX0JZX0FDQ09VTlRfTkFNRV9PUl9OVU1CRVIiOiJbxaDhur3hur3hur3Eg8SDxIPFmcOn4bipIMOfw73DvcO9IMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua8g4LiB4Li14LmJxIPEg8SDbcyC4bq94bq94bq9IMO2w7bDtsWZIOC4geC4teC5iceax5rHmm3MgsOf4bq94bq94bq9xZldIiwiTk9fQUNDT1VOVFNfRk9VTkQiOiJbw5HDtsO2w7YgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5r8WhIMaSw7bDtsO2x5rHmsea4LiB4Li14LmJxowuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGRVRDSF9BQ0NPVU5UUyI6IlvGkuG6veG6veG6veG5r8On4bipIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua/FoV0iLCJBQ0NPVU5UUyI6IlvhuqzDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvxaFdIiwiU0VBUkNIX0JZX0FDQ09VTlRfTkFNRV9PUl9OVU1CRVIiOiJbxaDhur3hur3hur3Eg8SDxIPFmcOn4bipIMOfw73DvcO9IMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua8g4LiB4Li14LmJxIPEg8SDbcyC4bq94bq94bq9IMO2w7bDtsWZIOC4geC4teC5iceax5rHmm3MgsOf4bq94bq94bq9xZldIiwiTk9fQUNDT1VOVFNfRk9VTkQiOiJbw5HDtsO2w7YgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5r8WhIMaSw7bDtsO2x5rHmsea4LiB4Li14LmJxowuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    