import { DELETE_SAVED_SEARCH } from '../../../queries/TalentSearchSavedSearch';
import type { Services } from '../../../types';

const dataService: Services.DataService<any, string> = ({ mutate }) =>
  function deleteSavedSearch(criteriaHash) {
    return mutate<{
      deleteSavedSearch: {
        deletedCriteriaHash: string;
      };
    }>({
      mutation: DELETE_SAVED_SEARCH,
      variables: {
        criteriaHash,
      },
    });
  };

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default dataService;
