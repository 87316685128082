import * as actions from './actions';

export const updateSearchId = (searchId: string) => ({
  type: actions.UPDATE_SEARCH_ID,
  value: {
    searchId,
  },
});

export const updateSimilarCandidatesSearchId = (
  similarCandidatesSearchId: string,
) => ({
  type: actions.UPDATE_SIMILAR_CANDIDATES_SEARCH_ID,
  value: {
    similarCandidatesSearchId,
  },
});

export const clearSimilarCandidatesSearchId = () => ({
  type: actions.CLEAR_SIMILAR_CANDIDATES_SEARCH_ID,
  value: null,
});
