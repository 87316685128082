import type {
  AccessMethods,
  User,
  CreditRates,
  TalentSearchFeature,
} from '../../types';

import * as actions from './actions';

/*
 * Set TS-API authentication status and access method (Direct | Integrated | Unknown)
 */
export interface IsAuthenticatedPayload {
  isAuthenticated: boolean;
  accessMethod: AccessMethods;
}

export const setIsAuthenticated = ({
  isAuthenticated,
  accessMethod,
}: IsAuthenticatedPayload) => ({
  type: actions.RECEIVE_IS_AUTHENTICATED_DATA,
  value: {
    isAuthenticated,
    accessMethod,
  },
});

/*
 * Set advertiser, ats, and user details into global state
 */
export const setUserContext = (userDetails: User) => ({
  type: actions.RECEIVE_USER_CONTEXT_DATA,
  value: userDetails,
});

/*
 * Set feature flags and credit rates into global state
 */
export const setExtendedUserContext = ({
  featureFlags,
  rates,
}: {
  featureFlags: TalentSearchFeature[];
  rates: CreditRates;
}) => ({
  type: actions.RECEIVE_EXTENDED_USER_CONTEXT_DATA,
  value: {
    featureFlags,
    rates,
  },
});
