import type { TalentSearchMarket } from 'src/types/generated';

import { GET_MARKETS } from '../../../queries/TalentSearchMetadata';
import type { Language, Services } from '../../../types';

export interface GetMarketsParam {
  language: Language;
}

const dataService: Services.DataService<
  TalentSearchMarket[],
  GetMarketsParam
> = ({ query }) =>
  async function getMarkets({ language }) {
    const data = await query<{
      talentSearchMetadataMarkets: TalentSearchMarket[];
    }>({
      query: GET_MARKETS,
      variables: {
        input: { language },
      },
    });
    return data?.talentSearchMetadataMarkets || [];
  };

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default dataService;
