import type { RootState } from '../../types';
import { getSeedProfileIdSelector } from '../router/selectors';

export const getSearchIdSelector = ({ search }: RootState): string =>
  search.searchId;

export const getSimilarCandidatesSearchIdSelector = (
  state: RootState,
): string | null => {
  const seedProfileId = getSeedProfileIdSelector(state);

  return seedProfileId ? state.search.similarCandidatesSearchId : null;
};
