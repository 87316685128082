import { useTranslations } from '@vocab/react';
import { useMemo } from 'react';

import translations from '../../services/message/.vocab';
import { getCreateMessageFromTemplate } from '../../services/message/createMessageFromTemplate';

interface WrappedFunctions {
  createMessageFromTemplate: ReturnType<typeof getCreateMessageFromTemplate>;
}

const useCreateMessage = (): WrappedFunctions => {
  const { t, ready } = useTranslations(translations);

  const wrappedFuncs = useMemo(
    () => ({
      createMessageFromTemplate: getCreateMessageFromTemplate(t),
    }),
    // Adding 'ready' flag alone is enough to determine if need to re-run again.
    // if adding 't' into dep array, will cause unnecessary re-rendering of this memo as
    // 't' reference is different every time returned from 'useTranslations'
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ready],
  );

  return wrappedFuncs;
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default useCreateMessage;
