
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoi4LiV4Li04LiU4LiV4LmI4Lit4LmA4Lij4LiyIiwiQ1VTVE9NRVJfU0VSVklDRSI6IuC4muC4o+C4tOC4geC4suC4o+C4peC4ueC4geC4hOC5ieC4siAiLCJMRUFSTl9NT1JFIjoi4LiC4LmJ4Lit4Lih4Li54Lil4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04LihIiwiUFJJVkFDWSI6IuC4hOC4s+C4iuC4teC5ieC5geC4iOC4h+C4quC4tOC4l+C4mOC4tOC4quC5iOC4p+C4meC4muC4uOC4hOC4hOC4pSIsIlRFUk1TX0FORF9DT05ESVRJT05TIjoi4LiC4LmJ4Lit4LiB4Liz4Lir4LiZ4LiU4LmB4Lil4Liw4LmA4LiH4Li34LmI4Lit4LiZ4LmE4LiCIiwiU0lHTl9TRUVLX1lFQVJfQUxMX1JJR0hUU19SRVNFUlZFRCI6IntzaWdufSBTRUVLIHt5ZWFyfSDguKrguIfguKfguJnguKXguLTguILguKrguLTguJfguJjguLTguYwiLCJSRUdJU1RSQVRJT05fTk8iOiLguYDguKXguILguJfguLDguYDguJrguLXguKLguJnguJnguLTguJXguLTguJrguLjguITguITguKU6IHtyZWdpc3RyYXRpb25OdW1iZXJ9IiwiSEVMUF9DRU5UUkUiOiLguKjguLnguJnguKLguYzguITguKfguLLguKHguIrguYjguKfguKLguYDguKvguKXguLfguK0iLCJURVJNU19PRl9VU0UiOiLguILguYnguK3guJXguIHguKXguIfguYPguJnguIHguLLguKPguYPguIrguYnguIfguLLguJkifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoi4LiV4Li04LiU4LiV4LmI4Lit4LmA4Lij4LiyIiwiQ1VTVE9NRVJfU0VSVklDRSI6IuC4muC4o+C4tOC4geC4suC4o+C4peC4ueC4geC4hOC5ieC4siAiLCJMRUFSTl9NT1JFIjoi4LiC4LmJ4Lit4Lih4Li54Lil4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04LihIiwiUFJJVkFDWSI6IuC4hOC4s+C4iuC4teC5ieC5geC4iOC4h+C4quC4tOC4l+C4mOC4tOC4quC5iOC4p+C4meC4muC4uOC4hOC4hOC4pSIsIlRFUk1TX0FORF9DT05ESVRJT05TIjoi4LiC4LmJ4Lit4LiB4Liz4Lir4LiZ4LiU4LmB4Lil4Liw4LmA4LiH4Li34LmI4Lit4LiZ4LmE4LiCIiwiU0lHTl9TRUVLX1lFQVJfQUxMX1JJR0hUU19SRVNFUlZFRCI6IntzaWdufSBTRUVLIHt5ZWFyfSDguKrguIfguKfguJnguKXguLTguILguKrguLTguJfguJjguLTguYwiLCJSRUdJU1RSQVRJT05fTk8iOiLguYDguKXguILguJfguLDguYDguJrguLXguKLguJnguJnguLTguJXguLTguJrguLjguITguITguKU6IHtyZWdpc3RyYXRpb25OdW1iZXJ9IiwiSEVMUF9DRU5UUkUiOiLguKjguLnguJnguKLguYzguITguKfguLLguKHguIrguYjguKfguKLguYDguKvguKXguLfguK0iLCJURVJNU19PRl9VU0UiOiLguILguYnguK3guJXguIHguKXguIfguYPguJnguIHguLLguKPguYPguIrguYnguIfguLLguJkifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiSHVidW5naSBrYW1pIiwiQ1VTVE9NRVJfU0VSVklDRSI6IkxheWFuYW4gUGVsYW5nZ2FuOiAiLCJMRUFSTl9NT1JFIjoiUGVsYWphcmkgU2VsZW5na2FwbnlhIiwiUFJJVkFDWSI6IlByaXZhc2kiLCJURVJNU19BTkRfQ09ORElUSU9OUyI6IlN5YXJhdCBkYW4gS2V0ZW50dWFuIiwiU0lHTl9TRUVLX1lFQVJfQUxMX1JJR0hUU19SRVNFUlZFRCI6IntzaWdufSBTRUVLIHt5ZWFyfSBIYWsgY2lwdGEgZGlsaW5kdW5naSB1bmRhbmctdW5kYW5nIiwiUkVHSVNUUkFUSU9OX05PIjoiTm8uIFJlZ2lzdHJhc2k6IHtyZWdpc3RyYXRpb25OdW1iZXJ9IiwiSEVMUF9DRU5UUkUiOiJQdXNhdCBCYW50dWFuIiwiVEVSTVNfT0ZfVVNFIjoiU3lhcmF0IHBlbmdndW5hYW4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiSHVidW5naSBrYW1pIiwiQ1VTVE9NRVJfU0VSVklDRSI6IkxheWFuYW4gUGVsYW5nZ2FuOiAiLCJMRUFSTl9NT1JFIjoiUGVsYWphcmkgU2VsZW5na2FwbnlhIiwiUFJJVkFDWSI6IlByaXZhc2kiLCJURVJNU19BTkRfQ09ORElUSU9OUyI6IlN5YXJhdCBkYW4gS2V0ZW50dWFuIiwiU0lHTl9TRUVLX1lFQVJfQUxMX1JJR0hUU19SRVNFUlZFRCI6IntzaWdufSBTRUVLIHt5ZWFyfSBIYWsgY2lwdGEgZGlsaW5kdW5naSB1bmRhbmctdW5kYW5nIiwiUkVHSVNUUkFUSU9OX05PIjoiTm8uIFJlZ2lzdHJhc2k6IHtyZWdpc3RyYXRpb25OdW1iZXJ9IiwiSEVMUF9DRU5UUkUiOiJQdXNhdCBCYW50dWFuIiwiVEVSTVNfT0ZfVVNFIjoiU3lhcmF0IHBlbmdndW5hYW4ifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoi4LiV4Li04LiU4LiV4LmI4Lit4LmA4Lij4LiyIiwiQ1VTVE9NRVJfU0VSVklDRSI6IuC4muC4o+C4tOC4geC4suC4o+C4peC4ueC4geC4hOC5ieC4siAiLCJMRUFSTl9NT1JFIjoi4LiC4LmJ4Lit4Lih4Li54Lil4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04LihIiwiUFJJVkFDWSI6IuC4hOC4s+C4iuC4teC5ieC5geC4iOC4h+C4quC4tOC4l+C4mOC4tOC4quC5iOC4p+C4meC4muC4uOC4hOC4hOC4pSIsIlRFUk1TX0FORF9DT05ESVRJT05TIjoi4LiC4LmJ4Lit4LiB4Liz4Lir4LiZ4LiU4LmB4Lil4Liw4LmA4LiH4Li34LmI4Lit4LiZ4LmE4LiCIiwiU0lHTl9TRUVLX1lFQVJfQUxMX1JJR0hUU19SRVNFUlZFRCI6IntzaWdufSBTRUVLIHt5ZWFyfSDguKrguIfguKfguJnguKXguLTguILguKrguLTguJfguJjguLTguYwiLCJSRUdJU1RSQVRJT05fTk8iOiLguYDguKXguILguJfguLDguYDguJrguLXguKLguJnguJnguLTguJXguLTguJrguLjguITguITguKU6IHtyZWdpc3RyYXRpb25OdW1iZXJ9IiwiSEVMUF9DRU5UUkUiOiLguKjguLnguJnguKLguYzguITguKfguLLguKHguIrguYjguKfguKLguYDguKvguKXguLfguK0iLCJURVJNU19PRl9VU0UiOiLguILguYnguK3guJXguIHguKXguIfguYPguJnguIHguLLguKPguYPguIrguYnguIfguLLguJkifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoi4LiV4Li04LiU4LiV4LmI4Lit4LmA4Lij4LiyIiwiQ1VTVE9NRVJfU0VSVklDRSI6IuC4muC4o+C4tOC4geC4suC4o+C4peC4ueC4geC4hOC5ieC4siAiLCJMRUFSTl9NT1JFIjoi4LiC4LmJ4Lit4Lih4Li54Lil4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04LihIiwiUFJJVkFDWSI6IuC4hOC4s+C4iuC4teC5ieC5geC4iOC4h+C4quC4tOC4l+C4mOC4tOC4quC5iOC4p+C4meC4muC4uOC4hOC4hOC4pSIsIlRFUk1TX0FORF9DT05ESVRJT05TIjoi4LiC4LmJ4Lit4LiB4Liz4Lir4LiZ4LiU4LmB4Lil4Liw4LmA4LiH4Li34LmI4Lit4LiZ4LmE4LiCIiwiU0lHTl9TRUVLX1lFQVJfQUxMX1JJR0hUU19SRVNFUlZFRCI6IntzaWdufSBTRUVLIHt5ZWFyfSDguKrguIfguKfguJnguKXguLTguILguKrguLTguJfguJjguLTguYwiLCJSRUdJU1RSQVRJT05fTk8iOiLguYDguKXguILguJfguLDguYDguJrguLXguKLguJnguJnguLTguJXguLTguJrguLjguITguITguKU6IHtyZWdpc3RyYXRpb25OdW1iZXJ9IiwiSEVMUF9DRU5UUkUiOiLguKjguLnguJnguKLguYzguITguKfguLLguKHguIrguYjguKfguKLguYDguKvguKXguLfguK0iLCJURVJNU19PRl9VU0UiOiLguILguYnguK3guJXguIHguKXguIfguYPguJnguIHguLLguKPguYPguIrguYnguIfguLLguJkifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiSHVidW5naSBrYW1pIiwiQ1VTVE9NRVJfU0VSVklDRSI6IkxheWFuYW4gUGVsYW5nZ2FuOiAiLCJMRUFSTl9NT1JFIjoiUGVsYWphcmkgU2VsZW5na2FwbnlhIiwiUFJJVkFDWSI6IlByaXZhc2kiLCJURVJNU19BTkRfQ09ORElUSU9OUyI6IlN5YXJhdCBkYW4gS2V0ZW50dWFuIiwiU0lHTl9TRUVLX1lFQVJfQUxMX1JJR0hUU19SRVNFUlZFRCI6IntzaWdufSBTRUVLIHt5ZWFyfSBIYWsgY2lwdGEgZGlsaW5kdW5naSB1bmRhbmctdW5kYW5nIiwiUkVHSVNUUkFUSU9OX05PIjoiTm8uIFJlZ2lzdHJhc2k6IHtyZWdpc3RyYXRpb25OdW1iZXJ9IiwiSEVMUF9DRU5UUkUiOiJQdXNhdCBCYW50dWFuIiwiVEVSTVNfT0ZfVVNFIjoiU3lhcmF0IHBlbmdndW5hYW4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiSHVidW5naSBrYW1pIiwiQ1VTVE9NRVJfU0VSVklDRSI6IkxheWFuYW4gUGVsYW5nZ2FuOiAiLCJMRUFSTl9NT1JFIjoiUGVsYWphcmkgU2VsZW5na2FwbnlhIiwiUFJJVkFDWSI6IlByaXZhc2kiLCJURVJNU19BTkRfQ09ORElUSU9OUyI6IlN5YXJhdCBkYW4gS2V0ZW50dWFuIiwiU0lHTl9TRUVLX1lFQVJfQUxMX1JJR0hUU19SRVNFUlZFRCI6IntzaWdufSBTRUVLIHt5ZWFyfSBIYWsgY2lwdGEgZGlsaW5kdW5naSB1bmRhbmctdW5kYW5nIiwiUkVHSVNUUkFUSU9OX05PIjoiTm8uIFJlZ2lzdHJhc2k6IHtyZWdpc3RyYXRpb25OdW1iZXJ9IiwiSEVMUF9DRU5UUkUiOiJQdXNhdCBCYW50dWFuIiwiVEVSTVNfT0ZfVVNFIjoiU3lhcmF0IHBlbmdndW5hYW4ifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiQ29udGFjdCB1cyIsIkNVU1RPTUVSX1NFUlZJQ0UiOiJDdXN0b21lciBTZXJ2aWNlOiAiLCJMRUFSTl9NT1JFIjoiTGVhcm4gTW9yZSIsIlBSSVZBQ1kiOiJQcml2YWN5IiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJUZXJtcyBhbmQgQ29uZGl0aW9ucyIsIlNJR05fU0VFS19ZRUFSX0FMTF9SSUdIVFNfUkVTRVJWRUQiOiJ7c2lnbn0gU0VFSyB7eWVhcn0gQWxsIHJpZ2h0cyByZXNlcnZlZCIsIlJFR0lTVFJBVElPTl9OTyI6IlJlZ2lzdHJhdGlvbiBObzoge3JlZ2lzdHJhdGlvbk51bWJlcn0iLCJIRUxQX0NFTlRSRSI6IkhlbHAgQ2VudHJlIiwiVEVSTVNfT0ZfVVNFIjoiVGVybXMgb2YgdXNlIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiW8OHw7bDtsO24LiB4Li14LmJ4bmvxIPEg8SDw6fhua8gx5rHmseaxaFdIiwiQ1VTVE9NRVJfU0VSVklDRSI6IlvDh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaDhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9OiBdIiwiTEVBUk5fTU9SRSI6IlvFgeG6veG6vcSDxIPFmeC4geC4teC5iSDhuYLDtsO2xZnhur3hur1dIiwiUFJJVkFDWSI6IlvGpMWZw6zDrOG5vcSDxIPDp8O9w71dIiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJb4bmu4bq94bq94bq9xZltzILFoSDEg8SDxIPguIHguLXguYnGjCDDh8O2w7bDtuC4geC4teC5icaMw6zDrMOs4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwiU0lHTl9TRUVLX1lFQVJfQUxMX1JJR0hUU19SRVNFUlZFRCI6Ilt7c2lnbn0gxaDDi8OL4biwIHt5ZWFyfSDhuqzGmsaaIMWZw6zDrMOsxKPhuKnhua/FoSDFmeG6veG6veG6vcWh4bq94bq94bq9xZnhub3hur3hur3hur3GjF0iLCJSRUdJU1RSQVRJT05fTk8iOiJbxZjhur3hur3hur3Eo8Osw6zDrMWh4bmvxZnEg8SDxIPhua/DrMOsw6zDtsO2w7bguIHguLXguYkgw5HDtsO2w7Y6IHtyZWdpc3RyYXRpb25OdW1iZXJ9XSIsIkhFTFBfQ0VOVFJFIjoiW+G4qOG6veG6veG6vcaaxqUgw4fhur3hur3hur3guIHguLXguYnhua/FmeG6veG6veG6vV0iLCJURVJNU19PRl9VU0UiOiJb4bmu4bq94bq94bq9xZltzILFoSDDtsO2w7bGkiDHmseax5rFoeG6veG6veG6vV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDT05UQUNUX1VTIjoiW8OHw7bDtsO24LiB4Li14LmJ4bmvxIPEg8SDw6fhua8gx5rHmseaxaFdIiwiQ1VTVE9NRVJfU0VSVklDRSI6IlvDh8eax5rHmsWh4bmvw7bDtsO2bcyC4bq94bq94bq9xZkgxaDhur3hur3hur3FmeG5vcOsw6zDrMOn4bq94bq94bq9OiBdIiwiTEVBUk5fTU9SRSI6IlvFgeG6veG6vcSDxIPFmeC4geC4teC5iSDhuYLDtsO2xZnhur3hur1dIiwiUFJJVkFDWSI6IlvGpMWZw6zDrOG5vcSDxIPDp8O9w71dIiwiVEVSTVNfQU5EX0NPTkRJVElPTlMiOiJb4bmu4bq94bq94bq9xZltzILFoSDEg8SDxIPguIHguLXguYnGjCDDh8O2w7bDtuC4geC4teC5icaMw6zDrMOs4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwiU0lHTl9TRUVLX1lFQVJfQUxMX1JJR0hUU19SRVNFUlZFRCI6Ilt7c2lnbn0gxaDDi8OL4biwIHt5ZWFyfSDhuqzGmsaaIMWZw6zDrMOsxKPhuKnhua/FoSDFmeG6veG6veG6vcWh4bq94bq94bq9xZnhub3hur3hur3hur3GjF0iLCJSRUdJU1RSQVRJT05fTk8iOiJbxZjhur3hur3hur3Eo8Osw6zDrMWh4bmvxZnEg8SDxIPhua/DrMOsw6zDtsO2w7bguIHguLXguYkgw5HDtsO2w7Y6IHtyZWdpc3RyYXRpb25OdW1iZXJ9XSIsIkhFTFBfQ0VOVFJFIjoiW+G4qOG6veG6veG6vcaaxqUgw4fhur3hur3hur3guIHguLXguYnhua/FmeG6veG6veG6vV0iLCJURVJNU19PRl9VU0UiOiJb4bmu4bq94bq94bq9xZltzILFoSDDtsO2w7bGkiDHmseax5rFoeG6veG6veG6vV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    