import { PrivacyTypes, type ProfileV2 } from '../../types';

function showLocationFoundInCv({
  suburb,
  state,
  country,
  profilePrivacy,
}: ProfileV2): boolean {
  return (
    [suburb, state, country].filter(Boolean).join(', ') !== '' &&
    profilePrivacy === PrivacyTypes.Standard
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default showLocationFoundInCv;
