import { useState, useEffect, useRef } from 'react';

import useACL from '../../../../../hooks/useACL';
import useRouter from '../../../../../hooks/useRouter';
import type { RouteDefinition } from '../../../../../types';
import RouteLoader from '../components/RouteLoader';

interface RouteProps {
  restrictions?: RouteDefinition['restrictions'];
}

const DEFAULT_RESTRICTIONS = {
  aclFeatureKey: null,
  featureFlags: [],
};

function withRestrictions<P extends RouteProps>(
  WrappedComponent: React.ComponentType<P>,
) {
  return ({ restrictions, ...restProps }: P) => {
    const ACL = useACL();
    const {
      redirectToStartPage,
      location: { pathname },
    } = useRouter();

    const prevPathname = useRef<string | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { aclFeatureKey } = {
      ...DEFAULT_RESTRICTIONS,
      ...(restrictions || {}),
    };

    useEffect(() => {
      if (pathname !== prevPathname.current) {
        prevPathname.current = pathname;

        const hasAccessToRoute = aclFeatureKey
          ? ACL.hasAccess(aclFeatureKey)
          : true;

        if (hasAccessToRoute) {
          setIsLoading(false);
        } else {
          redirectToStartPage();
        }
      }
    }, [ACL, aclFeatureKey, pathname, redirectToStartPage]);

    if (isLoading) {
      return <RouteLoader />;
    }

    return <WrappedComponent {...(restProps as P)} />;
  };
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default withRestrictions;
