import type { History } from 'history';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Router, matchPath } from 'react-router-dom';

import locationService from '../../services/location';
import { actionCreators as routerActionCreators } from '../../store/router';
import type { RouteDefinition } from '../../types';

export interface Props {
  routes: RouteDefinition[];
  children: React.ReactNode;
  history: History;
}

const RouterProvider: React.FC<Props> = ({ routes, children, history }) => {
  const dispatch = useDispatch();

  const routesRef = useRef<RouteDefinition[]>(routes);
  const historyRef = useRef<History>(history);

  useEffect(() => {
    //  Does exactly what <Switch> does under the hood, but allows us to
    //  have a little more control over how and when fire additional calls as routes change.
    function getMatchingRoute(pathname: string) {
      return routesRef.current
        .reduce(
          (
            acc: Array<{ key: string; params: Record<string, unknown> }>,
            { key, ...rest },
          ) => {
            const match = matchPath(pathname, { ...rest } as any);
            if (match) {
              const { params } = match;
              return [...acc, ...[{ key, params }]];
            }
            return acc;
          },
          [],
        )
        .find((_, index) => index === 0);
    }

    //  Takes the jobId from the path if it exists in the format
    //  /job/:jobId, or takes the ?jobId=123 as a fallback
    function getActiveJobId(params?: { jobId?: number }, search?: string) {
      if (params?.jobId) {
        return Number(params.jobId);
      }
      const { jobId } = locationService.queryStringService.parse(search || '');
      return jobId ? Number(jobId) : null;
    }

    function handleLocationChange({
      pathname,
      search,
    }: {
      pathname: string;
      search: string;
    }) {
      const { key = '', params = {} } = getMatchingRoute(pathname) || {};

      //  Gets the jobId from either the /job/1234 or ?jobId=1234
      const jobId = getActiveJobId(params, search);

      //  Sets the route into redux state, which allows us to access
      //  the current set of params / jobId from anywhere without
      //  having to wrap components withRouter
      dispatch(
        routerActionCreators.updateActiveRoute({
          key,
          pathname,
          search,
          params,
          jobId,
        }),
      );
    }

    const unsubscribe = historyRef.current.listen(handleLocationChange);
    handleLocationChange(historyRef.current.location);

    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  return <Router history={history}>{children}</Router>;
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default RouterProvider;
