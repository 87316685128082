import { ProfileTypes, type CreditRates, CreditRateTypes } from '../../types';

import hasSufficientCredits from './hasSufficientCredits';

interface Params {
  isUsed: boolean;
  credits: number;
  creditRates: CreditRates;
  profileType: ProfileTypes;
}

function canExportProfile({
  isUsed,
  credits,
  creditRates,
  profileType,
}: Params): boolean {
  switch (profileType) {
    case ProfileTypes.Seek:
      return hasSufficientCredits(
        credits,
        creditRates[CreditRateTypes.ExportSeekProfile],
      );

    case ProfileTypes.Combined:
      return isUsed
        ? hasSufficientCredits(
            credits,
            creditRates[CreditRateTypes.RefreshSharedProfile],
          )
        : hasSufficientCredits(
            credits,
            creditRates[CreditRateTypes.ExportSharedProfile],
          );

    default:
      return false;
  }
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default canExportProfile;
