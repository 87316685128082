const createPortalRoot = (containerId: string) => {
  const container = document.createElement('div');
  container.id = containerId;
  document.body.appendChild(container);
  return container;
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default function getPortalRoot(containerId = 'modal-root'): HTMLElement {
  return document.getElementById(containerId) || createPortalRoot(containerId);
}
