import type { TalentSearchHirerAccountTags } from 'src/types/generated';

import {
  type UserContext,
  type User,
  type Contract,
  TalentSearchClaim,
  TalentSearchContractType,
} from '../../../types';

const isPremium = (productType: UserContext['productType']) =>
  Boolean(productType) &&
  productType !== TalentSearchClaim.StandardTalentSearch;

const isConnectedTalentSearchUser = (productType: UserContext['productType']) =>
  Boolean(productType) &&
  productType === TalentSearchClaim.ConnectedTalentSearch;

const getPremiumContract = (
  contracts: UserContext['contracts'],
): Contract | null => {
  const trialContract = contracts.find(
    (contract) =>
      contract.productType ===
      TalentSearchContractType.PremiumTalentSearchTrial,
  );
  if (trialContract) {
    const startDateAsEpochDate =
      new Date(trialContract.startDate).getTime() / 1000;
    const endDateAsEpochDate = new Date(trialContract.endDate).getTime() / 1000;

    return {
      type: 'trial',
      startDate: startDateAsEpochDate,
      endDate: endDateAsEpochDate,
    };
  }

  return null;
};

const getTags = (
  tags: TalentSearchHirerAccountTags,
): User['advertiser']['tags'] => ({
  testRecord: tags.testRecord,
  recordExpiry: tags.recordExpiry ?? undefined,
  testScope: tags.testScope ?? undefined,
  testBehaviours: tags.testBehaviours ?? undefined,
});

/**
 * Ensures compatability between the user context response
 * from GraphQL, and our legacy user details response.
 */
export const mapUserContextToUser = ({
  user: { userId, firstName, lastName, email },
  ats,
  contracts,
  productType,
  advertiser: { id, name, accountNumber, tags },
}: UserContext): User => ({
  user: {
    id: userId,
    firstName,
    lastName: lastName ?? null,
    email,
    isHirer: false,
    isConnectedTalentSearchUser: isConnectedTalentSearchUser(productType),
  },
  advertiser: {
    id,
    name,
    accountNumber,
    companyId: null,
    companyName: '',
    isPremium: isPremium(productType),
    premiumContract: getPremiumContract(contracts),
    ...(tags && { tags: getTags(tags) }),
  },
  ...(ats && {
    ats: {
      atsId: ats.id,
      name: ats.name,
    },
  }),
});
