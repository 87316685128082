import { getSalaryTypeFromStringV2 } from 'src/services/salary/utils';

import {
  type RootState,
  type SalaryRange,
  Search,
  type TalentSearchJobV2,
} from '../../types';
import type { SalaryFrequency } from '../../types/generated';
import { getParamFromSearchSelector } from '../router/selectors';

export const getSalaryRangeForJobSelector = ({
  job,
}: RootState): SalaryRange | null => (job ? job.salaryRange : null);

export const getActiveSalaryTypeSelectorV2 = (
  state: RootState,
): SalaryFrequency => {
  const salaryType = getParamFromSearchSelector(
    state,
    Search.CriteriaKey.SalaryType,
  );

  //  If the salaryType search param has a value,
  //  then we should always use it to override the active job value
  if (salaryType) {
    return getSalaryTypeFromStringV2(salaryType);
  }

  //  The active job salaryType should be used if available
  //  otherwise the salaryType will always be Annual
  let type;
  // v1 job
  if (state?.job?.salaryRange) {
    type = state?.job?.salaryRange?.type;
  }

  // v2 job
  if (state?.job?.salary?.frequency) {
    type = state?.job?.salary?.frequency;
  }
  return getSalaryTypeFromStringV2(type);
};

export const getActiveJobSelector = ({
  job,
}: RootState): TalentSearchJobV2 | null => job;

export const getActiveJobTitleSelector = ({ job }: RootState): string | null =>
  job?.title || null;

export const getActiveJobCountryCodeSelector = ({
  job,
}: RootState): string | null => job?.location?.countryCode || null;
