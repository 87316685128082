import type { ACL } from './index';

export const PRODUCT_TYPE = {
  STS: 'STS',
  PTS: 'PTS',
  CTS: 'CTS',
} as const;

export const SCENE_LINK = {
  TALENT_SEARCH: 'talent_search',
  TALENT_SEARCH_BY_JOB: 'talent_search_by_job',
  TALENT_POOLING: 'talent_pooling',
  CREDITS_DASHBOARD: 'credits',
  CREDITS_COUPLED_BALANCE_ONLY: 'credits_coupled_balance_only',
} as const;

export const SEARCH_FILTER = {
  APPROACHABLE: 'approachable',
  KEYWORDS: 'keywords',
  PROFILE_SOURCE: 'profile_source',
  CV: 'cv',
  COMPANY: 'company',
  INDUSTRY: 'industry',
  RIGHT_TO_WORK: 'right_to_work',
  LAST_UPDATED: 'last_updated',
  WORK_TYPE: 'work_type',
  SALARY: 'salary',
} as const;

export const SCENE_FEATURE = {
  POOLING: 'pooling',
  SEARCH_UNCOUPLED: 'search_uncoupled',
  SIMILAR_CANDIDATES: 'similar_candidates',
  CREDITS_DASHBOARD: 'credits_dashboard',
  CREDITS_COUPLED_BALANCE_ONLY: 'credits_coupled_balance_only',
} as const;

export const CREDITS_FEATURE = {
  USAGE_AND_ACTIVITY: 'usage_and_activity',
  CONNECTION_RATES: 'connection_rates',
  AD_EXPIRY: 'ad_expiry',
  CREDITS_FOR_JOB_ONLY: 'credits_for_job_only',
} as const;

export const SEARCH_FEATURE = {
  GRANULAR_LOCATION: 'granular_location',
  SAVED_SEARCHES: 'saved_searches',
  ADD_TO_POOL: 'add_to_pool',
  SALARY_TYPE_ANNUAL: 'salary_type_annual',
  DATE_AND_RELEVANCE_SORT_OPTION: 'date_and_relevance_sort_option',
} as const;

export const PROFILE_FEATURE = {
  SIMILAR_CANDIDATES_LIST: 'similar_candidates_list',
  INTERACTIONS_CONNECTION: 'interactions_connection',
} as const;

export const CONNECTIONS_FEATURE = {
  ACCESS_CV: 'access_cv',
  ADD_PROFILE: 'add_profile',
  VIEW_PROFILE: 'view_profile',
} as const;

type ValueOf<T> = T[keyof T];

export type ProductType = ValueOf<typeof PRODUCT_TYPE>;
export type SceneLink = ValueOf<typeof SCENE_LINK>;
export type SearchFilter = ValueOf<typeof SEARCH_FILTER>;

export type FeatureKey =
  | ValueOf<typeof SCENE_FEATURE>
  | ValueOf<typeof CREDITS_FEATURE>
  | ValueOf<typeof SEARCH_FEATURE>
  | ValueOf<typeof PROFILE_FEATURE>
  | ValueOf<typeof CONNECTIONS_FEATURE>;

export type ProductFeatureConfig = Record<
  ProductType,
  {
    scene_features: ACL.FeatureKey[];
    credits_features: ACL.FeatureKey[];
    search_features: ACL.FeatureKey[];
    connections_features: ACL.FeatureKey[];
    profile_features: ACL.FeatureKey[];
  }
>;
export type ProductSceneLinkConfig = Record<ProductType, SceneLink[]>;
export type ProductSearchFilterConfig = Record<ProductType, SearchFilter[]>;
