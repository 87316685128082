import { type CreditRates, CreditRateTypes, PrivacyTypes } from '../../types';

import hasSufficientCredits from './hasSufficientCredits';

interface Params {
  isUsed: boolean;
  credits: number;
  creditRates: CreditRates;
  hasResume: boolean;
  profilePrivacy: PrivacyTypes;
}

function canAccessCv({
  isUsed,
  credits,
  creditRates,
  hasResume,
  profilePrivacy,
}: Params): boolean {
  return (
    !isUsed &&
    hasResume &&
    profilePrivacy === PrivacyTypes.Standard &&
    hasSufficientCredits(credits, creditRates[CreditRateTypes.AccessCv])
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default canAccessCv;
