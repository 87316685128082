import { useTranslations } from '@vocab/react';
import {
  Box,
  IconDelete,
  Loader,
  Columns,
  Column,
  Text,
  Button,
  Inline,
} from 'braid-design-system';
import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DateFormat from 'src/common/UI/DateFormat';
import useAppConfig from 'src/hooks/useAppConfig';
import { useRoutes } from 'src/hooks/useRoutes';

import useDataService from '../../../../../../hooks/useDataService';
import * as savedSearchesDataServices from '../../../../../../services/data/savedSearches';
import getSearchParamsFromCriteria from '../../../../../../services/search/getSearchParamsFromCriteria';
import {
  selectors as savedSearchesSelectors,
  actionCreators as savedSearchesActionCreators,
} from '../../../../../../store/savedSearches';
import {
  type SavedSearch as SavedSearchType,
  Search,
} from '../../../../../../types';
import MenuGroup from '../MenuGroup/MenuGroup';

import translations from './.vocab';

import * as styles from './SavedSearchList.css';

interface Props {
  hasScroll: boolean;
  onSelect: () => void;
}

function SavedSearchList({ hasScroll, onSelect }: Props) {
  const { push } = useHistory();
  const { routes } = useRoutes();
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(true);
  const [deletedSearches, setDeletedSearches] = useState<string[]>([]);
  const { LANGUAGE } = useAppConfig();

  const savedSearches = useSelector(
    savedSearchesSelectors.getAllSavedSearchesSelector,
  );

  const { t } = useTranslations(translations);

  const { fetchAllSavedSearches, deleteSavedSearch } = useDataService({
    fetchAllSavedSearches: savedSearchesDataServices.fetchAllSavedSearches,
    deleteSavedSearch: savedSearchesDataServices.deleteSavedSearch,
  });

  useEffect(() => {
    (async () => {
      try {
        dispatch(
          savedSearchesActionCreators.updateSavedSearches(
            await fetchAllSavedSearches({ language: LANGUAGE }),
          ),
        );
      } catch (err) {
        //  Handle error
      } finally {
        setIsFetching(false);
      }
    })();
  }, [fetchAllSavedSearches, dispatch, LANGUAGE]);

  const handleSavedSearchClick = (criteria: SavedSearchType['criteria']) => {
    // FIXME: Ideally routes.searchResultsUncoupled should know about all the search params
    // buuuut there's quite a bit of logic in getSearchParamsFromCriteria
    // so, someone with context of how this works, please fix this.
    push({
      pathname: routes.searchResultsUncoupled(),
      search: `?${getSearchParamsFromCriteria(criteria)}&searchType=${
        Search.SearchType.SavedSearch
      }`,
    });
    onSelect();
  };

  const handleDeleteClick = (criteriaHash: string) => {
    setDeletedSearches(deletedSearches.concat([criteriaHash]));
  };

  const handleCancelClick = (criteriaHash: string) => {
    setDeletedSearches(deletedSearches.filter((hash) => hash !== criteriaHash));
  };

  const handleConfirmClick = async (criteriaHash: string) => {
    await deleteSavedSearch(criteriaHash);
    dispatch(savedSearchesActionCreators.removeSavedSearch(criteriaHash));
  };

  return (
    <MenuGroup>
      <MenuGroup.Title
        text={
          <Fragment>
            {t('SAVED_SEARCHES')}
            <span>
              {' '}
              (
              {t('SAVED_SEARCHES_COUNT_OF_10', {
                savedSearchesCount: savedSearches.length.toString(),
              })}
              )
            </span>
          </Fragment>
        }
      />
      <MenuGroup.Content>
        {isFetching ? (
          <Box padding="gutter">
            <Inline align="center" space="none">
              <Loader />
            </Inline>
          </Box>
        ) : (
          <Box {...(hasScroll && { className: styles.scrollable })}>
            {savedSearches.map(
              ({ criteriaHash, name, createdDate, criteria }) => {
                const isDeleting = deletedSearches.includes(criteriaHash);

                if (isDeleting) {
                  return (
                    <Box
                      data-name="saved-search-item"
                      key={criteriaHash}
                      paddingX="gutter"
                      paddingY="small"
                      background="formAccentSoft"
                    >
                      <Columns alignY="center" space="small">
                        <Column>
                          <Text maxLines={1}>{t('DELETE_NAME', { name })}</Text>
                        </Column>
                        <Column width="content">
                          <Button
                            size="small"
                            onClick={() => handleConfirmClick(criteriaHash)}
                          >
                            {t('YES')}
                          </Button>
                        </Column>
                        <Column width="content">
                          <Button
                            size="small"
                            variant="ghost"
                            onClick={() => handleCancelClick(criteriaHash)}
                          >
                            {t('NO')}
                          </Button>
                        </Column>
                      </Columns>
                    </Box>
                  );
                }

                return (
                  <Box
                    data-name="saved-search-item"
                    key={criteriaHash}
                    paddingX="medium"
                    paddingY="xsmall"
                    cursor="pointer"
                    className={styles.option}
                    onClick={() => handleSavedSearchClick(criteria)}
                  >
                    <Columns alignY="center" space="small">
                      <Column>
                        <Text maxLines={1}>{name}</Text>
                      </Column>
                      <Column width="content">
                        <Text tone="secondary">
                          <DateFormat
                            format="short"
                            date={new Date(createdDate)}
                          />
                        </Text>
                      </Column>
                      <Column width="content">
                        <Box
                          cursor="pointer"
                          component="button"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDeleteClick(criteriaHash);
                          }}
                        >
                          <IconDelete tone="secondary" size="small" />
                        </Box>
                      </Column>
                    </Columns>
                  </Box>
                );
              },
            )}

            {savedSearches.length === 0 && (
              <Box padding="gutter">
                <Text>{t('YOU_HAVE_NO_SAVED_SEARCHES')}</Text>
              </Box>
            )}
          </Box>
        )}
      </MenuGroup.Content>
    </MenuGroup>
  );
}

SavedSearchList.defaultProps = {
  savedSearches: [],
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default SavedSearchList;
