import {
  getActiveRouteKeySelector,
  getParamFromSearchSelector,
} from '../../../store/router/selectors';
import { RouteKeys } from '../../../types';

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default function getFromProfilePageSelector(snapshot: any) {
  const routeKey = getActiveRouteKeySelector(snapshot);
  if (routeKey === RouteKeys.Profile) {
    return true;
  }

  const openProfileId = getParamFromSearchSelector(snapshot, 'openProfileId');
  return Boolean(openProfileId);
}
