import queryStringService from '../../services/location/queryStringService';
import { FilterDelimiter, Search, type CriteriaKey } from '../../types';
import { getSalaryTypeFromStringV2 } from '../salary/utils';

type ValueUnion = string | number | boolean | string[] | number[];
type KeyMap = Record<CriteriaKey, (value: string) => ValueUnion>;

const TO_CRITERIA_MAP: KeyMap = {
  [Search.CriteriaKey.Approachable]: (value) => value === 'true',
  [Search.CriteriaKey.Industry]: (value) => value.split(','),
  [Search.CriteriaKey.Company]: (value) => value.split(FilterDelimiter.Company),
  [Search.CriteriaKey.CV]: (value) => value === 'true',
  [Search.CriteriaKey.Keywords]: (value) => value,
  [Search.CriteriaKey.LastUpdated]: (value) => value,
  [Search.CriteriaKey.LocationList]: (value) => value.split(',').map(Number),
  [Search.CriteriaKey.SalaryMax]: (value) => Number(value),
  [Search.CriteriaKey.SalaryMin]: (value) => Number(value),
  [Search.CriteriaKey.Nation]: (value) => Number(value),
  [Search.CriteriaKey.SalaryNation]: (value) => Number(value),
  [Search.CriteriaKey.SalaryType]: (value) => getSalaryTypeFromStringV2(value),
  [Search.CriteriaKey.SalaryUnspecified]: (value) => value === 'true',
  [Search.CriteriaKey.UncoupledFreeText]: (value) => value,
  // DEPRECATED: will be removed when gRTW is released
  [Search.CriteriaKey.Visa]: (value) => value.split(','),
  [Search.CriteriaKey.WorkType]: (value) => value.split(','),
  [Search.CriteriaKey.WillingToRelocate]: (value) => value === 'true',
  [Search.CriteriaKey.ProfileTypes]: (value) => value.split(','),
  [Search.CriteriaKey.RightToWorkIds]: (value) => value.split(','),
  [Search.CriteriaKey.RightToWorkUnspecified]: (value) => value === 'true',
};

function getCriteriaFromSearchParams<T extends Record<string, unknown>>(
  search: string,
): T {
  const searchParams: Record<string, string> = queryStringService.parse(search);
  const criteria: Record<string, ValueUnion> = {};

  for (const [key, value] of Object.entries(searchParams)) {
    const parser = TO_CRITERIA_MAP[key as keyof KeyMap];
    if (parser) {
      criteria[key] = parser(value);
    }
  }

  return criteria as T;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default getCriteriaFromSearchParams;
