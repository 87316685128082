import { createContext } from 'react';

import type { Options } from './types';

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default createContext<{
  enableScrollbar: (handlerId: string) => (options?: Options) => void;
  disableScrollbar: (handlerId: string) => (options?: Options) => void;
}>({
  enableScrollbar: () => () => {},
  disableScrollbar: () => () => {},
});
