import { Box, Link } from 'braid-design-system';
import { NavLink } from 'react-router-dom';

import useMetrics from '../../../../../../../hooks/useMetrics';
import { globalActions } from '../../../../../../../metrics/actions';
import type { LinkType } from '../PageLinks/PageLinks';

import * as styles from './MobileMenuLink.css';

interface Props {
  href: string | null;
  hasCreditsMetricsEvent?: boolean;
  isExternal?: boolean;
  isLast: boolean;
  onClick?: (event: any) => void | Promise<void>;
  isActive?: (match: any, location: any) => boolean;
  linkType: LinkType;
  children: React.ReactNode;
}

const MobileMenuLink = ({
  href,
  hasCreditsMetricsEvent,
  isExternal,
  onClick,
  isActive,
  linkType,
  children,
}: Props) => {
  const { sendCreditsDashboardEvent } = useMetrics({
    sendCreditsDashboardEvent: globalActions.credits,
  });

  const handleOnClick = () => {
    if (hasCreditsMetricsEvent) {
      sendCreditsDashboardEvent();
    }
  };

  if (href === null) {
    return (
      <Box
        display="flex"
        alignItems="center"
        paddingX={linkType === 'credit' ? undefined : 'medium'}
        style={linkType === 'credit' ? undefined : { minHeight: '64px' }}
        background={linkType === 'credit' ? 'body' : undefined}
      >
        {children}
      </Box>
    );
  }

  // Todo - move this to a separate component, not a child of MenuLink
  if (isExternal) {
    return (
      <Link href={href} onClick={handleOnClick} target="_blank" rel="noopener">
        {children}
      </Link>
    );
  }

  return (
    <Box>
      <Box className={styles.link}>
        <NavLink
          to={href}
          {...(onClick ? { onClick } : null)}
          {...(isActive ? { isActive } : null)}
          className="navlink-menudrawer"
          activeClassName="active-navlink-menudrawer"
        >
          {children}
        </NavLink>
      </Box>
    </Box>
  );
};

MobileMenuLink.defaultProps = {
  isLast: false,
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default MobileMenuLink;
