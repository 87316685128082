import partition from 'lodash/partition';

import type {
  AsyncState,
  LatestInteraction,
  Action,
  UserPoolSelection,
  PoolItem,
} from '../../types';

import {
  PROFILES_RECEIVED,
  UPDATE_ASSIGNED_POOLS_IN_PROFILE,
  UPDATE_ADD_OR_REMOVED_PROFILE_IN_POOLS,
  UPDATE_LATEST_INTERACTION_IN_PROFILE,
  REMOVE_PROFILES,
  UPDATE_ALL_ASYNC_STATE,
  UPDATE_ASYNC_ERROR_STATE,
  CONNECTION_REQUESTED,
  CONNECTION_REDEEMED,
  UNCOUPLED_SEND_JOB_REDEEMED,
  CONNECTION_FAILED,
} from './actions';
import {
  connectionInProfileReducer,
  getInitialEntries,
  getNextEntries,
} from './utils';

type ReducerState = AsyncState;

const initialState: ReducerState = {
  entries: {},
  loading: true,
  error: false,
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default (state: ReducerState = initialState, action: Action) => {
  let profileId;
  let updatedPoolsPayload: UserPoolSelection[];
  let poolItem: PoolItem;

  switch (action.type) {
    case PROFILES_RECEIVED: {
      const { profiles } = action.value;

      return {
        loading: true,
        error: false,
        entries: getInitialEntries(profiles),
      };
    }
    case UPDATE_ASSIGNED_POOLS_IN_PROFILE: {
      ({ profileId, poolItem } = action.value);
      const { assignedPools } = state.entries[profileId];

      return {
        ...state,
        entries: {
          ...state.entries,
          [profileId]: {
            ...state.entries[profileId],
            assignedPools: Boolean(
              assignedPools.find(({ id }) => id === poolItem.id),
            )
              ? assignedPools.filter(({ id }) => id !== poolItem.id)
              : assignedPools.concat([poolItem]),
          },
        },
      };
    }

    case UPDATE_ADD_OR_REMOVED_PROFILE_IN_POOLS: {
      ({ profileId, updatedPoolsPayload } = action.value);
      const [addToAssignedPools, removeFomAssignedPools] = partition(
        updatedPoolsPayload,
        'addToPool',
      );

      // Remove deselected pools and add new selected pools
      const assignedPools = state.entries[profileId].assignedPools
        .filter(
          (assignedPool) =>
            !removeFomAssignedPools.some(
              (pool) => pool.poolId === assignedPool.id,
            ),
        )
        .concat(
          addToAssignedPools.map(({ poolId, name }) => ({ id: poolId, name })),
        );

      return {
        ...state,
        entries: {
          ...state.entries,
          [profileId]: {
            ...state.entries[profileId],
            assignedPools,
          },
        },
      };
    }

    case UPDATE_LATEST_INTERACTION_IN_PROFILE:
      ({ profileId } = action.value);

      const { latestInteraction } = state.entries[profileId];

      const prevLatestInteraction = latestInteraction || {
        profileId,
        count: 0,
      };

      const nextLatestInteraction = {
        ...prevLatestInteraction,
        count: prevLatestInteraction.count + 1,
        interaction: {
          associatedAction: {
            date: new Date().toISOString(),
          },
        } as LatestInteraction['interaction'],
      };

      return {
        ...state,
        entries: {
          ...state.entries,
          [profileId]: {
            ...state.entries[profileId],
            latestInteraction: nextLatestInteraction,
          },
        },
      };

    case UPDATE_ALL_ASYNC_STATE: {
      const nextEntries = getNextEntries({
        currentEntries: state.entries,
        ...action.value,
      });

      return {
        ...state,
        entries: nextEntries,
        loading: false,
      };
    }

    case UPDATE_ASYNC_ERROR_STATE:
      return {
        ...state,
        error: true,
      };

    case REMOVE_PROFILES: {
      return {
        entries: {},
        loading: true,
        error: false,
      };
    }

    case CONNECTION_REQUESTED:
    case CONNECTION_REDEEMED:
    case UNCOUPLED_SEND_JOB_REDEEMED:
    case CONNECTION_FAILED: {
      ({ profileId } = action.value);
      const profile = state.entries[profileId];

      const nextProfileState = connectionInProfileReducer(profile, action);

      return {
        ...state,
        entries: {
          ...state.entries,
          [profileId]: nextProfileState,
        },
      };
    }

    default:
      return state;
  }
};
