import classNames from 'classnames';
import React, { useContext } from 'react';

import Context from '../Context';
import { AnimationState } from '../types';

import * as styles from '../Drawer.css';

interface Props {
  onClose: () => void;
  state: string;
}

function Overlay({ onClose, state }: Props) {
  const {
    theme: { overlayClassName, overlayEnterClassName, overlayExitClassName },
  } = useContext(Context);

  return (
    <div
      role="presentation"
      className={classNames(
        styles.overlay,
        styles[state as keyof typeof styles],

        //  Theme overrides
        overlayClassName,
        {
          // @ts-expect-error
          [overlayEnterClassName]: state === AnimationState.Entered,
          // @ts-expect-error
          [overlayExitClassName]: state === AnimationState.Exiting,
        },
      )}
      onClick={onClose}
    />
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default Overlay;
