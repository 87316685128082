import { Box } from 'braid-design-system';

import { Tags } from '../../../../types';

function AppReadyIdentifier() {
  return <Box data-cy={Tags.Data.AppReadyIdentifier} style={{ height: 1 }} />;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default AppReadyIdentifier;
