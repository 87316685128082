import type { ACL, FeatureFlags } from '../../../types';
import type { Experiment } from '../Experiment';

interface ExperimentPhase {
  desc: string;
  startDate: string;
  endDate: string;
  threshold: number;
  exclude: FeatureFlags[];
  productType: ACL.ProductType[];
}

/**
 * This variable holds a series of experimental phases
 * that you want to run. See the example below
 */
const combined_rollout: ExperimentPhase[] = [
  /**
   * The example below shows that
   * the experiment will start on the 1st Jan 2022 to 4 July 2022
   * with 50% of the users being enabled excluding those account
   * with "HasRecommendedCandidatesOptOut" feature flag enabled.
   *
   * Then from 4 July 2022 onwards, it will become 100% of the users
   * to have this experiment enabled.
   */
  // {
  //   desc: 'Start rollout',
  //   startDate: '2022-01-01T00:00:00+10:00',
  //   endDate: '2022-07-04T00:00:00+10:00',
  //   threshold: 50,
  //   exclude: [FeatureFlags.HasRecommendedCandidatesOptOut],
  //   productType: [ACL.PRODUCT_TYPE.STS],
  // },
  // {
  //   desc: 'End goal',
  //   startDate: '2022-07-04T00:00:00+10:00',
  //   endDate: '9999-12-31T00:00:00+10:00',
  //   threshold: 100,
  //   exclude: [FeatureFlags.HasRecommendedCandidatesOptOut],
  //   productType: [ACL.PRODUCT_TYPE.STS]
  // },
];

const experiments: Experiment[] = [];

function dateBetween(testDate: Date, startDate: Date, endDate: Date): boolean {
  return testDate >= startDate && testDate < endDate;
}
const today = new Date();

const combined_rollout_phase: ExperimentPhase | undefined =
  combined_rollout.find((element) =>
    dateBetween(today, new Date(element.startDate), new Date(element.endDate)),
  );

if (combined_rollout_phase) {
  /**
   * Add the new experiment here.
   */
  // experiments.push(
  //   new Experiment({
  //     active: true,
  //     name: ExperimentName.RECOMMENDED_CANDIDATES,
  //     expires: combined_rollout_phase.endDate,
  //     buckets: [
  //       {
  //         name: BucketName.COUPLED,
  //         test: (value) => value % 100 > combined_rollout_phase.threshold,
  //       },
  //       {
  //         name: BucketName.CQ,
  //         test: (value) => value % 100 <= combined_rollout_phase.threshold,
  //       },
  //     ],
  //     restrictToFeatureFlags: [],
  //     excludeForFeatureFlags: combined_rollout_phase.exclude,
  //     restrictToProductType: combined_rollout_phase.productType,
  //   }),
  // );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default experiments;
