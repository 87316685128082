import {
  Box,
  Text,
  IconChevron,
  IconLanguage,
  IconTick,
  MenuRenderer,
  MenuItem,
  Strong,
} from 'braid-design-system';

import { useAppConfig } from 'src/hooks/useAppConfig';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { useRoutes, getWindowPathWithoutLanguage } from 'src/hooks/useRoutes';
import { LanguageToTextMap } from 'src/types';

const LanguageSwitcher = () => {
  const { LANGUAGE: currentLanguage, SUPPORTED_LANGUAGES } = useAppConfig();
  const { routes } = useRoutes();
  const { isDesktop } = useMediaQuery();

  if (SUPPORTED_LANGUAGES.length <= 1 || !isDesktop) {
    return null;
  }

  return (
    <MenuRenderer
      data={{ cy: 'language-selected' }}
      offsetSpace="small"
      trigger={(triggerProps, { open }) => (
        <Box cursor="pointer" {...triggerProps}>
          <Text size="small">
            <IconLanguage data={{ testid: 'icon-language' }} />{' '}
            {currentLanguage?.toUpperCase()}{' '}
            <IconChevron direction={open ? 'up' : 'down'} alignY="uppercase" />
          </Text>
        </Box>
      )}
    >
      {SUPPORTED_LANGUAGES.map((languageOption) => (
        <MenuItem
          key={languageOption}
          onClick={() =>
            window.location.assign(
              routes.urlResolver({
                language: languageOption,
                path: getWindowPathWithoutLanguage(),
              }),
            )
          }
        >
          {currentLanguage === languageOption ? (
            <>
              <Strong>{LanguageToTextMap[languageOption]}</Strong>{' '}
              <IconTick tone="positive" />
            </>
          ) : (
            LanguageToTextMap[languageOption]
          )}
        </MenuItem>
      ))}
    </MenuRenderer>
  );
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default LanguageSwitcher;
