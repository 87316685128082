import { URL_ANALYTICS_TALENT_SEARCH_USAGE } from '@seek/adv-constants';
import { type Country, createAbsoluteUrl } from '@seek/melways-sites';
import { EMPLOYER_CONTACT_US_URL_PROPS } from '@seek/shared-constants';
import { useMemo } from 'react';

import useAppConfig from '../useAppConfig';

export function useConstantUrls() {
  const { LANGUAGE: language, COUNTRY_CODE: countryCode } = useAppConfig();
  const privacyUrl = useMemo(
    () =>
      createAbsoluteUrl({
        product: 'employer',
        country: countryCode.toLowerCase() as Country,
        language,
        path: '/privacy',
      }),
    [countryCode, language],
  );

  const tsTermsUrl = useMemo(
    () =>
      createAbsoluteUrl({
        product: 'employer',
        country: countryCode.toLowerCase() as Country,
        language,
        path: '/support/talentsearchofferterms',
      }),
    [countryCode, language],
  );

  const contactUsUrl = useMemo(
    () =>
      createAbsoluteUrl({
        ...EMPLOYER_CONTACT_US_URL_PROPS,
        country: countryCode.toLowerCase() as Country,
        language,
      }),
    [countryCode, language],
  );

  const creditDashboardUrl = useMemo(
    () =>
      createAbsoluteUrl({
        product: 'employer',
        country: countryCode.toLowerCase() as Country,
        language,
        path: URL_ANALYTICS_TALENT_SEARCH_USAGE,
      }),
    [countryCode, language],
  );

  return { contactUsUrl, privacyUrl, tsTermsUrl, creditDashboardUrl };
}
