import type { SavedSearch } from '../../types';

import * as actions from './actions';

const mapSavedSearch = (savedSearch: SavedSearch): SavedSearch => ({
  ...savedSearch,
  criteria: {
    ...savedSearch.criteria,
    rightToWorkIds: savedSearch.criteria.rightToWorkList?.map((rtw) => rtw.id),
  },
});

export const addSavedSearch = (savedSearch: SavedSearch) => ({
  type: actions.ADD_SAVED_SEARCH,
  value: { savedSearch: mapSavedSearch(savedSearch) },
});

export const updateSavedSearches = (savedSearches?: SavedSearch[]) => ({
  type: actions.RECEIVE_SAVED_SEARCHES_DATA,
  value: { savedSearches: savedSearches?.map(mapSavedSearch) },
});

export const removeSavedSearch = (criteriaHash: string) => ({
  type: actions.REMOVE_SAVED_SEARCH,
  value: { criteriaHash },
});
