import { gql } from '@apollo/client';
import axios from 'axios';
import { print } from 'graphql';

import type { GraphResponse, TalentSearchJobsV2 } from '../../types';

export const GET_FIRST_ACTIVE_JOB_ID = gql`
  query GetFirstActiveTalentSearchJob {
    talentSearchJobsV2(input: { maxJobs: 1 }) {
      jobs {
        id
      }
    }
  }
`;

async function getFirstActiveJobId({
  hirerGraphUrl,
  advertiserId,
  token,
}: {
  hirerGraphUrl: string;
  advertiserId: number;
  token: string;
}) {
  const { data: jobsResponse } = await axios.post<
    GraphResponse<{
      talentSearchJobsV2: TalentSearchJobsV2;
    }>
  >(
    hirerGraphUrl,
    {
      query: print(GET_FIRST_ACTIVE_JOB_ID),
    },
    {
      headers: {
        'x-seek-site': 'talent-search-ui',
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
        advertiserId: `${advertiserId}`,
      },
    },
  );
  const [firstActiveJob] = jobsResponse.data?.talentSearchJobsV2?.jobs || [];
  return firstActiveJob?.id || null;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default getFirstActiveJobId;
