import { PrivacyTypes, type ProfileV2 } from '../../types';

function hasLocationFoundInCVOnSummary({
  profilePrivacy,
  suburb,
  state,
  country,
}: ProfileV2): boolean {
  if (profilePrivacy !== PrivacyTypes.Standard) {
    return false;
  }

  const hasSuburb = typeof suburb === 'string' && suburb.length > 0;
  const hasState = typeof state === 'string' && state.length > 0;
  const hasCountry = typeof country === 'string' && country.length > 0;

  const hasLocationFoundInCv = hasSuburb || hasState || hasCountry;

  if (!hasLocationFoundInCv) {
    return false;
  }

  return hasSuburb || hasState;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default hasLocationFoundInCVOnSummary;
