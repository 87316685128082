import type { Locale } from '@seek/melways-sites';

import { GET_METADATA_INDUSTRIES } from '../../../queries/TalentSearchMetadata';
import type { MetadataIndustry, Services } from '../../../types';

export interface GetIndustriesParam {
  locale: Locale;
}

const dataService: Services.DataService<
  MetadataIndustry[],
  GetIndustriesParam
> = ({ query }) =>
  async function getIndustries({ locale }) {
    const { talentSearchMetadataIndustries } = await query<{
      talentSearchMetadataIndustries: MetadataIndustry[];
    }>({
      query: GET_METADATA_INDUSTRIES,
      variables: {
        input: { locale },
      },
    });
    return talentSearchMetadataIndustries;
  };

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default dataService;
