import type {
  Action,
  MetadataIndustry,
  MetadataQueryValidations,
} from '../../types';

import * as actions from './actions';

interface State {
  industries: MetadataIndustry[] | null;
  validations: MetadataQueryValidations | null;
}

const initialState: State = {
  industries: null,
  validations: null,
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actions.UPDATE_INDUSTRIES_DATA:
      const { industries } = action.value;
      return { ...state, industries };

    case actions.UPDATE_UNCOUPLED_QUERY_VALIDATIONS_DATA:
      const { validations } = action.value;
      return {
        ...state,
        validations,
      };

    default:
      return state;
  }
};
