import type { ProfileV2, SimilarCandidateExtract } from '../../types';

function getSimilarCandidatesDataFromProfile(
  seedProfile: ProfileV2,
): SimilarCandidateExtract {
  return {
    currentJobTitle: seedProfile.currentJobTitle,
    firstName: seedProfile.firstName,
    lastName: seedProfile.lastName,
  };
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default getSimilarCandidatesDataFromProfile;
