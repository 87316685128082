import type { ErrorResponse } from '@apollo/client/link/error';
import type { AxiosError } from 'axios';

import {
  APIErrorCode,
  type APIErrorResponse,
  type AxiosOrGraphQLError,
  GraphQLErrorCode,
  OpaqueErrorId,
} from '../../types';

function isAxiosError(error: AxiosOrGraphQLError): error is AxiosError {
  return (error as AxiosError).response !== undefined;
}

function isGraphQLError(error: AxiosOrGraphQLError): error is ErrorResponse {
  return (
    (error as ErrorResponse).graphQLErrors !== undefined ||
    (error as ErrorResponse).networkError !== undefined
  );
}

function hasGraphQLErrorCode(
  { graphQLErrors, networkError }: ErrorResponse,
  code: GraphQLErrorCode,
  status: number,
) {
  const errorInResolver = graphQLErrors?.some(
    (error) => error?.extensions?.code === code,
  );
  const errorInNetwork =
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === status;
  return Boolean(errorInResolver || errorInNetwork);
}

function isAxiosOrGraphQLError(
  error: AxiosOrGraphQLError | Error,
): error is AxiosOrGraphQLError {
  return (
    isAxiosError(error as AxiosOrGraphQLError) ||
    isGraphQLError(error as AxiosOrGraphQLError)
  );
}

function isAPIErrorResponse(body: any): body is APIErrorResponse {
  return (
    body?.message !== undefined ||
    body?.errorId !== undefined ||
    body?.requestId !== undefined
  );
}

function mapCodeToStatus(code: GraphQLErrorCode): number | null {
  switch (code) {
    case GraphQLErrorCode.UNAUTHENTICATED:
      return 401;
    case GraphQLErrorCode.INTERNAL_SERVER_ERROR:
      return 500;
    case GraphQLErrorCode.FORBIDDEN:
      return 403;
    default:
      return null;
  }
}

function extractResolverErrorOrNetworkError({
  graphQLErrors,
  networkError,
}: ErrorResponse): {
  errorId: OpaqueErrorId | null;
  message: string | null;
} {
  let errorMessage: string | null;
  if (graphQLErrors?.length) {
    const [{ extensions, message } = { extensions: null, message: null }] =
      graphQLErrors;
    const body = extensions?.response?.body;
    if (isAPIErrorResponse(body)) {
      return {
        errorId: body.errorId || null,
        message: body.message || null,
      };
    }
    errorMessage = body || message;
  } else {
    errorMessage = networkError?.message || null;
  }
  return {
    errorId: null,
    message: errorMessage,
  };
}

/**
 * Attempts to extract a business API Error code such as "AdvertiserIdMismatch" from the full list of known error codes.
 * @param {AxiosOrGraphQLError} error
 */
function getAPIErrorCodeFromError(error: AxiosOrGraphQLError): {
  apiErrorCode: APIErrorCode | null;
  opaqueErrorId: OpaqueErrorId | null;
} {
  let messageFromError: string | null = null;
  let opaqueErrorId: OpaqueErrorId | null = null;

  if (isAxiosError(error)) {
    messageFromError = error.message || null;
    if (
      Object.values(OpaqueErrorId).includes(messageFromError as OpaqueErrorId)
    ) {
      opaqueErrorId = messageFromError as OpaqueErrorId;
    }
  } else if (isGraphQLError(error)) {
    const { errorId, message } = extractResolverErrorOrNetworkError(error);
    messageFromError = message;
    opaqueErrorId = errorId;
  }

  if (!messageFromError) {
    return {
      apiErrorCode: null,
      opaqueErrorId,
    };
  }

  let apiErrorCode: APIErrorCode | null = null;

  //  Finds a matching business error (defined as ErrorType)
  //  in the error message.
  for (const entry of Object.values(APIErrorCode)) {
    const [match] =
      messageFromError.match(new RegExp(`\\b${entry}\\b`, 'g')) || [];

    if (match) {
      apiErrorCode = match as APIErrorCode;
      break;
    }
  }

  return { apiErrorCode, opaqueErrorId };
}

/**
 * Extracts any or all information from the provided error
 * @param {AxiosOrGraphQLError} error
 */
function getDetailsFromError(error: AxiosOrGraphQLError): {
  status?: number;
  message?: string;
  apiErrorCode?: APIErrorCode;
  opaqueErrorId?: OpaqueErrorId;
} | null {
  const { apiErrorCode, opaqueErrorId } = getAPIErrorCodeFromError(error);

  if (isAxiosError(error)) {
    const status = error.response?.status || null;
    const message = error.message || null;

    return {
      ...(status && { status }),
      ...(message && { message }),
      ...(apiErrorCode && { apiErrorCode }),
      ...(opaqueErrorId && { opaqueErrorId }),
    };
  } else if (isGraphQLError(error)) {
    const { graphQLErrors, networkError } = error;
    const { message } = extractResolverErrorOrNetworkError(error);

    if (graphQLErrors?.length) {
      const [firstError] = graphQLErrors || [];
      const code = firstError.extensions?.code || null;
      const status = mapCodeToStatus(code);

      return {
        ...(status && { status }),
        ...(message && { message }),
        ...(apiErrorCode && { apiErrorCode }),
        ...(opaqueErrorId && { opaqueErrorId }),
      };
    } else if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode
    ) {
      const status = networkError.statusCode || null;
      const networkErrorMessage = networkError.message || null;

      return {
        ...(status && { status }),
        ...(networkErrorMessage && { message: networkErrorMessage }),
        ...(apiErrorCode && { apiErrorCode }),
        ...(opaqueErrorId && { opaqueErrorId }),
      };
    }
  }

  return null;
}

function isUnauthorisedError(error: AxiosOrGraphQLError) {
  if (isAxiosError(error)) {
    return error.response?.status === 401;
  } else if (isGraphQLError(error)) {
    return hasGraphQLErrorCode(error, GraphQLErrorCode.UNAUTHENTICATED, 401);
  }
  return false;
}

function isForbiddenError(error: AxiosOrGraphQLError) {
  if (isAxiosError(error)) {
    return error.response?.status === 403;
  } else if (isGraphQLError(error)) {
    return hasGraphQLErrorCode(error, GraphQLErrorCode.FORBIDDEN, 403);
  }
  return false;
}

/**
 * Errors that should redirect the user to the error page
 */
const UNRECOVERABLE_API_ERRORS = [
  APIErrorCode.FORBIDDEN_CREDIT_HOLD,
  APIErrorCode.FORBIDDEN_JOB_EXPIRED,
  APIErrorCode.FORBIDDEN_JOB_INACTIVE,
  APIErrorCode.FORBIDDEN_USER_REQUIRES_PREMIUM_TALENT_SEARCH,
  APIErrorCode.FORBIDDEN_ADVERTISER_NOT_ACTIVE,
  APIErrorCode.FORBIDDEN_ADVERTISER_NOT_APPROVED,
  APIErrorCode.FORBIDDEN_ADVERTISER_NOT_APPROVED_FOR_JSP_ACCESS,
  APIErrorCode.FORBIDDEN_CONTRACT_EXPIRED,
  APIErrorCode.FORBIDDEN_TRIAL_EXPIRED,
  APIErrorCode.FORBIDDEN_JOB_NOT_YET_PROCESSED_BY_SAP,
  APIErrorCode.FORBIDDEN_ADVERTISER_NOT_ACTIVE_OR_WITH_NO_CREDIT_BALANCE,
  APIErrorCode.FORBIDDEN_ADVERTISER_NOT_ACTIVE_OR_WITH_INVALID_BUDGET,
  APIErrorCode.FORBIDDEN_WITH_INVALID_JOB_OR_WITH_INVALID_BUDGET,
  APIErrorCode.FORBIDDEN_NO_PREMIUM_OR_CONNECTED_TALENT_SEARCH,
  APIErrorCode.FORBIDDEN_AUTHORISATION_ERROR,
  APIErrorCode.INVALID_AUTH_CONFIGURATION,
  APIErrorCode.FORBIDDEN_MISSING_AUTH_CONTEXT_OR_USER,
  APIErrorCode.FORBIDDEN_INVALID_ACCESS_TO_RESOURCE,
];

const UNRECOVERABLE_OPAQUE_ERROR_IDS = [
  OpaqueErrorId.FORBIDDEN_USER_NOT_FOUND,
  OpaqueErrorId.FORBIDDEN_NO_AVAILABLE_ACCOUNT,
  OpaqueErrorId.FORBIDDEN_NO_VIEW_ADVERTISER,
  OpaqueErrorId.FORBIDDEN_PRODUCT_TYPE_NOT_MATCH,
  OpaqueErrorId.FORBIDDEN_NO_AVAILABLE_JOB_FOR_STS,
  OpaqueErrorId.FORBIDDEN_CLAIM_NOT_AUTHORISED,
  OpaqueErrorId.FORBIDDEN_ADVERTISER_NOT_APPROVED,
];

function isUnrecoverableError(error: AxiosOrGraphQLError) {
  const { apiErrorCode, opaqueErrorId } = getAPIErrorCodeFromError(error);
  return (
    Boolean(apiErrorCode && UNRECOVERABLE_API_ERRORS.includes(apiErrorCode)) ||
    Boolean(
      opaqueErrorId && UNRECOVERABLE_OPAQUE_ERROR_IDS.includes(opaqueErrorId),
    )
  );
}

export {
  isAxiosError,
  isGraphQLError,
  getAPIErrorCodeFromError,
  getDetailsFromError,
  isAxiosOrGraphQLError,
  isUnauthorisedError,
  isUnrecoverableError,
  isForbiddenError,
};
