import { TEALIUM_KEY } from '../tealium';
import {
  SimilarCandidates as SimilarCandidatesLinks,
  UtagActions,
} from '../tealium/types';

const actions = {
  cardClick: ({ position }: { position: number }) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = SimilarCandidatesLinks.CardClick;
        return {
          action: UtagActions.Link,
          value: {
            eventName,
            similarCandidatePosition: position,
          },
        };
      },
    },
  ],
  seeAllClick: () => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = SimilarCandidatesLinks.SeeAllClick;
        return {
          action: UtagActions.Link,
          value: {
            eventName,
          },
        };
      },
    },
  ],
  toggleLocation: (isEnabled: boolean) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = isEnabled
          ? SimilarCandidatesLinks.LocationToggleOn
          : SimilarCandidatesLinks.LocationToggleOff;
        return {
          action: UtagActions.Link,
          value: {
            eventName,
          },
        };
      },
    },
  ],
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default actions;
