import { Loader, useColor } from 'braid-design-system';
import classNames from 'classnames';
import { transparentize } from 'polished';

import * as styles from './LoaderOverlay.css';

interface Props {
  hasBackground?: boolean;
  isInline?: boolean;
}

function LoaderOverlay({ hasBackground, isInline }: Props) {
  const backgroundColor = useColor().background.neutralLight;

  return (
    <div
      className={classNames(styles.container, {
        [styles.isInline]: isInline,
      })}
      style={{
        ...(hasBackground && {
          background: transparentize(0.65, backgroundColor),
        }),
      }}
    >
      <div className={styles.loader}>
        <Loader />
      </div>
    </div>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default LoaderOverlay;
