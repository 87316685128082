import { CREATE_SAVED_SEARCH } from '../../../queries/TalentSearchSavedSearch';
import type { SavedSearch, Services } from '../../../types';

interface Params {
  name: string;
  criteria: SavedSearch['criteria'];
}

const dataService: Services.DataService<SavedSearch, Params> = ({ mutate }) =>
  async function createSavedSearch({ name, criteria }) {
    const response = await mutate<{
      createSavedSearch: {
        savedSearch: SavedSearch;
      };
    }>({
      mutation: CREATE_SAVED_SEARCH,
      variables: {
        input: {
          name,
          criteria,
          createdDate: new Date().toJSON(),
        },
      },
    });

    return response.createSavedSearch.savedSearch;
  };

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default dataService;
