import profileService from '../../../services/profile';
import { CVPreviewStates, type ProfileV2 } from '../../../types';

const MAP_CV_STATUS_TO_PAGE = {
  [CVPreviewStates.CvPreview]: 'cv+preview',
  [CVPreviewStates.NoCv]: 'no+cv',
  [CVPreviewStates.CvNotParsed]: 'cv+not+parsed',
  [CVPreviewStates.CvPrivate]: 'private+cv',
} as const;

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default function (profile: ProfileV2): string {
  const status = profileService.getCvPreviewStatus(profile);
  return MAP_CV_STATUS_TO_PAGE[status];
}
