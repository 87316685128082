import { GET_METADATA_QUERY_VALIDATIONS } from '../../../queries/TalentSearchMetadata';
import type { Services, MetadataQueryValidations } from '../../../types';

type Response = MetadataQueryValidations;

const dataService: Services.DataService<Response> = ({ query }) =>
  async function getUncoupledQueryValidations() {
    const data = await query<{
      talentSearchMetadataQueryValidations: Response;
    }>({
      query: GET_METADATA_QUERY_VALIDATIONS,
    });

    return data?.talentSearchMetadataQueryValidations || {};
  };

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default dataService;
