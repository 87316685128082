import { useTranslations } from '@vocab/react';
import {
  Card,
  Heading,
  Stack,
  Text,
  Box,
  Inline,
  IconCaution,
  List,
  Button,
  Strong,
} from 'braid-design-system';

import type { ContextType } from '../../../hooks/useAuth';
import errorService from '../../../services/error';
import { type APIErrorCode, ErrorTypes } from '../../../types';

import translations from './.vocab';
import ErrorMessageText from './components/ErrorMessageText';
import mapContextToFriendlyMessage from './mapContextToFriendlyMessage';
import mapErrorTypeToMessage from './mapErrorTypeToMessage';

import * as styles from './ErrorMessage.css';

interface Props {
  errorType: ErrorTypes;
  showErrorContext: boolean;
  context: {
    apiErrorCode?: APIErrorCode;
    opaqueErrorId?: string;
    jobId?: number;
    advertiserId?: number;
  };
  actions?: {
    logout: ContextType['logout'];
  };
  isFixedCenter: boolean;
}

function ErrorMessage({
  errorType,
  showErrorContext,
  context,
  actions,
  isFixedCenter,
}: Props) {
  const { t } = useTranslations(translations);

  const { apiErrorCode, opaqueErrorId } = context;

  const code = opaqueErrorId
    ? opaqueErrorId
    : errorService.mapAPIErrorCodeToFriendlyCode(apiErrorCode);

  const { image, title, message, showLogoutAction, errorCode } =
    mapErrorTypeToMessage(errorType, {
      ...(apiErrorCode && { apiErrorCode }),
      code,
    });

  return (
    <Box className={isFixedCenter ? styles.container : ''}>
      <Inline space="none" align="center">
        <Box maxWidth="small">
          <Card rounded>
            <Box padding="large">
              <Stack space="large">
                <Stack space="large" align="center">
                  <Stack space="medium" align="center">
                    <Box dangerouslySetInnerHTML={{ __html: image }} />
                    <ErrorMessageText
                      titleKey={title}
                      translationKey={message}
                      errorCode={errorCode}
                    />
                  </Stack>

                  {actions && showLogoutAction && (
                    <Inline space="none" align="center">
                      <Button
                        id="sign-out-button"
                        variant="ghost"
                        onClick={() => {
                          actions.logout();
                        }}
                      >
                        {t('SIGN_OUT')}
                      </Button>
                    </Inline>
                  )}
                </Stack>

                {showErrorContext && (
                  <Box
                    id="error-context"
                    background="cautionLight"
                    padding="medium"
                    boxShadow="borderCaution"
                    data={{ testid: 'error-context' }}
                  >
                    <Stack space="large">
                      <Inline alignY="center" space="small">
                        <Heading level="2">
                          <IconCaution tone="caution" />
                        </Heading>
                        <Text tone="caution" weight="strong">
                          {t('ADDITIONAL_ERROR_CONTEXT')}
                        </Text>
                      </Inline>

                      <List space="medium">
                        {Object.entries(context).map(([key, value]) => (
                          <Text key={key}>
                            {t(`${mapContextToFriendlyMessage(key)}`, {
                              key,
                              Strong: (children) => <Strong>{children}</Strong>,
                              value: String(value),
                            })}
                          </Text>
                        ))}
                      </List>

                      <Text size="small">{t('IMPERSONATING_DEBUG_INFO')}</Text>
                    </Stack>
                  </Box>
                )}
              </Stack>
            </Box>
          </Card>
        </Box>
      </Inline>
    </Box>
  );
}

ErrorMessage.defaultProps = {
  errorType: ErrorTypes.UNKNOWN,
  showErrorContext: false,
  context: {},
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default ErrorMessage;
