import { ACL, type FeatureFlags } from '../../types';

const {
  PRODUCT_TYPE,
  SCENE_FEATURE,
  CREDITS_FEATURE,
  CONNECTIONS_FEATURE,
  SEARCH_FEATURE,
  PROFILE_FEATURE,
  SCENE_LINK,
  SEARCH_FILTER,
} = ACL;

/**
 * Product scene link config
 *
 * @remarks
 * The configuration of available menu links by productType
 */
export const productSceneLinkConfig: ACL.ProductSceneLinkConfig = {
  [PRODUCT_TYPE.STS]: [
    SCENE_LINK.TALENT_SEARCH_BY_JOB,
    SCENE_LINK.CREDITS_COUPLED_BALANCE_ONLY,
  ],
  [PRODUCT_TYPE.PTS]: [
    SCENE_LINK.TALENT_SEARCH,
    SCENE_LINK.TALENT_SEARCH_BY_JOB,
    SCENE_LINK.TALENT_POOLING,
    SCENE_LINK.CREDITS_DASHBOARD,
  ],
  [PRODUCT_TYPE.CTS]: [
    SCENE_LINK.TALENT_SEARCH,
    SCENE_LINK.TALENT_SEARCH_BY_JOB,
    SCENE_LINK.TALENT_POOLING,
    SCENE_LINK.CREDITS_DASHBOARD,
  ],
};

/**
 * Product search filter config
 *
 * @remarks
 * The configuration of available search filters by productType.
 */
export const productSearchFilterConfig: ACL.ProductSearchFilterConfig = {
  [PRODUCT_TYPE.STS]: [
    SEARCH_FILTER.KEYWORDS,
    SEARCH_FILTER.INDUSTRY,
    SEARCH_FILTER.RIGHT_TO_WORK,
    SEARCH_FILTER.LAST_UPDATED,
    SEARCH_FILTER.WORK_TYPE,
    SEARCH_FILTER.SALARY,
    SEARCH_FILTER.CV,
  ],
  [PRODUCT_TYPE.PTS]: [
    SEARCH_FILTER.KEYWORDS,
    SEARCH_FILTER.INDUSTRY,
    SEARCH_FILTER.COMPANY,
    SEARCH_FILTER.APPROACHABLE,
    SEARCH_FILTER.LAST_UPDATED,
    SEARCH_FILTER.SALARY,
    SEARCH_FILTER.WORK_TYPE,
    SEARCH_FILTER.RIGHT_TO_WORK,
    SEARCH_FILTER.CV,
  ],
  [PRODUCT_TYPE.CTS]: [
    SEARCH_FILTER.KEYWORDS,
    SEARCH_FILTER.PROFILE_SOURCE,
    SEARCH_FILTER.INDUSTRY,
    SEARCH_FILTER.COMPANY,
    SEARCH_FILTER.APPROACHABLE,
    SEARCH_FILTER.LAST_UPDATED,
    SEARCH_FILTER.SALARY,
    SEARCH_FILTER.WORK_TYPE,
    SEARCH_FILTER.RIGHT_TO_WORK,
    SEARCH_FILTER.CV,
  ],
};

/**
 * Product feature config
 *
 * @remarks
 * The configuration of available features by productType. Not all
 * UI features exist in this matrix, only the ones that require specific access.
 * This list is flattened and consumed as a single array of features for each productType.
 */
// prettier-ignore
export const productFeatureConfig: ACL.ProductFeatureConfig = {
  [PRODUCT_TYPE.STS]: {
    scene_features: [],
    credits_features: [
      CREDITS_FEATURE.CREDITS_FOR_JOB_ONLY,
      CREDITS_FEATURE.CONNECTION_RATES,
      CREDITS_FEATURE.AD_EXPIRY
    ],
    search_features: [],
    connections_features: [
      CONNECTIONS_FEATURE.ACCESS_CV
    ],
    profile_features: [],
  },
  [PRODUCT_TYPE.PTS]: {
    scene_features: [
      SCENE_FEATURE.POOLING,
      SCENE_FEATURE.SEARCH_UNCOUPLED,
      SCENE_FEATURE.SIMILAR_CANDIDATES,
      SCENE_FEATURE.CREDITS_DASHBOARD,
    ],
    credits_features: [
      CREDITS_FEATURE.CONNECTION_RATES,
      CREDITS_FEATURE.USAGE_AND_ACTIVITY,
    ],
    search_features: [
      SEARCH_FEATURE.GRANULAR_LOCATION,
      SEARCH_FEATURE.SAVED_SEARCHES,
      SEARCH_FEATURE.ADD_TO_POOL,
      SEARCH_FEATURE.SALARY_TYPE_ANNUAL,
      SEARCH_FEATURE.DATE_AND_RELEVANCE_SORT_OPTION,
    ],
    connections_features: [
      CONNECTIONS_FEATURE.ACCESS_CV
    ],
    profile_features: [
      PROFILE_FEATURE.SIMILAR_CANDIDATES_LIST
    ],
  },
  [PRODUCT_TYPE.CTS]: {
    scene_features: [
      SCENE_FEATURE.POOLING,
      SCENE_FEATURE.SEARCH_UNCOUPLED,
      SCENE_FEATURE.SIMILAR_CANDIDATES,
      SCENE_FEATURE.CREDITS_DASHBOARD,
    ],
    credits_features: [
      CREDITS_FEATURE.CONNECTION_RATES
    ],
    search_features: [
      SEARCH_FEATURE.GRANULAR_LOCATION,
      SEARCH_FEATURE.SAVED_SEARCHES,
      SEARCH_FEATURE.ADD_TO_POOL,
      SEARCH_FEATURE.SALARY_TYPE_ANNUAL,
      SEARCH_FEATURE.DATE_AND_RELEVANCE_SORT_OPTION,
    ],
    connections_features: [
      CONNECTIONS_FEATURE.ADD_PROFILE,
      CONNECTIONS_FEATURE.VIEW_PROFILE,
    ],
    profile_features: [
      PROFILE_FEATURE.SIMILAR_CANDIDATES_LIST,
      PROFILE_FEATURE.INTERACTIONS_CONNECTION,
    ],
  },
};

// Add to the array to always enable the feature flag
// example `export const enabledFeatureFlags = [FeatureFlags.HasUnifiedRightToWorkEnabled];` if you want to enable FeatureFlags.HasUnifiedRightToWorkEnabled to all users
export const enabledFeatureFlags: FeatureFlags[] = [];
