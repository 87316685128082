import qs, { parse, stringify } from 'query-string';

import {
  type SalaryFrequency,
  TalentSearchQuerySortBy,
} from '../../types/generated';
import { getSalaryTypeFromStringV2 } from '../salary/utils';

/* Use search params hook */
export type DefaultQuerySchema = Record<
  string,
  {
    type: any;
    defaultValue?: any;
  }
>;

export type QuerySchema<T extends DefaultQuerySchema> = {
  [K in keyof T]: undefined extends T[K]['defaultValue']
    ? ReturnType<T[K]['type']> | undefined
    : ReturnType<T[K]['type']> | T[K]['defaultValue'];
};

type Params = Record<string, unknown>;

export const enumParsers = {
  salaryType: (value: string): SalaryFrequency =>
    getSalaryTypeFromStringV2(value),
  sortBy: (value: string) => {
    const mappedValue = Object.entries(TalentSearchQuerySortBy).reduce<
      TalentSearchQuerySortBy | undefined
    >(
      (acc, [key, sortBy]) =>
        value.toLowerCase() === key.toLowerCase() ? sortBy : acc,
      undefined,
    );
    return mappedValue
      ? (mappedValue as TalentSearchQuerySortBy)
      : TalentSearchQuerySortBy.Relevance;
  },
};

export const parsers = {
  boolean: (value: string): boolean => value === 'true',
  string: (value: string): string => String(value),
  number: (value: string): number => Number(value),
  array: <T>(
    value: string,
    delimiter: string,
    parser: (value: string) => T,
  ): T[] =>
    Array.isArray(value)
      ? value
      : value.split(delimiter).map((item) => parser(item)),
};

// TODO: Unfortunately this is a necessary evil for now until we migrate all search related calls to hirer graph.
const hasValue = (value: unknown): boolean =>
  value !== 'undefined' &&
  value !== undefined &&
  value !== 'null' &&
  value !== null;

function parseWithSchema<T extends DefaultQuerySchema>(
  params: Params,
  schema: T,
): QuerySchema<T> {
  const parsedParams: any = {};

  for (const key of Object.keys(schema)) {
    const { type, defaultValue } = schema[key];
    if (!(key in params)) {
      parsedParams[key] = defaultValue;
    } else {
      parsedParams[key] = hasValue(params[key])
        ? type(params[key] as string)
        : defaultValue ?? undefined;
    }
  }

  return parsedParams;
}

/**
 * Extracts parameters from provided search string
 * @param {string} search - Search string input
 * @param {object} schema - Parses output using a provided schema
 */
function parseParams<T extends DefaultQuerySchema>(
  search: string,
  schema: T,
): QuerySchema<T> {
  return parseWithSchema(qs.parse(search), schema);
}

/**
 * Extracts parameters from provided search string
 * @param {string} search - Search string input
 * @param {string[]} keys - List of keys required to omit
 */
function extractParams(
  search: string,
  keys: string[],
): [Record<string, string>, string];
/**
 * Extracts parameters from provided search string and parses output with schema
 * @param {string} search - Search string input
 * @param {string[]} keys - List of keys required to omit
 * @param [schema] - Parses output using a provided schema
 */
function extractParams<T extends DefaultQuerySchema>(
  search: string,
  keys: string[],
  schema: T,
): [QuerySchema<T>, string];
function extractParams<T extends DefaultQuerySchema>(
  search: string,
  keys: string[],
  schema?: T,
): [Record<string, string>, string] | [QuerySchema<T>, string] {
  const extracted: any = {};
  const remaining: any = {};

  for (const [key, value] of Object.entries(qs.parse(search))) {
    if (keys.includes(key)) {
      extracted[key] = value;
    } else {
      remaining[key] = value;
    }
  }

  return [
    schema ? parseWithSchema(extracted, schema) : extracted,
    stringify(remaining),
  ];
}

/**
 * Omits parameters from provided search string
 * @param {string} search - Search string input
 * @param {string[]} keys - List of keys required to omit
 */
function omitParams(
  search: string,
  keys: string[],
): [Record<string, string>, string];
/**
 * Omits parameters from provided search string and parses output with schema
 * @param {string} search - Search string input
 * @param {string[]} keys - List of keys required to omit
 * @param [schema] - Parses output using a provided schema
 */
function omitParams<T extends DefaultQuerySchema>(
  search: string,
  keys: string[],
  schema: T,
): [QuerySchema<T>, string];
function omitParams<T extends DefaultQuerySchema>(
  search: string,
  keys: string[],
  schema?: T,
): [Record<string, string>, string] | [QuerySchema<T>, string] {
  const output: any = {};

  for (const [key, value] of Object.entries(qs.parse(search))) {
    if (!keys.includes(key)) {
      output[key] = value;
    }
  }

  return [schema ? parseWithSchema(output, schema) : output, stringify(output)];
}

const isNotNullOrEmpty = (value: unknown) =>
  value !== undefined && value !== null && value !== '';

/**
 * Updates search string with provided query object
 * @param {string} search - Search string input
 * @param {object} nextParams - Object containing query parameters to update. Any null or empty parameters will
 * be omitted.
 */
function updateParams<K extends Record<string, unknown>>(
  search: string,
  nextParams: K,
): [Record<string, string>, string] {
  const currentParams = parse(search);
  const params: any = {};

  for (const [key, value] of Object.entries({
    ...currentParams,
    ...nextParams,
  })) {
    if (isNotNullOrEmpty(value)) {
      params[key] = value;
    }
  }

  return [params, stringify(params)];
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default {
  parseParams,
  extractParams,
  parseWithSchema,
  omitParams,
  updateParams,
  parse,
  stringify,
};
