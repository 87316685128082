import { oauth } from '@seek/oauth-url-builders';
import { AuthN, type ClientConfig } from '@seek/online-identity';
import { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { actionCreators as settingsActionCreators } from '../../store/settings';
import useAppConfig from '../useAppConfig';

import Context from './Context';
import createClientConfig from './clientConfig';
import persistence from './persistence';
import type { ContextType, Identity } from './types';

interface Props {
  children: React.ReactNode;
}

function AuthProvider({ children }: Props) {
  const appConfig = useAppConfig();
  const location = useLocation();
  const dispatch = useDispatch();
  const { IS_LEGACY_SITE, LANGUAGE, ENVIRONMENT, COUNTRY_CODE } = appConfig;

  const clientConfigRef = useRef<Readonly<ClientConfig>>(
    createClientConfig(ENVIRONMENT, COUNTRY_CODE),
  );

  const authClientRef = useRef<AuthN | null>(null);
  const tokenRef = useRef<string | null>(
    persistence.getToken(ENVIRONMENT, clientConfigRef.current),
  );
  const identityRef = useRef<Identity | null>(
    persistence.getIdentity(clientConfigRef.current),
  );

  const getAuthClient = useCallback<() => AuthN>(() => {
    if (authClientRef.current) {
      return authClientRef.current;
    }
    const authClient = new AuthN(clientConfigRef.current);
    authClientRef.current = authClient;
    return authClient;
  }, []);

  const getLoginUrl = (): string => {
    const params = new URLSearchParams(location.search);
    if (params.has('advertiserId')) {
      return oauth.buildLoginWithScopeUrl({
        origin: window.location.origin,
        // DO NOT USE location.pathname from useLocation() below, it is not,
        // for some reason, going to contain the right URL.
        returnUri: `${window.location.pathname}${window.location.search}`,
        scope: [`advertiser:${params.get('advertiserId')}`],
        language: LANGUAGE,
      });
    }

    return oauth.buildLoginUrl({
      language: LANGUAGE,
    });
  };

  const value = useMemo<ContextType>(() => {
    const loginWithCallback = () => {
      if (IS_LEGACY_SITE) {
        const { pathname, search } = location;
        const authClient = getAuthClient();
        const dangerouslySetOauthRedirect = true;

        return authClient.login(
          { returnUri: pathname + search },
          dangerouslySetOauthRedirect,
        );
      }

      const loginUrl = getLoginUrl();
      window.location.assign(loginUrl);
    };

    const clearToken = () => {
      tokenRef.current = null;
    };

    const init = async () => {
      await getAuthClient().init();
    };

    const getToken = async () => {
      if (tokenRef.current) {
        return tokenRef.current;
      }
      try {
        const authClient = getAuthClient();
        const token = (await authClient.getToken()) || null;
        return token;
      } catch (ex) {
        switch ((ex as any).error) {
          case 'login_required':
            await loginWithCallback();
            return null;

          case 'invalid_grant': {
            clearToken();
            await loginWithCallback();
            return null;
          }
          default:
            throw ex;
        }
      }
    };

    const renewToken = () => {
      clearToken();
      return getToken();
    };

    const logout = () => {
      // TODO: fix logout redirect in local environment
      dispatch(settingsActionCreators.updatePreferredAdvertiserId(null));
      return getAuthClient().logout();
    };

    return {
      getToken,
      clearToken,
      renewToken,
      init,
      loginWithCallback,
      handleRedirect: (redirectHandler) =>
        getAuthClient().handleRedirect(redirectHandler),
      logout,
      getIdentity: async () => {
        if (identityRef.current) {
          return identityRef.current;
        }
        const identity = await getAuthClient().getIdentity();
        identityRef.current = identity;
        return identity;
      },
    };
  }, [getAuthClient, location, IS_LEGACY_SITE, LANGUAGE, dispatch]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default AuthProvider;
