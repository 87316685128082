import queryStringService from './queryStringService';
import redirectToPage from './redirectToPage';
import reloadCurrentPage from './reloadCurrentPage';

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default {
  queryStringService,
  reloadCurrentPage,
  redirectToPage,
};
