function hasSufficientCredits(
  credits: number,
  creditsToDeduct: number,
): boolean {
  return credits >= creditsToDeduct;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default hasSufficientCredits;
