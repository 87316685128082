import type { MetricsProviderConfig } from '../../hooks/useMetrics';
import type { AppConfig, MelwaysLanguage, RootState } from '../../types';

import { UtagActions } from './types';
import getGeneralTemplateValues from './utils/getGeneralTemplateValues';

export const TEALIUM_KEY = 'tealium';

type Handler = (
  { action, value }: { action: UtagActions; value: Record<string, unknown> },
  snapshot: RootState,
) => void;

export const sideEffects = {
  utagView: (payload: unknown) => window.utag.view(payload),
  utagLink: (payload: unknown) => window.utag.link(payload),
};

const provider: MetricsProviderConfig<Handler> = {
  key: TEALIUM_KEY,
  createScript: ({ TEALIUM_URL }: AppConfig) => {
    const utagScript = document.createElement('script');
    utagScript.innerHTML = `
  (function(a,b,c,d){
    a='${TEALIUM_URL}';
    b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
    a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
  })();
    `;
    document.getElementsByTagName('body')[0].appendChild(utagScript);
  },
  createHandler: ({ SITE, LANGUAGE }: AppConfig, getFeatureFlags, routes) => {
    let isLoadTest = false;
    if (typeof window !== 'undefined') {
      window.utag_data = {};
      window.utag_cfg_ovrd = { noview: true };
      isLoadTest = Boolean(window.SEEK_TEST);
    }
    return {
      send: ({ action, value: eventValue }, snapshot) => {
        const featureFlagsEnabled = getFeatureFlags()
          .filter((flag) => flag.active)
          .map((flag) => flag.key);
        try {
          const payload = {
            ...getGeneralTemplateValues(
              snapshot,
              SITE,
              LANGUAGE as MelwaysLanguage,
              (eventValue as { eventName: string }).eventName,
              routes,
            ),
            ...eventValue,
            featureFlagsEnabled,
            isFeatureFlagEnabled: featureFlagsEnabled.length > 0,
          };
          if (!isLoadTest) {
            if (action === UtagActions.View) {
              sideEffects.utagView(payload);
            } else if (action === UtagActions.Link) {
              sideEffects.utagLink(payload);
            }
          }
        } catch (error) {
          //
        }
      },
    };
  },
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default provider;
