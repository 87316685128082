import type { TalentSearchMarket } from 'src/types/generated';

import type { Action } from '../../types';

import * as actions from './actions';

type InitialState = TalentSearchMarket[] | null;

const initialState: InitialState = null;

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default (state: InitialState = initialState, action: Action) => {
  switch (action.type) {
    case actions.UPDATE_MARKETS_DETAILS: {
      return action.value;
    }

    default:
      return state;
  }
};
