import { useCallback, useRef } from 'react';

type PromiseToResolve<T> = (value: T) => void;

type FunctionToResolveOnce<T> = (...args: any[]) => Promise<T>;

const useResolveOnceAndCache = <T>(
  functionToResolveOnce: FunctionToResolveOnce<T>,
): FunctionToResolveOnce<T> => {
  const promises = useRef<Array<PromiseToResolve<T>>>([]);
  const isPending = useRef<boolean>(false);
  const result = useRef<T | null>(null);

  const resolvePendingPromises = useCallback((value: T) => {
    for (const resolve of promises.current) {
      resolve(value);
    }
  }, []);

  return useCallback(
    (...args: any[]) =>
      new Promise(async (resolve) => {
        if (result.current) {
          return resolve(result.current);
        }
        if (!isPending.current) {
          isPending.current = true;
          result.current = await functionToResolveOnce(...args);
          resolvePendingPromises(result.current);
          isPending.current = false;
          resolve(result.current);
        } else {
          promises.current.push(resolve);
        }
      }),
    [functionToResolveOnce, resolvePendingPromises],
  );
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default useResolveOnceAndCache;
