import { BraidProvider, makeLinkComponent } from 'braid-design-system';
import seekJobs from 'braid-design-system/themes/seekJobs';
import { Link as ReactRouterLink } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

export const CustomLink = makeLinkComponent(({ href, ...restProps }, ref) =>
  // FIXME: This CustomLink won't work well if we'd like to render shared components e.g. EmployerHeader, EmployerFooter
  // Reason: these components use `Link`, `ButtonLink`, etc. with relative path i.e. `/` as the first character of `href`.
  //         when we use CustomLink, it'd use client-site routing and it'd redirect user to /talentsearch/ route
  //
  // Recommendation:
  // - Remove CustomLink
  // - Find all usage of `Link`, `ButtonLink`, etc. from `braid-design-system` and use `ReactRouterLink` if it's client-side routing
  href.startsWith('/talentsearch') ||
  href.startsWith('/id/talentsearch') ||
  href.startsWith('/th/talentsearch') ? (
    <ReactRouterLink ref={ref} to={href} {...restProps} />
  ) : (
    <a ref={ref} href={href} {...restProps} />
  ),
);

function ThemedBraidProvider({ children }: Props) {
  return (
    <BraidProvider theme={seekJobs} linkComponent={CustomLink}>
      {children}
    </BraidProvider>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default ThemedBraidProvider;
