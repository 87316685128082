import { setSeekId } from '@seek/adv-account-selection';

import type { HirerDetails, Search, ATSJobsInput } from '../../types';

import * as actions from './actions';
import type { State as SettingsState } from './reducer';

export const sideEffects = {
  setItem: (key: keyof SettingsState, value: string) =>
    window.localStorage.setItem(key, value),
};

const updateSettingByKey = (key: keyof SettingsState, value: string) => {
  sideEffects.setItem(key, value);
  return {
    type: actions.UPDATE_SETTING_BY_KEY,
    value: { key, value },
  };
};

export const updateWelcomeModalDismissed = (value: boolean) =>
  updateSettingByKey('welcomeModalDismissed', String(value));

export const updateRecommendedCandidatesAlert = (value: 'visible' | 'hidden') =>
  updateSettingByKey('recommendedCandidatesAlert', value);

export const updateEthicalHiringValue = (ethicalHiringValue: boolean) =>
  updateSettingByKey('ethicalHiring', String(ethicalHiringValue));

export const updateVerifiedSignalsAlertDismissed = (value: boolean) =>
  updateSettingByKey('betaVerifiedSignalsAlertDismissed', String(value));

export const updateRecentSearches = (
  recentSearches: Search.RecentSearch[],
  localStorageKey: keyof SettingsState,
) => updateSettingByKey(localStorageKey, JSON.stringify(recentSearches));

export const updateAccessCvEmail = (value: string) =>
  updateSettingByKey('accessCvEmail', `"${value}"`);

export const updateSendMessageHirerDetails = (hirer: HirerDetails) =>
  updateSettingByKey('sendMessageHirerDetails', JSON.stringify(hirer));

export const updateSuppressSendJobModal = (value: boolean) =>
  updateSettingByKey('suppressSendJobModal', String(value));

export const setFeatureHighlightAsSeen = (
  highlights: string[],
  featureName: string,
) => {
  const value = JSON.stringify([...(highlights || []), ...[featureName]]);
  return updateSettingByKey('featureHighlights', value);
};

export const updateSearchExperiment = (searchExperimentValue: string) => ({
  type: actions.UPDATE_SETTING_BY_KEY,
  value: {
    key: 'searchExperiment',
    value: searchExperimentValue,
  },
});

export const updateRecentATSJobSearch = (recentSearch: ATSJobsInput) =>
  updateSettingByKey('recentATSJobSearch', JSON.stringify(recentSearch));

export const updatePreferredAdvertiserIdLegacy = (
  preferredAdvertiserId: number | null,
) => updateSettingByKey('preferredAdvertiserId', String(preferredAdvertiserId));

export const updatePreferredAdvertiserId = (
  preferredAdvertiserId: number | null,
) => {
  setSeekId(preferredAdvertiserId);
  return {
    type: actions.UPDATE_SETTING_BY_KEY,
    value: {
      key: 'preferredAdvertiserId',
      value: String(preferredAdvertiserId),
    },
  };
};

export const updateShowMarketSelectorInfoDialogue = (value: boolean) =>
  updateSettingByKey('showMarketSelectorInfoDialogue', String(value));
