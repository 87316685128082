import Cookies from 'js-cookie';

import { type AppConfig, Nations, type RootState } from '../types';

import {
  initialState as initialSettingsState,
  type State as SettingsState,
  overrideSettings,
} from './settings/reducer';

export const sideEffects = {
  generateId: () => Math.floor(Math.random() * 100000000).toString(),
  getLocalStorage: () => window.localStorage,
};

function createInitialState({
  COUNTRY_CODE,
}: AppConfig): Partial<RootState> | undefined {
  if (typeof window === 'undefined') {
    return undefined;
  }

  //  Gets or sets a userSessionId that is sent with
  //  all TS-API requires as a part of metrics tracking
  const userSessionIdCookieName = 'usersessionid';
  let userSessionId = Cookies.get(userSessionIdCookieName);
  if (!userSessionId) {
    userSessionId = sideEffects.generateId();
    Cookies.set(userSessionIdCookieName, userSessionId);
  }

  //  A default set of features the application requires
  const baseSettings: SettingsState = {
    ...initialSettingsState,
  };

  //  Local storage entries the application persists
  const applicationSettingKeys = Object.keys(baseSettings) as string[];

  //  Local storage entries that "WE" can persist to override application features
  const overrideSettingKeys = Object.keys(overrideSettings) as string[];

  for (const [key, value] of Object.entries(sideEffects.getLocalStorage())) {
    //  Overrides the default value with any persisted settings
    if (applicationSettingKeys.includes(key)) {
      baseSettings[key as keyof SettingsState] = value;
    }
    //  Overrides the base settings with any overrides set
    //  into local storage using the SCREAMING_CAPITAL_CASE
    if (overrideSettingKeys.includes(key)) {
      const settingsReferenceKey =
        key in overrideSettings
          ? overrideSettings[key as keyof typeof overrideSettings]
          : null;
      if (settingsReferenceKey) {
        baseSettings[settingsReferenceKey as keyof SettingsState] = value;
      }
    }
  }

  //  The default nation based on the URL
  const nation = Nations[COUNTRY_CODE];

  const settings = {
    ...baseSettings,
    userSessionId,
    nation,
  };

  return { settings };
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default createInitialState;
