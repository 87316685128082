import { GET_BALANCE } from '../../../queries/TalentSearchBudgets';
import type { Services, Balance } from '../../../types';

const dataService: Services.DataService<Balance> =
  ({ query }) =>
  async () => {
    const { talentSearchBudgetBalance } = await query<{
      talentSearchBudgetBalance: Balance;
    }>({
      query: GET_BALANCE,
    });

    return talentSearchBudgetBalance;
  };

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default dataService;
