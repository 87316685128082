import { selectors as profilesSelectors } from '../../store/profiles';
import { selectors as routerSelectors } from '../../store/router';
import { selectors as searchesSelectors } from '../../store/searches';
import { selectors as settingsSelectors } from '../../store/settings';
import { selectors as userSelectors } from '../../store/user';
import type { ProfileV2, RootState } from '../../types';
import { DATAPLATFORM_KEY } from '../dataPlatform';
import dataPlatformSelectors from '../dataPlatform/selectors';
import {
  Source,
  TabLocations,
  EventName as DataPlatformEventName,
} from '../dataPlatform/types';
import getDefaultProfileFields from '../dataPlatform/utils/getDefaultProfileFields';
import { TEALIUM_KEY } from '../tealium';
import { Pooling as PoolingLinks, UtagActions } from '../tealium/types';

const getDataFromStateForDP = (snapshot: RootState, profile?: ProfileV2) => {
  const connectionId = null;
  const jobId = routerSelectors.getActiveJobIdSelector(snapshot);
  const advertiserId = userSelectors.getAdvertiserIdSelector(snapshot);
  const userId = userSelectors.getUserIdSelector(snapshot);
  const userSessionId = settingsSelectors.getUserSessionIdSelector(snapshot);
  const searchId = searchesSelectors.getSearchIdSelector(snapshot);
  const fromProfilePage =
    dataPlatformSelectors.getFromProfilePageSelector(snapshot);
  const site = dataPlatformSelectors.getSiteValueSelector(snapshot);
  const similarCandidatesSearchId =
    searchesSelectors.getSimilarCandidatesSearchIdSelector(snapshot);

  let defaultProfileFields = null;
  let profilePosition = 0;
  if (profile) {
    defaultProfileFields = getDefaultProfileFields(profile);
    profilePosition = profilesSelectors.getProfilePositionSelector(
      snapshot,
      profile,
    );
  }

  return {
    source: Source.Site,
    site,
    resultListTab: TabLocations.Results,
    jobId,
    advertiserId,
    userId,
    userSessionId,
    searchId,
    similarCandidatesSearchId,
    profile: {
      ...defaultProfileFields,
      profilePosition: profilePosition ?? 0,
      fromProfilePage,
    },
    connectionId,
  };
};
const actions = {
  poolClick: (
    poolId: string,
    poolProfileCount: number,
    isPoolOwner: boolean,
  ) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = PoolingLinks.PoolClick;
        return {
          action: UtagActions.Link,
          value: {
            eventName,
            poolId,
            poolProfileCount,
            isPoolOwner,
          },
        };
      },
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const dataFromState = getDataFromStateForDP(snapshot);
        const tags = userSelectors.getHirerAccountTagsSelector(snapshot);
        return {
          value: {
            eventName: DataPlatformEventName.TSPoolClicked,
            metrics: {
              ...dataFromState,
              poolId,
              poolProfileCount,
              isPoolOwner,
              tags: tags
                ? { ...tags, testRecord: tags.testRecord === 'true' }
                : undefined,
            },
          },
        };
      },
    },
  ],
  poolEdit: (
    poolId: string,
    poolProfileCount: number,
    isPoolOwner: boolean,
  ) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = PoolingLinks.PoolEdit;
        return {
          action: UtagActions.Link,
          value: {
            eventName,
            poolId,
            poolProfileCount,
            isPoolOwner,
          },
        };
      },
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const dataFromState = getDataFromStateForDP(snapshot);
        const tags = userSelectors.getHirerAccountTagsSelector(snapshot);
        return {
          value: {
            eventName: DataPlatformEventName.TSPoolEdited,
            metrics: {
              ...dataFromState,
              poolId,
              poolProfileCount,
              isPoolOwner,
              tags: tags
                ? { ...tags, testRecord: tags.testRecord === 'true' }
                : undefined,
            },
          },
        };
      },
    },
  ],
  poolCreate: (poolId: string, profile?: ProfileV2) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = PoolingLinks.PoolCreate;

        return {
          action: UtagActions.Link,
          value: {
            eventName,
            ...(profile && {
              profileId: profile.profileId,
              profileType: profile.profileType,
            }),
            poolId,
          },
        };
      },
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const dataFromState = getDataFromStateForDP(snapshot, profile);
        const tags = userSelectors.getHirerAccountTagsSelector(snapshot);
        return {
          value: {
            eventName: DataPlatformEventName.TSPoolCreated,
            metrics: {
              ...dataFromState,
              poolId,
              tags: tags
                ? { ...tags, testRecord: tags.testRecord === 'true' }
                : undefined,
            },
          },
        };
      },
    },
  ],
  poolDelete: (
    poolId: string,
    poolProfileCount: number,
    isPoolOwner: boolean,
  ) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = PoolingLinks.PoolDelete;
        return {
          action: UtagActions.Link,
          value: {
            eventName,
            poolId,
            poolProfileCount,
            isPoolOwner,
          },
        };
      },
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const dataFromState = getDataFromStateForDP(snapshot);
        const tags = userSelectors.getHirerAccountTagsSelector(snapshot);
        return {
          value: {
            eventName: DataPlatformEventName.TSPoolDeleted,
            metrics: {
              ...dataFromState,
              poolId,
              poolProfileCount,
              isPoolOwner,
              tags: tags
                ? { ...tags, testRecord: tags.testRecord === 'true' }
                : undefined,
            },
          },
        };
      },
    },
  ],
  poolAddOrRemove: ({
    profile,
    action,
    pool,
  }: {
    profile: ProfileV2;
    action: 'add' | 'remove';
    pool: {
      id: string;
      name: string;
      isOwner: boolean;
      poolProfileCount: number;
    };
  }) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName =
          action === 'add'
            ? PoolingLinks.PoolAddCandidate
            : PoolingLinks.PoolRemoveCandidate;

        const { profileType, profileId } = profile;

        return {
          action: UtagActions.Link,
          value: {
            eventName,
            profileId,
            profileType,
            poolId: pool.id,
            isPoolOwner: pool.isOwner,
            poolProfileCount: pool.poolProfileCount,
          },
        };
      },
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const dataFromState = getDataFromStateForDP(snapshot, profile);

        const eventName =
          action === 'add'
            ? DataPlatformEventName.TSProfileAddedToPool
            : DataPlatformEventName.TSProfileRemovedFromPool;
        const tags = userSelectors.getHirerAccountTagsSelector(snapshot);

        return {
          value: {
            eventName,
            metrics: {
              ...dataFromState,
              poolId: pool.id,
              poolName: pool.name,
              isPoolOwner: pool.isOwner,
              poolProfileCount: pool.poolProfileCount,
              tags: tags
                ? { ...tags, testRecord: tags.testRecord === 'true' }
                : undefined,
            },
          },
        };
      },
    },
  ],
  poolShare: (poolId: string, policy: string, poolProfileCount: number) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = PoolingLinks.PoolShare;
        return {
          action: UtagActions.Link,
          value: {
            eventName,
            poolId,
            shareAccess: policy,
            poolProfileCount,
          },
        };
      },
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const dataFromState = getDataFromStateForDP(snapshot);
        const tags = userSelectors.getHirerAccountTagsSelector(snapshot);
        return {
          value: {
            eventName: DataPlatformEventName.TSPoolShared,
            metrics: {
              ...dataFromState,
              poolId,
              shareAccess: policy,
              poolProfileCount,
              tags: tags
                ? { ...tags, testRecord: tags.testRecord === 'true' }
                : undefined,
            },
          },
        };
      },
    },
  ],
  poolUnshare: (poolId: string, poolProfileCount: number) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = PoolingLinks.PoolUnshare;
        return {
          action: UtagActions.Link,
          value: {
            eventName,
            poolId,
            poolProfileCount,
          },
        };
      },
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const dataFromState = getDataFromStateForDP(snapshot);
        const tags = userSelectors.getHirerAccountTagsSelector(snapshot);
        return {
          value: {
            eventName: DataPlatformEventName.TSPoolUnshared,
            metrics: {
              ...dataFromState,
              poolId,
              poolProfileCount,
              tags: tags
                ? { ...tags, testRecord: tags.testRecord === 'true' }
                : undefined,
            },
          },
        };
      },
    },
  ],
  poolCopy: (poolId: string, poolProfileCount: number) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = PoolingLinks.PoolCopy;
        return {
          action: UtagActions.Link,
          value: {
            eventName,
            poolId,
            poolProfileCount,
          },
        };
      },
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const dataFromState = getDataFromStateForDP(snapshot);
        const tags = userSelectors.getHirerAccountTagsSelector(snapshot);
        return {
          value: {
            eventName: DataPlatformEventName.TSPoolCopied,
            metrics: {
              ...dataFromState,
              poolId,
              poolProfileCount,
              tags: tags
                ? { ...tags, testRecord: tags.testRecord === 'true' }
                : undefined,
            },
          },
        };
      },
    },
  ],
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default actions;
