import { useEffect, useRef, useMemo, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useInterval } from 'react-use';

import queryStringService, {
  parsers,
} from '../../services/location/queryStringService';
import { selectors as routerSelectors } from '../../store/router';
import { selectors as userSelectors } from '../../store/user';
import useRouter from '../useRouter';

import Context from './Context';
import {
  type Poll,
  HotjarId,
  type HotjarEventType,
  type PollKey,
  type HotjarEvent,
} from './types';

export interface Props {
  polls: Poll[];
  children: React.ReactNode;
}

/**
 * Default delay to check for the hotjar been added to global "window" object
 */
export const INTERVAL_DELAY = 500;

export const utils = {
  /**
   * Does the hotjar instance is initialized
   * @returns boolean
   */
  isHotjarInitialized: () => typeof window.hj === 'function',

  /**
   * custom hook to verify the hotjar script was loaded onto global window object
   * @returns boolean
   */
  useHotjarLoaded: () => {
    const [isHotjarInitalized, setIsHotjarInitialized] = useState(false);
    // interval hook to verify the hotjar script has been loaded
    useInterval(
      () => {
        if (utils.isHotjarInitialized()) {
          setIsHotjarInitialized(true);
        }
      },
      isHotjarInitalized ? null : INTERVAL_DELAY,
    );
    return isHotjarInitalized;
  },

  /**
   *  Create hotjar tracking script code
   * @param hotjarId The site's ID (This is the ID which tells Hotjar which site settings it should load and where it should save the data collected.)
   * @param hjsv (Optional) The 'Hotjar Snippet Version' - The version of the Tracking Code you are using. This is only needed if Hotjar ever updates the Tracking Code and needs to discontinue older ones. Defaults to latest version "6"
   * @returns string
   */
  getScript: (hotjarId: number, hjsv = 6) => `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${hotjarId},hjsv:${hjsv}};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
};

export function HotjarProvider({ polls, children }: Props) {
  const triggeredRef = useRef<string[]>([]);
  const {
    location: { pathname, search },
  } = useRouter();

  const isPremiumUser = useSelector(userSelectors.isPremiumSelector);
  const advertiserId = useSelector(userSelectors.getAdvertiserIdSelector);
  const hasUserData = useSelector(userSelectors.getInitialDataStateSelector);
  const isValidRoute = useSelector(routerSelectors.isValidRouteSelector);
  const shouldInjectHotjarScript = hasUserData && isValidRoute;

  const { openProfileId } = queryStringService.parseParams(search, {
    openProfileId: { type: parsers.number, defaultValue: null },
  });

  const prevOpenProfileId = useRef(openProfileId);
  const nextOpenProfileId = useMemo(() => {
    if (prevOpenProfileId.current !== openProfileId) {
      prevOpenProfileId.current = openProfileId;
    }
    return prevOpenProfileId.current;
  }, [openProfileId]);

  const isHotjarInitalized = utils.useHotjarLoaded();

  /**
   * Send an event to window hotjar object
   * @param eventType type of event enum
   * @param value data for the event
   */
  const sendEvent = useCallback(
    async (eventType: HotjarEventType, value: string | string[]) => {
      try {
        if (isHotjarInitalized) {
          window.hj(eventType, value);
        }
      } catch (ex) {
        // TODO: What to do in case of error
      }
    },
    [isHotjarInitalized],
  );

  useEffect(() => {
    sendEvent('stateChange', pathname);
  }, [pathname, sendEvent]);

  /*
   * Custom event map when the profile drawer is open
   * All other state changes are keyed off of the pathname
   */
  useEffect(() => {
    if (!nextOpenProfileId) {
      return;
    }
    sendEvent('event', ['profile-drawer-open']);
  }, [nextOpenProfileId, sendEvent]);

  const value = useMemo(() => {
    const userParams = {
      isPremiumUser,
      advertiserId,
    };
    return {
      trigger: (pollKey: PollKey) => {
        const poll = polls.find(({ key }) => key === pollKey);
        if (
          poll?.test(userParams) &&
          !triggeredRef.current.includes(poll.key)
        ) {
          triggeredRef.current.push(poll.key);
          sendEvent('trigger', poll.key);
        }
      },
      triggerEvent: (eventName: HotjarEvent) => {
        sendEvent('event', eventName);
      },
    };
  }, [isPremiumUser, advertiserId, polls, sendEvent]);

  return (
    <Context.Provider value={value}>
      <Helmet>
        <body>
          {shouldInjectHotjarScript &&
            utils.getScript(
              isPremiumUser ? HotjarId.Premium : HotjarId.Standard,
            )}
        </body>
      </Helmet>
      {children}
    </Context.Provider>
  );
}
