import { Environment } from 'src/types';

export const tealiumConfig = {
  [Environment.Production]: {
    url: '//tags.tiqcdn.com/utag/seek/hirer/prod/utag.js',
  },
  [Environment.Staging]: {
    url: '//tags.tiqcdn.com/utag/seek/hirer/dev/utag.js',
  },
  [Environment.Development]: {
    url: '//tags.tiqcdn.com/utag/seek/hirer/dev/utag.js',
  },
} as const;
