import { useTranslations } from '@vocab/react';
import { Box, Text } from 'braid-design-system';
import { NavLink } from 'react-router-dom';

import useAuth from '../../../../../../hooks/useAuth';

import translations from './.vocab';

function SignOut() {
  const { logout } = useAuth();
  const { t } = useTranslations(translations);

  const handleSignOutClick = async (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    //  Sign the user out of SSO (if available) and redirect
    return logout();
  };

  return (
    <NavLink to="#" onClick={handleSignOutClick} className="navlink">
      <Box display="flex" alignItems="center" padding="medium">
        <Text tone="link">{t('SIGN_OUT')}</Text>
      </Box>
    </NavLink>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default SignOut;
