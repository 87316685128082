import { Box, Inline, IconNewWindow } from 'braid-design-system';
import type { ComponentProps } from 'react';

import type { useRoutes } from 'src/hooks/useRoutes';
import { PRODUCT_TYPE } from 'src/types/acl';

import { ACL } from '../../../../../../types';
import Amount from '../../../../../UI/Amount';

import type { Option, PageLink } from './types';

type SceneLinkConfig = Record<ACL.SceneLink, Option>;
type ReactNodeNoStrings = ComponentProps<typeof Inline>['children'];
type BoxChildren = ComponentProps<typeof Box>['children'];

const isShowBalanceOnly = (productType: string) => {
  if (productType === PRODUCT_TYPE.PTS || productType === PRODUCT_TYPE.CTS) {
    // for PTS and CTS we show Balance with link to credit dashboard
    return false;
  }
  return true;
};

const createSceneLinkConfig = (
  routes: ReturnType<typeof useRoutes>['routes'],
): SceneLinkConfig => ({
  [ACL.SCENE_LINK.TALENT_SEARCH]: {
    test: () => true,
    getLinkProps: ({ t }) => ({
      isActive: (match, { pathname } = {}) =>
        match &&
        // FIXME: it is unsafe to do any `pathname` string matching as we are trying to do React Router's job and it doesn't work in all cases e.g. when there's path params
        (pathname === routes.searchUncoupled() ||
          pathname === routes.searchResultsUncoupled()),
      href: routes.searchUncoupled(),
      linkText: t('TALENT_SEARCH'),
      children: <>{t('TALENT_SEARCH')}</>,
    }),
  },
  [ACL.SCENE_LINK.TALENT_SEARCH_BY_JOB]: {
    test: () => true,
    getLinkProps: ({ t }) => ({
      href: routes.searchCoupled(),
      linkText: t('TALENT_SEARCH_BY_JOB', {
        Inline: (children: ReactNodeNoStrings) => (
          <Inline space="xxsmall">{children}</Inline>
        ),
        Box: (children: BoxChildren) => <Box>{children}</Box>,
      }),
      children: (
        <>
          {t('TALENT_SEARCH_BY_JOB', {
            br: () => <br />,
            Inline: (children: ReactNodeNoStrings) => (
              <Inline space="xxsmall">{children}</Inline>
            ),
            Box: (children: BoxChildren) => <Box>{children}</Box>,
          })}
        </>
      ),
    }),
  },
  [ACL.SCENE_LINK.TALENT_POOLING]: {
    test: () => true,
    getLinkProps: ({ t }) => ({
      href: routes.pooling(),
      linkText: t('TALENT_POOLS'),
      children: <>{t('TALENT_POOLS')}</>,
    }),
  },
  [ACL.SCENE_LINK.CREDITS_DASHBOARD]: {
    test: ({ productType }) => !isShowBalanceOnly(productType),
    getLinkProps: ({ advertisersCreditDashboardUrl, creditBalance, t }) => ({
      href: advertisersCreditDashboardUrl,
      hasCreditsMetricsEvent: true,
      hasCredits: true,
      isExternal: true,
      children: (
        <>
          {t('CREDITS')} <Amount value={creditBalance} /> <IconNewWindow />
        </>
      ),
    }),
  },
  [ACL.SCENE_LINK.CREDITS_COUPLED_BALANCE_ONLY]: {
    test: ({ productType }) => isShowBalanceOnly(productType),
    getLinkProps: ({ jobId, creditBalance, t }) => ({
      href: null,
      hasCredits: true,
      children: (
        <>
          {t('CREDITS')}
          {jobId && (
            <>
              {' '}
              <Amount value={creditBalance} />
            </>
          )}
        </>
      ),
    }),
  },
});

export const getPageLinks = (
  links: ACL.SceneLink[],
  {
    creditDashboardUrl,
    advertiserId,
    jobId,
    creditBalance,
    countryCode,
    productType,
    isLegacySite = true,
    t,
  }: {
    creditDashboardUrl: string;
    advertiserId: number;
    jobId: number | null;
    creditBalance: number;
    countryCode: string;
    productType: string;
    isLegacySite?: boolean;
    t: (key: string, param?: Record<string, any>) => string;
  },
  routes: ReturnType<typeof useRoutes>['routes'],
): PageLink[] => {
  const pageLinks: PageLink[] = [];
  for (const sceneLinkKey of links) {
    const { test, getLinkProps } = createSceneLinkConfig(routes)[sceneLinkKey];
    const advertisersCreditDashboardUrl = `${creditDashboardUrl}/${advertiserId}`;
    if (test({ jobId, countryCode, isLegacySite, productType })) {
      pageLinks.push(
        getLinkProps({
          advertisersCreditDashboardUrl,
          jobId,
          creditBalance,
          t,
        }),
      );
    }
  }

  return pageLinks;
};
