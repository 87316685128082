export enum Source {
  Site = 'talentsearch_ui',
}

export enum PageLocations {
  PersonalPool = 'personalpool',
  FollowerStream = 'followerstream',
  CustomStream = 'customstream',
  TalentPooling = 'talentpooling',
  SimilarCandidates = 'similarcandidates',
  Profile = 'profile',
  Default = 'talentsearch',
}

export enum TabLocations {
  Results = 'All Results Tab',
}

export enum SubSections {
  Coupled = 'coupled',
  Uncoupled = 'uncoupled',
}

export enum EventName {
  TSProfileOpened = 'ts_profileOpened',
  TSSendMessageCompleted = 'ts_sendMessageCompleted',
  TSSendJobCompleted = 'ts_sendJobCompleted',
  TSAccessCVCompleted = 'ts_accessCvCompleted',
  TSProfileShortlisted = 'ts_profileShortlisted',
  TSProfileRemovedFromShortlist = 'ts_profileRemovedFromShortlisted',
  TSProfileRemovedFromPool = 'ts_profileRemovedFromPool',
  TSProfileAddedToPool = 'ts_profileAddedToPool',
  TSPoolClicked = 'ts_poolClicked',
  TSPoolEdited = 'ts_poolEdited',
  TSPoolDeleted = 'ts_poolDeleted',
  TSPoolCreated = 'ts_poolCreated',
  TSPoolShared = 'ts_poolShared',
  TSPoolUnshared = 'ts_poolUnshared',
  TSPoolCopied = 'ts_poolCopied',
  TSPoolViewed = 'ts_poolViewed',
  TSPoolsViewed = 'ts_poolsViewed',
  TSSendMessageFailed = 'ts_sendMessageFailed',
  TSSendJobFailed = 'ts_sendJobFailed',
  TSAccessCvFailed = 'ts_accessCvFailed',
  TSDownloadCvCompleted = 'ts_downloadCvCompleted',
  TSExportSeekProfileCompleted = 'ts_exportSeekProfileCompleted',
  TSInteractionsTabOpened = 'ts_interactionsTabOpened',
  TSCvPreviewed = 'ts_cvPreviewed',
  TSAtsCustomAction = 'ts_atsCustomAction',
}
