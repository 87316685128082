import type { Account } from '../../../types';

export const DEFAULT_SIZE = 7;

export interface LegacyAccount
  extends Pick<
    Account,
    | 'hasConnectedTalentSearchAccess'
    | 'hasPremiumTalentSearchAccess'
    | 'companyAddress'
  > {
  id: number;
  name: string;
  accountNumber: number;
}

export interface LegacyAccountsResponse {
  accounts: LegacyAccount[];
  totalAccounts: number;
}
