import React, { type ReactNode, useState, useMemo } from 'react';

import Content from './components/Content';
import MenuContext from './components/Context';
import Title from './components/Title';

interface Props {
  children: ReactNode;
}

const MenuGroup = ({ children }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const value = useMemo(() => ({ isExpanded, setIsExpanded }), [isExpanded]);

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

MenuGroup.Title = Title;
MenuGroup.Content = Content;

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default MenuGroup;
