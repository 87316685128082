import {
  getActiveRouteKeySelector,
  getChannelFromParams,
  getActiveJobIdSelector,
} from '../../../store/router/selectors';
import { isPremiumSelector } from '../../../store/user/selectors';
import { RouteKeys, type RootState } from '../../../types';
import { PageLocations, SubSections } from '../types';

const channelToSiteMapper = {
  stream: PageLocations.CustomStream,
  pool: PageLocations.PersonalPool,
  follower: PageLocations.FollowerStream,
};

const siteValueOptions: Record<
  string,
  (
    snapshot: RootState,
    params: { key: string; feature: string; subSection: string },
  ) => string
> = {
  [RouteKeys.Pooling]: (_, { subSection }) =>
    `adv:${PageLocations.TalentPooling}:${subSection}`,
  [RouteKeys.PoolingChannel]: (snapshot, { feature, subSection }) =>
    `adv:${
      (channelToSiteMapper as any)[getChannelFromParams(snapshot)]
    }:${feature}${subSection}`,
  [RouteKeys.SearchResults]: (_, { feature, subSection }) =>
    `adv:${PageLocations.Default}:${feature}${subSection}`,
  [RouteKeys.SimilarCandidates]: () => `adv:${PageLocations.SimilarCandidates}`,
  [RouteKeys.Profile]: (_, { subSection }) =>
    `adv:${PageLocations.Profile}:${subSection}`,
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default function getSiteValueSelector(snapshot: RootState) {
  const key = getActiveRouteKeySelector(snapshot);
  const feature = isPremiumSelector(snapshot) ? 'premium+' : '';
  const subSection = getActiveJobIdSelector(snapshot)
    ? SubSections.Coupled
    : SubSections.Uncoupled;

  const siteValue =
    key in siteValueOptions
      ? siteValueOptions[key](snapshot, { key, feature, subSection })
      : `adv:${key}:${feature}${subSection}`;

  return siteValue;
}
