import { Fragment } from 'react';

interface Props {
  components: Array<
    | [React.ComponentType<any>, Record<string, unknown>]
    | React.ComponentType<any>
  >;
  children: React.ReactNode;
}

const Providers = ({ components, children }: Props) => (
  <Fragment>
    {components.reverse().reduce((acc, component) => {
      const [Provider, props] = Array.isArray(component)
        ? [component[0], component[1]]
        : [component, {}];
      return <Provider {...props}>{acc}</Provider>;
    }, children)}
  </Fragment>
);

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default Providers;
