import type { AppConfig } from 'src/types';

interface Window {
  __TS_CONFIG__?: AppConfig;
}

interface Globals {
  window: Window;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default Object.defineProperty({}, 'window', {
  get: () => (typeof window === 'undefined' ? {} : window),
}) as Globals;
