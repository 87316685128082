import { APIErrorCode } from '../../types';

export const API_ERROR_CODE_TO_FRIENDLY_CODE_MAP: Record<string, string> = {
  [APIErrorCode.FORBIDDEN_TOGGLE_ACCOUNT_INCORRECT_ADVERTISER_ID]: 'xk0JjM',
  [APIErrorCode.FORBIDDEN_TOGGLE_ACCOUNT_NO_USER_ID_SPECIFIED]: 'TVWcLa',
  [APIErrorCode.FORBIDDEN_AUTHORISATION_ERROR]: 'b8dE3f',
  [APIErrorCode.FORBIDDEN_BETA_TEST_ENVIRONMENT_ACCESS]: 'TgxKah',
  [APIErrorCode.INVALID_AUTH_CONFIGURATION]: 'rtZ691',
  [APIErrorCode.FORBIDDEN_MISSING_AUTH_CONTEXT_OR_USER]: 'Vzjb65',
  [APIErrorCode.FORBIDDEN_INVALID_ACCESS_TO_RESOURCE]: '5RJ7gg',
};

const FALLBACK_UNKNOWN_CODE = 'XY2rVG';

/**
 * Maps an API Error Code to a supported friendly error code (for debugging)
 * @param {APIErrorCode} apiErrorCode
 */
function mapAPIErrorCodeToFriendlyCode(apiErrorCode?: APIErrorCode): string {
  if (!apiErrorCode) {
    return FALLBACK_UNKNOWN_CODE;
  }
  return apiErrorCode in API_ERROR_CODE_TO_FRIENDLY_CODE_MAP
    ? API_ERROR_CODE_TO_FRIENDLY_CODE_MAP[apiErrorCode]
    : FALLBACK_UNKNOWN_CODE;
}

/**
 * Maps a friendly error code back to an API Error Code
 * @param {string} code
 */
function mapFriendlyCodeToAPIErrorCode(code?: string): APIErrorCode | null {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const errorType = Object.entries(API_ERROR_CODE_TO_FRIENDLY_CODE_MAP).find(
    ([_, value]) => value === code,
  );
  return (errorType?.[0] as APIErrorCode) || null;
}

export { mapFriendlyCodeToAPIErrorCode, mapAPIErrorCodeToFriendlyCode };
