import { Stack, Text } from 'braid-design-system';

interface Props {
  advertiserName: string | null;
  userFirstName: string | null;
  accountNumber: number;
}

function ActiveAccount({
  userFirstName,
  advertiserName,
  accountNumber,
}: Props) {
  return (
    <Stack space="small">
      <Text weight="strong">{userFirstName} </Text>
      <Text>
        {advertiserName} ({accountNumber})
      </Text>
    </Stack>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default ActiveAccount;
