import {
  TalentSearchConnectionType,
  type ConnectionAvailability,
  type ProfileSendJobStatus,
  SendJobStatus,
} from '../../../types';
import { INITIAL_CONNECTION_METHODS } from '../constants';

import getTimestamp from './dateWrapper';

interface Params {
  connectionsAvailability: ConnectionAvailability[];
  sendJobStatus?: ProfileSendJobStatus;
}

function getConnectionMethods({
  connectionsAvailability,
  sendJobStatus,
}: Params) {
  return connectionsAvailability.reduce((accumulator, connection) => {
    const {
      isUsed,
      connectionTypeName,
      createdDateUtc,
      nextAvailableDateUtc,
      connectionId,
    } = connection;
    const connectionMethod = {
      isPending: false,
      hasError: false,
      isUsed,
      ...(connectionId && { connectionId }),
      ...(createdDateUtc && {
        lastConnectionTimestamp: getTimestamp(createdDateUtc),
      }),
      ...(nextAvailableDateUtc && {
        nextAvailableConnectionTimestamp: getTimestamp(nextAvailableDateUtc),
      }),
    };

    switch (connectionTypeName) {
      case TalentSearchConnectionType.ITA: {
        if (sendJobStatus) {
          return {
            ...accumulator,
            sendJob: {
              ...accumulator.sendJob,
              isUsed: sendJobStatus.status === SendJobStatus.AllJobsSent,
              status: sendJobStatus.status,
            },
          };
        }
        return { ...accumulator, sendJob: connectionMethod };
      }
      case TalentSearchConnectionType.MSG:
      case TalentSearchConnectionType.ATS_MSG:
        return isUsed
          ? { ...accumulator, sendMessage: connectionMethod }
          : accumulator;
      case TalentSearchConnectionType.JSP:
        return { ...accumulator, accessCv: connectionMethod };
      case TalentSearchConnectionType.PROFILE_EXPORT_ATS:
        return isUsed
          ? { ...accumulator, exportProfile: connectionMethod }
          : accumulator;
      default:
        return accumulator;
    }
  }, INITIAL_CONNECTION_METHODS);
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default getConnectionMethods;
