// Default exports can be ignored for this file. sku templates provide default exports as standard here
/* eslint-disable import/no-default-export */

import loadable from 'sku/@loadable/component';

import { RouteKeys, RoutePaths, type RouteDefinition, ACL } from './types';

const reactRouterPaths = Object.keys(RoutePaths).reduce(
  (res, key) => {
    // @ts-expect-error - TS doesn't like the fact that we're using a dynamic key. Please fix if you know how to handle TypeScript enums.
    res[key] = `/:language?${res[key]}`;
    return res;
  },
  { ...RoutePaths },
);

//  NOTE: Order matters!
//  https://reacttraining.com/react-router/core/api/Route/path-string
//  react-router will always return the FIRST matching route by path
const unrestricted: RouteDefinition[] = [
  {
    key: RouteKeys.Error,
    path: reactRouterPaths.ErrorCustom,
    exact: true,
    component: loadable(() => import('./scenes/Error')),
  },
  {
    key: RouteKeys.Error,
    path: reactRouterPaths.Error,
    exact: true,
    component: loadable(() => import('./scenes/Error')),
  },
];

const restricted: RouteDefinition[] = [
  {
    key: RouteKeys.Pooling,
    path: reactRouterPaths.Pooling,
    exact: true,
    component: loadable(() => import('./scenes/Pooling')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.POOLING,
    },
  },
  {
    key: RouteKeys.PoolingChannel,
    path: reactRouterPaths.PoolingChannel,
    exact: true,
    component: loadable(() => import('./scenes/PoolingChannel')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.POOLING,
    },
  },
  {
    key: RouteKeys.Profile,
    path: reactRouterPaths.Profile,
    exact: true,
    component: loadable(() => import('./scenes/Profile/ProfileContainer')),
  },
  {
    key: RouteKeys.SearchResults,
    path: reactRouterPaths.SearchResultsCoupled,
    exact: true,
    component: loadable(() => import('./scenes/Search')),
  },
  {
    key: RouteKeys.Search,
    path: reactRouterPaths.SearchCoupled,
    exact: true,
    component: loadable(() => import('./scenes/Search')),
  },
  {
    key: RouteKeys.SearchResults,
    path: reactRouterPaths.SearchResultsUncoupled,
    exact: true,
    component: loadable(() => import('./scenes/Search')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.SEARCH_UNCOUPLED,
    },
  },
  {
    key: RouteKeys.Search,
    path: reactRouterPaths.SearchUncoupled,
    exact: true,
    component: loadable(() => import('./scenes/Search')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.SEARCH_UNCOUPLED,
    },
  },
  {
    key: RouteKeys.SimilarCandidates,
    path: reactRouterPaths.SimilarCandidates,
    exact: true,
    component: loadable(() => import('./scenes/SimilarCandidates')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.SIMILAR_CANDIDATES,
    },
  },
  {
    key: RouteKeys.Help,
    path: reactRouterPaths.Help,
    exact: true,
    component: loadable(() => import('./scenes/Help')),
  },
  {
    key: RouteKeys.Root,
    path: '*',
    exact: false,
    component: loadable(() => import('./scenes/Root')),
  },
];

const recommendedCandidates = {
  key: RouteKeys.RecommendedCandidates,
  path: reactRouterPaths.RecommendedCandidates,
  exact: true,
  component: loadable(() => import('./scenes/RecommendedCandidates')),
} satisfies RouteDefinition;

export default {
  unrestricted,
  restricted,
  recommendedCandidates,
};
