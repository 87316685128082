import type { CompactProfileV2, ProfileV2 } from '../../types';

function isApproachable({
  approachabilitySignal,
}: CompactProfileV2 | ProfileV2): boolean {
  return approachabilitySignal ? approachabilitySignal === 2 : false;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default isApproachable;
