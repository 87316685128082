import queryStringService from '../../services/location/queryStringService';
import { FilterDelimiter, Search, type CriteriaKey } from '../../types';
import { getSalaryTypeFromStringV2 } from '../salary/utils';

type KeyMap = Record<CriteriaKey, (value: any) => string>;

const FROM_CRITERIA_MAP: KeyMap = {
  [Search.CriteriaKey.Approachable]: (value) => value,
  [Search.CriteriaKey.Industry]: (value) => value.join(','),
  [Search.CriteriaKey.Company]: (value) =>
    value.map(encodeURIComponent).join(FilterDelimiter.Company),
  [Search.CriteriaKey.CV]: (value) => value,
  [Search.CriteriaKey.Keywords]: (value) => encodeURIComponent(value),
  [Search.CriteriaKey.LastUpdated]: (value) => value,
  [Search.CriteriaKey.LocationList]: (value) => value.join(','),
  [Search.CriteriaKey.SalaryMax]: (value) => value,
  [Search.CriteriaKey.SalaryMin]: (value) => value,
  [Search.CriteriaKey.Nation]: (value) => value,
  [Search.CriteriaKey.SalaryNation]: (value) => value,
  [Search.CriteriaKey.SalaryType]: (value) => getSalaryTypeFromStringV2(value),
  [Search.CriteriaKey.SalaryUnspecified]: (value) => value,
  [Search.CriteriaKey.UncoupledFreeText]: (value) => encodeURIComponent(value),
  // DEPRECATED: will be removed when gRTW is released
  [Search.CriteriaKey.Visa]: (value) => value.join(','),
  [Search.CriteriaKey.WorkType]: (value) => value.join(','),
  [Search.CriteriaKey.WillingToRelocate]: (value) => value,
  [Search.CriteriaKey.ProfileTypes]: (value) => value.join(','),

  [Search.CriteriaKey.RightToWorkIds]: (value) => value.join(','),
  [Search.CriteriaKey.RightToWorkUnspecified]: (value) => value,
};

const hasValue = (value: unknown) =>
  Array.isArray(value)
    ? value.length !== 0
    : value !== null && value !== '' && value !== undefined;

function getSearchParamsFromCriteria(criteria: Record<string, any>): string {
  const parsedCriteria: Record<string, string> = {};

  for (const [key, value] of Object.entries(criteria)) {
    if (key in FROM_CRITERIA_MAP && hasValue(value)) {
      const parser = FROM_CRITERIA_MAP[key as keyof KeyMap];
      parsedCriteria[key] = parser(value);
    }
  }

  return queryStringService.stringify(parsedCriteria, { encode: false });
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default getSearchParamsFromCriteria;
