import { getConnectionRate } from '@seek/talentsearch-credit-rates';
import { Connection } from '@seek/talentsearch-entity-types';

import { ACL, type CreditRates } from '../../types';

const getCreditRates = (productType: ACL.ProductType): CreditRates => {
  switch (productType) {
    case ACL.PRODUCT_TYPE.CTS:
      return {
        sendJob: getConnectionRate(Connection.ConnectionType.ITA),
        sendMessage: getConnectionRate(Connection.ConnectionType.ATS_MSG),
        accessCv: 0,
        exportSeekProfile: 0,
        exportSharedProfile: 0,
        refreshSharedProfile: 0,
        exportProfileCts: getConnectionRate(
          Connection.ConnectionType.PROFILE_EXPORT_ATS,
        ),
      };
    // PTS is the default
    default:
      return {
        sendJob: getConnectionRate(Connection.ConnectionType.ITA),
        sendMessage: getConnectionRate(Connection.ConnectionType.MSG),
        accessCv: getConnectionRate(Connection.ConnectionType.JSP),
        exportSeekProfile: 0,
        exportSharedProfile: 0,
        refreshSharedProfile: 0,
        exportProfileCts: 0,
      };
  }
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default getCreditRates;
