import { siteConfig as siteConfigs } from '@seek/melways-sites';

import { TALENTSEARCH_PREFIX } from 'src/constants';
import {
  type AppConfig,
  Language,
  type ClientContext,
  type CountryCodes,
} from 'src/types';

import { datadogConfig } from './datadog';
import { graphqlConfig } from './graphql';
import { tealiumConfig } from './tealium';
import { trackingConfig } from './tracking';

export const createAppConfig = ({
  site: SITE,
  environment: ENVIRONMENT,
  locale: LOCALE,
  language: LANGUAGE,
  name: APP_NAME,
  version: APP_VERSION,
  branch: BRANCH_NAME,
}: ClientContext): AppConfig => {
  const siteConfig = siteConfigs[SITE];

  return {
    SITE,
    ENVIRONMENT,
    LANGUAGE,
    LOCALE,
    APP_NAME,
    APP_VERSION,
    BRANCH_NAME,
    SUPPORTED_LANGUAGES: siteConfig.languages.map((lang) => lang as Language),
    COUNTRY_CODE:
      siteConfig.classification.country.toUpperCase() as CountryCodes,
    ROUTER_BASE_NAME:
      LANGUAGE === Language.EN
        ? `${TALENTSEARCH_PREFIX}/`
        : `/${LANGUAGE}${TALENTSEARCH_PREFIX}/`,
    DATADOG_RUM_CONFIG: datadogConfig[ENVIRONMENT],
    TEALIUM_URL: tealiumConfig[ENVIRONMENT].url,
    TRACKING_URL: trackingConfig[ENVIRONMENT].url,
    HIRER_GRAPH_URL: graphqlConfig[ENVIRONMENT].url,
    IS_LEGACY_SITE: false,
  };
};
