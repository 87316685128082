import { useTranslations } from '@vocab/react';
import { Text, Alert } from 'braid-design-system';
import { DateTime } from 'luxon';

import useAppConfig from 'src/hooks/useAppConfig';
import { formatDuration } from 'src/services/date/dateFormatter';

import type { Contract } from '../../../../../../types';

import translations from './.vocab';

interface Props {
  premiumContract: Contract;
}

function TrialWarning({ premiumContract }: Props) {
  const { LOCALE } = useAppConfig();

  const now = new Date();
  const premiumContractEndDate = new Date(premiumContract.endDate * 1000);
  const { t } = useTranslations(translations);

  let daysRemaining = '';
  const nowLuxonDate = DateTime.local().startOf('day');
  const premiumContractEndDateLuxon = DateTime.fromJSDate(
    premiumContractEndDate,
  ).startOf('day');
  const { days } = premiumContractEndDateLuxon.diff(nowLuxonDate, ['days']);
  if (days <= 0) {
    daysRemaining = t('N_DAYS', { days: days.toString() });
  } else {
    daysRemaining = formatDuration(
      new Date(now.toDateString()),
      new Date(premiumContractEndDate.toDateString()),
      ['days'],
      'long',
      LOCALE,
    );
  }

  return (
    <Alert tone="caution">
      <Text>
        {t('DAYS_LEFT_ON_PREMIUM_TALENT_SEARCH_TRIAL', {
          daysRemaining,
        })}
      </Text>
    </Alert>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default TrialWarning;
