import type { ProfileV2, AsyncState } from '../../../types';
import { INITIAL_CONNECTION_METHODS } from '../constants';

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default function getInitialEntries(
  profiles: ProfileV2[],
): AsyncState['entries'] {
  return profiles.reduce(
    (accumulator, profile, index) => ({
      ...accumulator,
      [String(profile.profileId)]: {
        position: index + 1,
        assignedPools: [],
        latestInteraction: null,
        hasConnectionHistory: false,
        ...INITIAL_CONNECTION_METHODS,
      },
    }),
    {},
  );
}
