import type { ProfileV2 } from '../../types';

function getLastInteractionTimestamp({
  sendJob,
  sendMessage,
  accessCv,
  exportProfile,
}: ProfileV2): number | null {
  const [latestTimestamp = null] = [
    sendJob,
    sendMessage,
    accessCv,
    exportProfile,
  ]
    .map((connection) => connection && connection.lastConnectionTimestamp)
    .filter(Boolean)
    .sort();

  return latestTimestamp;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default getLastInteractionTimestamp;
