import {
  type Action,
  AccessMethods,
  type User,
  type CreditRates,
  type TalentSearchFeature,
} from '../../types';

import * as actions from './actions';

interface State {
  isAuthenticated: boolean;
  accessMethod: AccessMethods;
  isProduction: boolean;
  advertiser: User['advertiser'];
  ats: User['ats'] | null;
  user: User['user'];
  featureFlags: TalentSearchFeature[];
  rates: CreditRates;
  hasUserContext: boolean;
  hasAllRequiredUserContext: boolean;
}

const initialState: State = {
  isAuthenticated: false,
  accessMethod: AccessMethods.Unknown,
  isProduction: false,
  advertiser: {
    id: 0,
    name: '',
    companyId: null,
    companyName: '',
    accountNumber: 0,
    isPremium: false,
    premiumContract: null,
  },
  ats: null,
  user: {
    id: 0,
    firstName: null,
    lastName: null,
    email: null,
    isHirer: false,
    isConnectedTalentSearchUser: false,
  },
  featureFlags: [],
  rates: {
    sendJob: 0,
    sendMessage: 0,
    accessCv: 0,
    exportSeekProfile: 0,
    exportSharedProfile: 0,
    refreshSharedProfile: 0,
    exportProfileCts: 0,
  },
  hasUserContext: false,
  hasAllRequiredUserContext: false,
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case actions.RECEIVE_IS_AUTHENTICATED_DATA: {
      const { isAuthenticated, accessMethod } = action.value;
      return {
        ...state,
        isAuthenticated,
        accessMethod,
      };
    }

    case actions.RECEIVE_USER_CONTEXT_DATA:
      return {
        ...state,
        ...action.value,
        hasUserContext: true,
      };

    case actions.RECEIVE_EXTENDED_USER_CONTEXT_DATA:
      return {
        ...state,
        ...action.value,
        hasAllRequiredUserContext: true,
      };

    default:
      return state;
  }
};
