import type {
  Pool,
  AssignedPools,
  LatestInteraction,
  ProfileTypes,
  UserPoolSelection,
  ProfileConnectionsAvailability,
  ProfileSendJobStatus,
  SendJobStatus,
  ConnectionTypes,
  ProfileV2,
  CompleteProfileV2,
} from '../../types';

import {
  PROFILES_RECEIVED,
  UPDATE_ALL_ASYNC_STATE,
  UPDATE_ASYNC_ERROR_STATE,
  REMOVE_PROFILES,
  UPDATE_ASSIGNED_POOLS_IN_PROFILE,
  UPDATE_ADD_OR_REMOVED_PROFILE_IN_POOLS,
  CONNECTION_REDEEMED,
  CONNECTION_REQUESTED,
  UNCOUPLED_SEND_JOB_REDEEMED,
  CONNECTION_FAILED,
  UPDATE_LATEST_INTERACTION_IN_PROFILE,
} from './actions';

interface Connection {
  connectionId: string;
  connectionType: ConnectionTypes;
  creditsRedeemedDateUtc?: string;
  nextAvailableDateUtc?: string;
}

export const createProfilesReceivedAction = (
  profiles: ProfileV2[] | CompleteProfileV2[],
) => ({
  type: PROFILES_RECEIVED,
  value: { profiles },
});

export const updateAsyncStateForProfiles = ({
  assignedPoolsForProfiles,
  latestInteractionsForProfiles,
  connectionsAvailabilitiesForProfiles,
  sendJobStatusForProfiles,
}: {
  assignedPoolsForProfiles: AssignedPools[];
  latestInteractionsForProfiles: LatestInteraction[];
  connectionsAvailabilitiesForProfiles: ProfileConnectionsAvailability[];
  sendJobStatusForProfiles: ProfileSendJobStatus[];
}) => ({
  type: UPDATE_ALL_ASYNC_STATE,
  value: {
    assignedPoolsForProfiles,
    latestInteractionsForProfiles,
    connectionsAvailabilitiesForProfiles,
    sendJobStatusForProfiles,
  },
});

export const updateAsyncStateErrorForProfiles = () => ({
  type: UPDATE_ASYNC_ERROR_STATE,
  value: null,
});

export const removeProfiles = () => ({
  type: REMOVE_PROFILES,
});

export const updateAssignedPoolsInProfile = (
  profileId: number,
  poolItem: Pool,
) => ({
  type: UPDATE_ASSIGNED_POOLS_IN_PROFILE,
  value: {
    profileId: String(profileId),
    poolItem,
  },
});

export const updateAddOrRemovedProfileInPools = (
  profileId: number,
  updatedPoolsPayload: UserPoolSelection[],
) => ({
  type: UPDATE_ADD_OR_REMOVED_PROFILE_IN_POOLS,
  value: {
    profileId: String(profileId),
    updatedPoolsPayload,
  },
});

export const updateLatestInteractionInProfile = (
  profileId: number,
  profileType: ProfileTypes,
) => ({
  type: UPDATE_LATEST_INTERACTION_IN_PROFILE,
  value: {
    profileId: String(profileId),
    profileType,
  },
});

export const createConnectionRequestedAction = (
  profileId: number,
  connectionType: ConnectionTypes,
) => ({
  type: CONNECTION_REQUESTED,
  value: {
    profileId,
    connectionType,
  },
});

export const createConnectionRedeemedAction = (
  profileId: number,
  connection: Connection,
) => ({
  type: CONNECTION_REDEEMED,
  value: {
    profileId,
    connection,
  },
});

export const createUncoupledSendJobRedeemedAction = (
  profileId: number,
  status: SendJobStatus,
) => ({
  type: UNCOUPLED_SEND_JOB_REDEEMED,
  value: {
    profileId,
    status,
  },
});

export const createConnectionFailedAction = (
  profileId: number,
  connectionType: ConnectionTypes,
) => ({
  type: CONNECTION_FAILED,
  value: {
    profileId,
    connectionType,
  },
});
