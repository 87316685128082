import type { Action, Nations } from '../../types';

import * as actions from './actions';

export const overrideSettings = {
  SEARCH_EXPERIMENT_OVERRIDE: 'searchExperimentOverride',
  BETA_ENVIRONMENT: 'betaEnvironment',
} as const;

export interface State {
  searchExperiment: string | null;
  searchExperimentOverride: string | null;
  nation: Nations | null;
  betaEnvironment: string | null;
  // DEPRECATED: due to the introduction of gRTW, we will use new key
  recentSearchesInfo: string | null;
  recentSearchesInfoV2: string | null;
  ethicalHiring: string | null;
  accessCvEmail: string | null;
  oneTimePopovers: string | null;
  sendMessageHirerDetails: string | null;
  lastKnownAccessMethod: string | null;
  direct: string | null;
  userSessionId: string | null;
  featureHighlights: string | null;
  suppressSendJobModal: string | null;
  recentATSJobSearch: string | null;
  enPseudoMode: string | null;
  preferredAdvertiserId: string | null;
  showMarketSelectorInfoDialogue: string | null;
  betaVerifiedSignalsAlertDismissed: string | null;
  recommendedCandidatesAlert: string | null;
  welcomeModalDismissed: string | null;
}

export const initialState: State = {
  searchExperiment: null,
  searchExperimentOverride: null,
  nation: null,
  betaEnvironment: null,
  // DEPRECATED: will be removed after gRTW is released and will switch to use recentSearchesInfoV2 instead
  recentSearchesInfo: null,
  recentSearchesInfoV2: null,
  ethicalHiring: null,
  accessCvEmail: null,
  oneTimePopovers: null,
  sendMessageHirerDetails: null,
  lastKnownAccessMethod: null,
  direct: null,
  userSessionId: null,
  featureHighlights: null,
  suppressSendJobModal: null,
  recentATSJobSearch: null,
  enPseudoMode: null,
  preferredAdvertiserId: null,
  showMarketSelectorInfoDialogue: null,
  betaVerifiedSignalsAlertDismissed: null,
  recommendedCandidatesAlert: 'visible',
  welcomeModalDismissed: null,
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default (state = initialState, action: Action) => {
  switch (action.type) {
    case actions.UPDATE_SETTING_BY_KEY: {
      const { key, value } = action.value;
      return { ...state, [key]: value };
    }

    default:
      return state;
  }
};
