import { type CreditRates, CreditRateTypes } from '../../types';

import hasSufficientCredits from './hasSufficientCredits';

interface Params {
  isUsed: boolean;
  credits: number;
  creditRates: CreditRates;
}

function canSendMessage({ isUsed, credits, creditRates }: Params): boolean {
  return (
    !isUsed &&
    hasSufficientCredits(credits, creditRates[CreditRateTypes.SendMessage])
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default canSendMessage;
