import { createContext } from 'react';

import { defaultDatadogConfig } from 'src/config/datadog';
import { type AppConfig, CountryCodes, Environment, Language } from 'src/types';

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default createContext<AppConfig>({
  TRACKING_URL: '',
  APP_NAME: 'talent-search-ui',
  APP_VERSION: '',
  BRANCH_NAME: '',
  ROUTER_BASE_NAME: '',
  TEALIUM_URL: '',
  HIRER_GRAPH_URL: '',
  ENVIRONMENT: Environment.Production,
  COUNTRY_CODE: CountryCodes.AU,
  IS_LEGACY_SITE: true,
  LANGUAGE: Language.EN,
  DATADOG_RUM_CONFIG: defaultDatadogConfig,
  LOCALE: 'en-AU',
  SUPPORTED_LANGUAGES: [Language.EN],
  SITE: 'employer-seek-au',
});
