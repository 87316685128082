import { useHistory, useLocation } from 'react-router-dom';

import errorService from '../../services/error';
import queryStringService from '../../services/location/queryStringService';
import type { AxiosOrGraphQLError, ErrorTypes } from '../../types';
import useAppConfig from '../useAppConfig';
import type { useRoutes } from '../useRoutes';

export interface Router {
  /**
   * Returns useLocation hook pathname and search in original format
   */
  location: ReturnType<typeof useLocation>;
  /**
   * Redirects (with full page reload) to "/"
   */
  redirectToStartPage: () => void;
  /**
   * Navigates to /error/:errorType route
   * @param {ErrorTypes} errorType - Navigates to custom error page
   */
  navigateToError: (
    routes: ReturnType<typeof useRoutes>['routes'],
    errorType: ErrorTypes,
    context?: {
      err?: AxiosOrGraphQLError;
      jobId?: number;
      advertiserId?: number;
    },
  ) => void;

  /**
   * Uses history.push to update params
   * @param {object} query - Object containing next params. Null or empty params will be omitted.
   */
  updateSearchParams: <T extends Record<string, unknown>>(query: T) => void;
  /**
   * Uses history.replace to update params
   * @param {object} query - Object containing next params. Null or empty params will be omitted.
   */
  replaceSearchParams: <T extends Record<string, unknown>>(query: T) => void;
  /**
   * Update the location.href with new path
   * @param path new path to change
   * @returns void
   */
  forwardToPath: (path: string) => void;
}

function getNextSearch(search: string, query: Record<string, unknown>): string {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, nextSearch] = queryStringService.updateParams(
    location.search,
    query,
  );
  return `?${nextSearch}`;
}

function useRouter(): Router {
  const location = useLocation();
  const { push, replace } = useHistory();

  const { ROUTER_BASE_NAME } = useAppConfig();

  return {
    location,
    updateSearchParams: (query) => {
      push({
        pathname: location.pathname,
        search: getNextSearch(location.search, query),
      });
    },
    replaceSearchParams: (query) => {
      replace({
        pathname: location.pathname,
        search: getNextSearch(location.search, query),
      });
    },
    redirectToStartPage: () => {
      // TODO: does this work now?
      window.location.replace(ROUTER_BASE_NAME);
    },
    navigateToError: (routes, errorType, context = {}) => {
      const { err, ...restContext } = context;

      const details = err ? errorService.getDetailsFromError(err) : null;
      const code = details?.opaqueErrorId
        ? details.opaqueErrorId
        : errorService.mapAPIErrorCodeToFriendlyCode(details?.apiErrorCode);

      const url = routes.error({
        errorType,
        code,
        advertiserId: restContext.advertiserId,
        jobId: restContext.jobId,
      });

      push(url);
    },
    forwardToPath: (path: string) => {
      window.location.href = path;
    },
  };
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default useRouter;
