import hasLocationFoundInCvOnSummary from '../../../services/profile/hasLocationFoundInCvOnSummary';
import hasRoleFoundInCV from '../../../services/profile/hasRoleFoundInCV';
import getIsApproachableValue from '../../../services/profile/isApproachable';
import type { ProfileV2 } from '../../../types';

import getHasResumeValue from './getHasResumeValue';
import getProfileType from './getProfileType';

function getDefaultProfileFields(profile: ProfileV2) {
  const profilePrivacy = profile.profilePrivacy;
  const profileType = getProfileType(profile);
  const hasResume = getHasResumeValue(profile);
  const roleFoundInCv = hasRoleFoundInCV(profile);
  const isApproachable = getIsApproachableValue(profile);
  const showLocationOnSummary = hasLocationFoundInCvOnSummary(profile);

  return {
    profileId: profile.profileId,
    profileType,
    hasResume,
    profilePrivacy,
    isApproachable,
    roleFoundInCv,
    showLocationOnSummary,
  };
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default getDefaultProfileFields;
