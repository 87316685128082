import {
  SalaryTypeOptions_Hourly,
  SalaryTypeOptions_Annual,
  type SavedSearch,
} from '../../types';
import { SalaryFrequency } from '../../types/generated';

const filterEmptyValues =
  ({ criteria }: { criteria: any }) =>
  (key: string) =>
    Array.isArray(criteria[key])
      ? criteria[key].length > 0
      : criteria[key] !== '';

const DEFAULTS = {
  [SalaryFrequency.Annual]: SalaryTypeOptions_Annual,
  [SalaryFrequency.Hourly]: SalaryTypeOptions_Hourly,
};

const createDefaultLegacySearchCriteria = (
  searchCriteria: SavedSearch['criteria'],
): any => {
  const ANNUAL_DEFAULTS = DEFAULTS[SalaryFrequency.Annual];
  const { salaryNation = ANNUAL_DEFAULTS.NATION } = searchCriteria;

  return {
    nation: salaryNation,
    locationList: [],
    minSalary: undefined,
    maxSalary: undefined,
    salaryType: SalaryFrequency.Annual,
    salaryUnspecified: false,
  };
};

function formatEmptyLegacySearchCriteria(
  savedSearches: SavedSearch[],
): SavedSearch[] {
  return savedSearches.map((search) => {
    const { name, createdDate, criteriaHash } = search;
    return {
      name,
      createdDate,
      criteriaHash,
      criteria: Object.keys(search.criteria)
        .filter(filterEmptyValues(search))
        .reduce((acc, key) => {
          const { criteria: searchCriteria } = search;
          const criteriaValue = (searchCriteria as any)[key];

          return {
            ...createDefaultLegacySearchCriteria(searchCriteria as any),
            ...acc,
            [key]: criteriaValue,
          };
        }, {}) as SavedSearch['criteria'],
    };
  });
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default formatEmptyLegacySearchCriteria;
