/* eslint-disable @typescript-eslint/no-shadow */

export enum SeekType {
  Coupled = 'coupled',
  Uncoupled = 'uncoupled',
}

export enum AccountType {
  Premium = 'premium',
  Standard = 'standard',
  Connected = 'connected',
}

export enum UtagActions {
  View = 'view',
  Link = 'link',
}

export enum CandidateViewTypes {
  FullProfileView = 'candidate_profile_tab_pressed',
  SearchResultsView = 'search_results_displayed',
  CandidateCvPreviewed = 'candidate_cv_displayed',
  InteractionsView = 'interactions_displayed',
  NoInteractionsView = 'no_interactions_displayed',
}

export enum Global {
  HideNamesToggleOn = 'ethical_hiring_toggle_on_pressed',
  HideNamesToggleOff = 'ethical_hiring_toggle_off_pressed',
  LocationToggleOn = 'location_toggle_on_pressed',
  LocationToggleOff = 'location_toggle_off_pressed',
  Credits = 'dashboard_credits_pressed',
  AccountSwitch = 'account_switched_succeeded',
}

export enum Pooling {
  PoolClick = 'talent_pool_pressed',
  PoolEdit = 'talent_pool_edit_succeeded',
  PoolDelete = 'talent_pool_delete_succeeded',
  PoolCreate = 'talent_pool_create_succeeded',
  PoolAddCandidate = 'talent_pool_add_candidate_succeeded',
  PoolRemoveCandidate = 'talent_pool_remove_candidate_succeeded',
  PoolShare = 'talent_pool_share_succeeded',
  PoolUnshare = 'talent_pool_unshare_succeeded',
  PoolCopy = 'talent_pool_copy_pressed',
  PoolViewed = 'talent_pool_detail_displayed',
  PoolsViewed = 'talent_pools_displayed',
}

export enum Profile {
  ConnectionHistoryExpand = 'connection-history-expanded',
  PoolAdd = 'talent_pool_add_candidate_succeeded',
  PoolRemove = 'talent_pool_remove_candidate_succeeded',
  SimilarCandidateClick = 'similar_candidate_pressed',
  SimilarCandidatesSeeAllClick = 'similar_candidate_see_all_pressed',
  ProfileTabClick = 'candidate_profile_tab_pressed',
  CVPreviewTabClick = 'candidate_cv_displayed',
  InteractionsTabClick = 'candidate_interactions_tab_pressed',
  NoInteractionsTabClick = 'candidate_no_interactions_tab_pressed',
  InteractionsBadgeClick = 'interactions_badge_pressed',
  InteractionsTooltipClick = 'interactions_tooltip_pressed',
  LatestInteractionLinkClick = 'lastest_interaction_link_pressed',
  SendJobClick = 'send_job_pressed',
  SendJobCompleted = 'send_job_succeeded',
  SendMessageClick = 'send_message_pressed',
  SendMessageCompleted = 'send_message_succeeded',
  SendMessageViewed = 'sent_message_displayed',
  AccessCVClick = 'access_cv_pressed',
  AccessCVCompleted = 'access_cv_succeeded',
  ViewProfileLinkClick = 'view_profile_link_pressed',
  CVDownloaded = 'cv_download_succeeded',
  // TSC events
  ExportAtsProfileToJobClick = 'export_ats_profile_to_job_pressed',
  ExportAtsProfileToJobCompleted = 'export_ats_profile_to_job_succeeded',
}

export enum SimilarCandidates {
  CardClick = 'similar_candidate_pressed',
  SeeAllClick = 'similar_candidate_see_all_pressed',
  LocationToggleOn = 'location_toggle_on_pressed',
  LocationToggleOff = 'location_toggle_off_pressed',
}

export enum Search {
  SaveSearch = 'search_saved_succeeded',
  SuggestedLocationClick = 'suggested_location_pressed',
}

export enum FreemiumNudgeBanner {
  ButtonClicked = 'job_upgrade_button_pressed',
  ButtonViewed = 'job_upgrade_button_viewed',
}

export enum Page {
  Search = 'start-search',
  SearchResults = 'search-results-page',
  Pooling = 'talent-pools',
  PoolingChannelFollowers = 'company-follower',
  PoolingChannelPools = 'pools',
  Profile = 'full-profile',
  SimilarCandidates = 'view-profiles-like-this',
  RecommendedCandidates = 'recommended-candidates',
  Unknown = '',
}
