import { ProfileListDefaults } from '../../types';

function getProfilePosition(pageNumber: number, activeIndex: number): number {
  return (
    (pageNumber - 1) * ProfileListDefaults.ItemsPerPage + (activeIndex + 1)
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default getProfilePosition;
