import { useMemo } from 'react';

export interface UseWindowProps {
  scrollToTop: (behavior?: Behaviors) => void;
  scrollTo: (left?: number, top?: number) => void;
  scroll: (top?: number, useOffset?: boolean, behavior?: Behaviors) => void;
  open: (url: string, target: string, features?: string) => void;
}

export enum Behaviors {
  Smooth = 'smooth',
  Auto = 'auto',
}

export const HEADER_OFFSET = 80;

function useWindow(): UseWindowProps {
  return useMemo(() => {
    const open = (url: string, target: string, features?: string): void => {
      window.open(url, target, features);
    };

    const scrollTo = (left = 0, top = 0): void => {
      window.scrollTo({ left, top });
    };

    const scrollToTop = (behavior: Behaviors = Behaviors.Smooth): void => {
      setTimeout(() => window.scroll({ top: 0, behavior }));
    };

    const scroll = (
      top = 0,
      useOffset = false,
      behavior: Behaviors = Behaviors.Auto,
    ): void => {
      const topValue = useOffset ? top - HEADER_OFFSET : top;
      setTimeout(() => window.scroll({ top: topValue, behavior }));
    };

    return {
      scrollToTop,
      scrollTo,
      scroll,
      open,
    };
  }, []);
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default useWindow;
