// Default exports can be ignored for this file. sku templates provide default exports as standard here
/* eslint-disable import/no-default-export */

import { hydrateRoot } from 'react-dom/client';
import { Provider as ReduxStoreProvider } from 'react-redux';

import App from './common/App/App';
import { createAppConfig } from './config/createAppConfig';
import { AppConfigProvider } from './hooks/useAppConfig';
import { RouterProvider, createHistory } from './hooks/useRouter';
import { RoutesProvider } from './hooks/useRoutes';
import routes from './routes';
import createStore from './store';
import type { ClientContext } from './types';

export default (clientContext: ClientContext) => {
  //  Builds on the config by substituting values
  //  that are only available from the client side
  const config = createAppConfig(clientContext);
  const store = createStore(config);
  const history = createHistory();

  hydrateRoot(
    document.getElementById('app')!,
    <ReduxStoreProvider store={store}>
      <RouterProvider
        routes={[
          ...routes.unrestricted,
          routes.recommendedCandidates,
          ...routes.restricted,
        ]}
        history={history}
      >
        <AppConfigProvider config={config}>
          <RoutesProvider
            environment={config.ENVIRONMENT}
            locale={config.LOCALE}
            site={config.SITE}
          >
            <App routes={routes} />
          </RoutesProvider>
        </AppConfigProvider>
      </RouterProvider>
    </ReduxStoreProvider>,
  );
};
