import { PrivacyTypes, type ProfileV2 } from '../../../types';

function getProfileTypes({
  hasResume,
  profilePrivacy,
  approachabilitySignal,
  workHistories,
  profileType,
}: ProfileV2) {
  return [
    hasResume && profilePrivacy.toString() === PrivacyTypes.Standard
      ? 'cv_available'
      : 'cv_unavailable',
    profilePrivacy,
    approachabilitySignal && approachabilitySignal === 2
      ? 'approachability_signal'
      : 'none',
    workHistories && workHistories.find((w) => w.foundInCV)
      ? 'role_found_in_cv'
      : 'none',
    profileType,
  ];
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default getProfileTypes;
