import type { Locale } from '@seek/scalars';
import {
  absoluteDateFormatter,
  durationFormatter,
} from '@seek/unified-display-date-time';
import type { AbsoluteDateFormat } from '@seek/unified-display-date-time/lib-types/absolute-date-formatter';
import type {
  DateLabelFormat,
  DurationUnit,
} from '@seek/unified-display-date-time/lib-types/types';
import { DateTime } from 'luxon';

import type { Maybe } from 'src/types/generated';

export const formatDuration = (
  startDate: Maybe<Date> | undefined,
  endDate: Maybe<Date> | undefined,
  durationUnits?: DurationUnit[],
  format?: DateLabelFormat,
  locale?: Locale,
): string => {
  if (!startDate || !endDate) return '';

  const unifiedDate = durationFormatter({
    startDate,
    endDate,
    durationUnits,
    format,
    locale,
  });

  return unifiedDate.isValid ? unifiedDate.formattedDuration : '';
};

export const formatAbsoluteDate = (
  date: Maybe<Date> | undefined,
  format: AbsoluteDateFormat,
  timezone?: string,
  locale?: Locale,
): string => {
  if (!date) return '';
  const unifiedDisplayDate = absoluteDateFormatter({
    date,
    format,
    timezone,
    locale,
  });

  return unifiedDisplayDate.isValid ? unifiedDisplayDate.formattedDate : '';
};

export const getRelativeDuration = (
  inputDate: Maybe<string> | undefined,
  formatVocabKey: string,
  format: DateLabelFormat,
  t: (key: string, param?: Record<string, any>) => string,
  locale?: Locale,
): string => {
  if (!inputDate) return '';

  const inputLuxonDate = DateTime.fromISO(inputDate).startOf('day');
  if (!inputLuxonDate.isValid) return '';

  const nowLuxonDate = DateTime.local().startOf('day');

  const {
    years = 0,
    months = 0,
    days = 0,
  } = nowLuxonDate.diff(inputLuxonDate, ['years', 'months', 'days']).toObject();

  let dUnit: DurationUnit[];
  if (Math.abs(years) >= 1) {
    dUnit = ['years', 'months'];
  } else if (Math.abs(months) >= 1) {
    dUnit = ['months'];
  } else if (Math.abs(days) >= 1) {
    dUnit = ['days'];
  } else return t('TODAY');

  const formattedDuration = formatDuration(
    inputLuxonDate.toJSDate(),
    nowLuxonDate.toJSDate(),
    dUnit,
    format,
    locale,
  );

  return t(formatVocabKey, { duration: formattedDuration });
};

export const isToday = (inputDate: string): boolean => {
  const inputLuxonDate = DateTime.fromISO(inputDate).startOf('day');
  const nowLuxonDate = DateTime.local().startOf('day');

  return inputLuxonDate.hasSame(nowLuxonDate, 'day');
};

export const formatTime = (jsDateString: string) => {
  if (jsDateString) {
    const luxonDateTime = DateTime.fromJSDate(new Date(jsDateString));
    if (luxonDateTime.isValid) {
      return luxonDateTime.toFormat('h:mm a').toLocaleLowerCase();
    }
  }
  return '';
};
