import queryStringService, {
  parsers,
} from '../../../../services/location/queryStringService';
import isEqual from '../../../../services/utils/isEqual';
import { Search } from '../../../../types';

//  The only parameters that should trigger the
//  generation of a searchId - everything else is excluded
const allowList = Object.entries(Search.CriteriaKey).reduce<string[]>(
  (acc, [_, value]) => [
    ...acc,
    ...(value !== Search.CriteriaKey.PageNumber ? [value] : []),
  ],
  [],
);

const extractValidParams = (search: string) =>
  Object.entries(queryStringService.parse(search)).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(allowList.includes(key) && { [key]: value }),
    }),
    {},
  );

const haveValidSearchParamsChanged = (prevSearch: string, nextSearch: string) =>
  !isEqual(extractValidParams(prevSearch), extractValidParams(nextSearch));

const hasSameSearchBeenReRun = (prevSearch: string, nextSearch: string) => {
  const { searchId: prevSearchId } = queryStringService.parseParams(
    prevSearch,
    {
      searchId: { type: parsers.string },
    },
  );
  const { searchId: nextSearchId } = queryStringService.parseParams(
    nextSearch,
    {
      searchId: { type: parsers.string },
    },
  );
  return prevSearchId !== nextSearchId;
};

export const shouldGenerateSearchId = (
  prevSearch: string,
  nextSearch: string,
) =>
  haveValidSearchParamsChanged(prevSearch, nextSearch) ||
  hasSameSearchBeenReRun(prevSearch, nextSearch);
