
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoi4LiE4Li44LiT4Lih4Li14LmA4Lin4Lil4Liy4LmA4Lir4Lil4Li34LitIHtkYXlzUmVtYWluaW5nfSDguKrguLPguKvguKPguLHguJrguIHguLLguKPguJfguJTguKXguK3guIfguYPguIrguYkgUHJlbWl1bSBUYWxlbnQgU2VhcmNoIiwiTl9EQVlTIjoie2RheXN9IOC4p+C4seC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoi4LiE4Li44LiT4Lih4Li14LmA4Lin4Lil4Liy4LmA4Lir4Lil4Li34LitIHtkYXlzUmVtYWluaW5nfSDguKrguLPguKvguKPguLHguJrguIHguLLguKPguJfguJTguKXguK3guIfguYPguIrguYkgUHJlbWl1bSBUYWxlbnQgU2VhcmNoIiwiTl9EQVlTIjoie2RheXN9IOC4p+C4seC4mSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiTWFzaWggdGVyc2lzYSB7ZGF5c1JlbWFpbmluZ30gbGFnaSBkaSBGcmVlIFRyaWFsIFByZW1pdW0gVGFsZW50IFNlYXJjaCBBbmRhLiIsIk5fREFZUyI6IntkYXlzfSBoYXJpIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiTWFzaWggdGVyc2lzYSB7ZGF5c1JlbWFpbmluZ30gbGFnaSBkaSBGcmVlIFRyaWFsIFByZW1pdW0gVGFsZW50IFNlYXJjaCBBbmRhLiIsIk5fREFZUyI6IntkYXlzfSBoYXJpIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoi4LiE4Li44LiT4Lih4Li14LmA4Lin4Lil4Liy4LmA4Lir4Lil4Li34LitIHtkYXlzUmVtYWluaW5nfSDguKrguLPguKvguKPguLHguJrguIHguLLguKPguJfguJTguKXguK3guIfguYPguIrguYkgUHJlbWl1bSBUYWxlbnQgU2VhcmNoIiwiTl9EQVlTIjoie2RheXN9IOC4p+C4seC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoi4LiE4Li44LiT4Lih4Li14LmA4Lin4Lil4Liy4LmA4Lir4Lil4Li34LitIHtkYXlzUmVtYWluaW5nfSDguKrguLPguKvguKPguLHguJrguIHguLLguKPguJfguJTguKXguK3guIfguYPguIrguYkgUHJlbWl1bSBUYWxlbnQgU2VhcmNoIiwiTl9EQVlTIjoie2RheXN9IOC4p+C4seC4mSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiTWFzaWggdGVyc2lzYSB7ZGF5c1JlbWFpbmluZ30gbGFnaSBkaSBGcmVlIFRyaWFsIFByZW1pdW0gVGFsZW50IFNlYXJjaCBBbmRhLiIsIk5fREFZUyI6IntkYXlzfSBoYXJpIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiTWFzaWggdGVyc2lzYSB7ZGF5c1JlbWFpbmluZ30gbGFnaSBkaSBGcmVlIFRyaWFsIFByZW1pdW0gVGFsZW50IFNlYXJjaCBBbmRhLiIsIk5fREFZUyI6IntkYXlzfSBoYXJpIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiWW91IGhhdmUge2RheXNSZW1haW5pbmd9IGxlZnQgb24geW91ciBQcmVtaXVtIFRhbGVudCBTZWFyY2ggVHJpYWwuIiwiTl9EQVlTIjoie2RheXN9IGRheXMifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiW8Odw7bDtseax5og4bipxIPEg+G5veG6veG6vSB7ZGF5c1JlbWFpbmluZ30gxprhur3hur3hur3GkuG5ryDDtsO2w7bguIHguLXguYkgw73DvcO9w7bDtsO2x5rHmseaxZkgxqTFmeG6veG6veG6vW3MgsOsw6zDrMeax5rHmm3MgiDhua7Eg8SDxIPGmuG6veG6veG6veC4geC4teC5ieG5ryDFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkg4bmuxZnDrMOsw6zEg8SDxIPGmi5dIiwiTl9EQVlTIjoiW3tkYXlzfSDGjMSDxIPDvcO9xaFdIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEQVlTX0xFRlRfT05fUFJFTUlVTV9UQUxFTlRfU0VBUkNIX1RSSUFMIjoiW8Odw7bDtseax5og4bipxIPEg+G5veG6veG6vSB7ZGF5c1JlbWFpbmluZ30gxprhur3hur3hur3GkuG5ryDDtsO2w7bguIHguLXguYkgw73DvcO9w7bDtsO2x5rHmseaxZkgxqTFmeG6veG6veG6vW3MgsOsw6zDrMeax5rHmm3MgiDhua7Eg8SDxIPGmuG6veG6veG6veC4geC4teC5ieG5ryDFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkg4bmuxZnDrMOsw6zEg8SDxIPGmi5dIiwiTl9EQVlTIjoiW3tkYXlzfSDGjMSDxIPDvcO9xaFdIn0=!"
        )
      )
      });
  
      export { translations as default };
    