import { gql } from '@apollo/client';

export const GET_BALANCE = gql`
  query GetTalentSearchBudgetBalance {
    talentSearchBudgetBalance {
      initialBalance
      currentBalance
    }
  }
`;
