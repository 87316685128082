import { createContext } from 'react';

export interface ContextType {
  fetchAndUpdateBalance: (jobId?: number | null) => Promise<number>;
  updateBalance: (amount: number) => void;
  deductCredits: (amount: number) => void;
  balance: number;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default createContext<ContextType>({
  fetchAndUpdateBalance: () => Promise.resolve(0),
  updateBalance: () => {},
  deductCredits: () => {},
  balance: 0,
});
