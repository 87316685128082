import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import useMediaQuery from '../../hooks/useMediaQuery';
import useWindow from '../../hooks/useWindow';
import queryStringService, {
  parsers,
} from '../../services/location/queryStringService';

import Context from './Context';
import type { Options } from './types';

import * as styles from './ScrollbarProvider.css';

interface Props {
  children: React.ReactNode;
}

export const DEFAULT_OPTIONS = {
  timeoutMs: 0,
  timeoutMsMobile: 500, // this delay caters for the body being hidden,
};

export const ROOT_ELEMENT = 'body';

export const sideEffects = {
  getScrollbarWidth: () =>
    window.innerWidth -
    document.getElementsByTagName(ROOT_ELEMENT)[0].clientWidth,
  setDocumentWidth: (nextWidth: string) => {
    document.getElementsByTagName(ROOT_ELEMENT)[0].style.width = nextWidth;
  },
  removeDocumentWidth: () => {
    document
      .getElementsByTagName(ROOT_ELEMENT)[0]
      .style.removeProperty('width');
  },
  addDisabledClassName: (s: typeof styles) => {
    document.getElementsByTagName(ROOT_ELEMENT)[0].classList.add(s.disabled);
  },
  removeDisabledClassName: (s: typeof styles) => {
    document.getElementsByTagName(ROOT_ELEMENT)[0].classList.remove(s.disabled);
  },
  getScrollPosition: () => window.scrollY,
  setScrollRestoration: (newValue: string) => {
    if (window && 'scrollRestoration' in window.history) {
      window.history.scrollRestoration = newValue as any;
    }
  },
};

function ScrollbarProvider({ children }: Props) {
  const activeHandlerIds = useRef<string[]>([]);
  const { isMobile } = useMediaQuery();
  const { pathname, search } = useLocation();
  const { scrollTo } = useWindow();

  const { pageNumber } = useMemo(
    () =>
      queryStringService.parseParams(search, {
        pageNumber: { type: parsers.number, defaultValue: null },
      }),
    [search],
  );

  useEffect(() => {
    sideEffects.setScrollRestoration('manual');
  }, []);

  useEffect(() => {
    scrollTo(0, 0);
  }, [pathname, pageNumber, scrollTo]);

  const value = useMemo(() => {
    let timeout: any;
    let currentScrollPosition: any;

    const disableScrollbar = (handlerId: string) => (options?: Options) => {
      const { timeoutMs, timeoutMsMobile } = { ...DEFAULT_OPTIONS, ...options };

      if (!activeHandlerIds.current.includes(handlerId)) {
        activeHandlerIds.current = activeHandlerIds.current.concat([handlerId]);

        //  We only need to lock the the <body /> scroll
        //  when the first component requests it, all other
        //  calls to lock the scrollbar will just increment the count
        if (activeHandlerIds.current.length === 1) {
          if (isMobile) {
            currentScrollPosition = sideEffects.getScrollPosition();
          }

          timeout = setTimeout(
            () => {
              sideEffects.setDocumentWidth(
                `calc(100vw - ${sideEffects.getScrollbarWidth()}px)`,
              );
              sideEffects.addDisabledClassName(styles);
            },
            isMobile ? timeoutMsMobile : timeoutMs,
          );
        }
      }
    };

    const enableScrollbar = (handlerId: string) => () => {
      if (activeHandlerIds.current.includes(handlerId)) {
        activeHandlerIds.current = activeHandlerIds.current.filter(
          (id) => id !== handlerId,
        );

        //  If the last element that requires a scroll lock
        //  has been unmounted, then we need to restore
        //  the original styles of the <body />
        if (activeHandlerIds.current.length === 0) {
          sideEffects.removeDocumentWidth();
          sideEffects.removeDisabledClassName(styles);

          if (isMobile) {
            scrollTo(0, currentScrollPosition);
          }
          //  Clear the timeout
          clearTimeout(timeout);
        }
      }
    };

    return {
      enableScrollbar,
      disableScrollbar,
    };
  }, [isMobile, scrollTo]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default ScrollbarProvider;
