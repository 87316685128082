import { useMemo, useRef } from 'react';

import {
  type User,
  type TalentSearchFeature,
  ACL,
  type FeatureFlags,
} from '../../types';

import Context from './Context';
import {
  productSceneLinkConfig,
  productFeatureConfig,
  productSearchFilterConfig,
  enabledFeatureFlags,
} from './config';
import type { ProductConfig, ContextType } from './types';

interface TestParams {
  isConnectedTalentSearchUser: boolean;
  isPremium: boolean;
  isIndirectUser: boolean;
}

const productTypeMap = [
  {
    test: ({ isConnectedTalentSearchUser }: TestParams) =>
      isConnectedTalentSearchUser,
    productType: ACL.PRODUCT_TYPE.CTS,
  },
  {
    test: ({ isPremium, isIndirectUser }: TestParams) =>
      isPremium && !isIndirectUser,
    productType: ACL.PRODUCT_TYPE.PTS,
  },
  {
    test: ({ isPremium, isIndirectUser }: TestParams) =>
      !isPremium && !isIndirectUser,
    productType: ACL.PRODUCT_TYPE.STS,
  },
];

interface Props {
  children: React.ReactNode;
}

const DEFAULT_USER_PARAMS = {
  isPremium: false,
  isConnectedTalentSearchUser: false,
  isIndirectUser: false,
} as const;

export const ACLProvider = ({ children }: Props) => {
  const productTypeRef = useRef<ACL.ProductType>(ACL.PRODUCT_TYPE.STS);

  const productConfigRef = useRef<ProductConfig>({
    links: [],
    features: [],
    filters: [],
    featureFlags: [],
  });

  const value: ContextType = useMemo(
    () => ({
      setUser: (
        { advertiser, user }: User,
        featureFlags: TalentSearchFeature[],
      ) => {
        const userParams = {
          ...DEFAULT_USER_PARAMS,
          isPremium: advertiser.isPremium,
          ...(user
            ? {
                isConnectedTalentSearchUser: user.isConnectedTalentSearchUser,
              }
            : {
                isIndirectUser: true,
              }),
        };

        const { productType } = productTypeMap.find(({ test }) =>
          test(userParams),
        ) || {
          productType: ACL.PRODUCT_TYPE.STS,
        };

        //  Store a references to the active product type
        productTypeRef.current = productType;

        //  Store a references to all flattened available features for this product type
        const featuresForProduct = (
          productType in productFeatureConfig
            ? productFeatureConfig[productType]
            : productFeatureConfig.STS
        ) as Record<string, ACL.FeatureKey[]>;

        //  Flattens all features into a single array for easier look-up
        let productFeatures: ACL.FeatureKey[] = [];
        for (const features of Object.values(featuresForProduct)) {
          productFeatures = productFeatures.concat(features);
        }

        //  Configuration of available menu links
        const productLinks = (
          productType in productSceneLinkConfig
            ? productSceneLinkConfig[productType]
            : productSceneLinkConfig.STS
        ) as ACL.SceneLink[];

        //  Configuration of available search filters
        const productFilters =
          productType in productSearchFilterConfig
            ? productSearchFilterConfig[productType]
            : productSearchFilterConfig.STS;

        productConfigRef.current = {
          links: productLinks,
          features: productFeatures,
          filters: productFilters,
          featureFlags,
        };
      },
      hasAccess: (featureKey: ACL.FeatureKey) =>
        productConfigRef.current.features.includes(featureKey),
      getProductType: () => productTypeRef.current,
      getProductConfig: () => productConfigRef.current,
      // Check if featureFlags[].active OR enabledFeatureFlags[] contains the key
      hasFeatureFlag: (key: FeatureFlags) =>
        (
          productConfigRef.current.featureFlags.find(
            (flag) => flag.key === key,
          ) || { active: false }
        ).active || enabledFeatureFlags.includes(key),
    }),
    [],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
