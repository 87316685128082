import {
  Profile as ProfileLinks,
  UtagActions,
} from '../../metrics/tealium/types';
import { TEALIUM_KEY } from '../tealium';

const actions = {
  latestInteractionLinkClick: () => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const eventName = ProfileLinks.LatestInteractionLinkClick;

        return {
          action: UtagActions.Link,
          value: {
            eventName,
          },
        };
      },
    },
  ],
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default actions;
