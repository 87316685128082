import { GET_METADATA_COMPANY_SUGGEST } from '../../../queries/TalentSearchMetadata';
import type { Services } from '../../../types';

const DEFAULT_SIZE = 25;

const dataService: Services.DataService<
  string[],
  { query: string; size?: number }
> = ({ query }) =>
  async function getCompanySuggest({ query: companyQuery }) {
    const data = await query<{
      talentSearchMetadataCompanySuggest: string[];
    }>({
      query: GET_METADATA_COMPANY_SUGGEST,
      variables: {
        query: companyQuery,
        size: DEFAULT_SIZE,
      },
    });
    return data?.talentSearchMetadataCompanySuggest || [];
  };

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default dataService;
