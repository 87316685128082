import { Box, Text } from 'braid-design-system';

import useMediaQuery from '../../../../../../hooks/useMediaQuery';

type BoxProps = React.ComponentProps<typeof Box>;
interface Props {
  children?: React.ReactNode;
  mobileLineBorder?: boolean;
  text?: string;
  mobilePaddingTop?: BoxProps['padding'];
  mobilePaddingBottom?: BoxProps['padding'];
  styleClass?: string;
}

const FooterTextWrapper = ({
  children,
  mobileLineBorder,
  text,
  mobilePaddingTop,
  mobilePaddingBottom,
  styleClass = '',
}: Props) => {
  const { isMobile, isTablet } = useMediaQuery();
  return (
    <Box
      className={styleClass}
      {...(isMobile
        ? {
            paddingTop: mobilePaddingTop,
            paddingBottom: mobilePaddingBottom,
            ...(mobileLineBorder && {
              style: { borderTop: '1px solid #d6d6d6' },
            }),
          }
        : {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            style: { minHeight: '80px' },
          })}
    >
      <Text size={isTablet ? 'small' : 'standard'}>
        {text}
        {children}
      </Text>
    </Box>
  );
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default FooterTextWrapper;
