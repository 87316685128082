import { gql } from '@apollo/client';

export const GET_SAVED_SEARCHES = gql`
  query GetTalentSearchSavedSearches($language: LanguageCodeIso) {
    talentSearchSavedSearches {
      name
      createdDate
      criteriaHash
      criteria {
        approachable
        countryCode
        keywords
        lastUpdated
        workType
        willingToRelocate
        visa
        cv
        industry
        company
        minSalary
        maxSalary
        salaryNation
        salaryType
        salaryUnspecified
        nation
        locationList
        locationDisplayTexts(language: $language)
        uncoupledFreeText
        profileTypes
        rightToWorkList {
          id
          label(language: $language)
        }
        rightToWorkUnspecified
      }
    }
  }
`;

export const DELETE_SAVED_SEARCH = gql`
  mutation DeleteSavedSearch($criteriaHash: String!) {
    deleteSavedSearch(criteriaHash: $criteriaHash) {
      deletedCriteriaHash
    }
  }
`;

export const CREATE_SAVED_SEARCH = gql`
  mutation CreateSavedSearch($input: CreateSavedSearchInput!) {
    createSavedSearch(input: $input) {
      savedSearch {
        name
        createdDate
        criteriaHash
        criteria {
          approachable
          keywords
          lastUpdated
          workType
          willingToRelocate
          visa
          cv
          industry
          company
          minSalary
          maxSalary
          salaryNation
          salaryType
          salaryUnspecified
          nation
          locationList
          uncoupledFreeText
          profileTypes
          rightToWorkList {
            id
          }
          rightToWorkUnspecified
        }
      }
    }
  }
`;
