import { useMemo, useContext, useRef } from 'react';
import { v4 as generateUuid } from 'uuid';

import Context from './Context';

function useScrollbar() {
  const handlerId = useRef<string>(generateUuid());
  const { enableScrollbar, disableScrollbar } = useContext(Context);

  return useMemo(
    () => ({
      enableScrollbar: enableScrollbar(handlerId.current),
      disableScrollbar: disableScrollbar(handlerId.current),
    }),
    [enableScrollbar, disableScrollbar],
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default useScrollbar;
