import {
  type History,
  createBrowserHistory,
  createMemoryHistory,
} from 'history';

function createHistory(): History {
  return typeof window !== 'undefined'
    ? createBrowserHistory()
    : createMemoryHistory();
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default createHistory;
