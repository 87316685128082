const connectionMethodBase = {
  isUsed: false,
  isPending: false,
  hasError: false,
};

export const INITIAL_CONNECTION_METHODS = {
  sendJob: connectionMethodBase,
  sendMessage: connectionMethodBase,
  accessCv: connectionMethodBase,
  exportProfile: connectionMethodBase,
};

export const DEFAULT_STATE = {
  assignedPools: [],
  hasConnectionHistory: false,
  latestInteraction: null,
  position: 1,
  ...INITIAL_CONNECTION_METHODS,
};
