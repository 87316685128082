import searchService from '../../services/search';
import isDeepEqual from '../../services/utils/isDeepEqual';
import type { RootState, SavedSearch } from '../../types';

export const getAllSavedSearchesSelector = (state: RootState): SavedSearch[] =>
  state.savedSearches.searches;

export const getSavedSearchesCountSelector = (state: RootState): number =>
  state.savedSearches.searches.length;

export const getSavedSearchFetchedSelector = (state: RootState): boolean =>
  Boolean(state.savedSearches.lastFetchedTimestamp);

export const findMatchingSavedSearchSelector = (
  { savedSearches: { searches } }: RootState,
  search: string,
): SavedSearch | null => {
  const activeSearchCriteria =
    searchService.getCriteriaFromSearchParams(search);
  return (
    searches.find(({ criteria }: SavedSearch) =>
      isDeepEqual(criteria as any, activeSearchCriteria),
    ) || null
  );
};
