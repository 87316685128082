import type { Action } from '../../types';

import * as actions from './actions';
import type { ReducerState } from './types';

const initialState: ReducerState = {
  key: '',
  pathname: '',
  search: '',
  params: {},
  jobId: null,
  sceneState: {},
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default (state: ReducerState = initialState, action: Action) => {
  switch (action.type) {
    case actions.UPDATE_ACTIVE_ROUTE:
      const { nextRoute } = action.value;
      return {
        ...state,
        ...nextRoute,
      };

    case actions.UPDATE_SCENE_STATE:
      const { nextSceneState } = action.value;
      return {
        ...state,
        sceneState: {
          ...state.sceneState,
          ...nextSceneState,
        },
      };

    case actions.CLEAR_SCENE_STATE:
      return {
        ...state,
        sceneState: {},
      };

    default:
      return state;
  }
};
