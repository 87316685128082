import { ToastProvider, useToast } from 'braid-design-system';
import { useState, useMemo, useCallback } from 'react';
import { v4 as generateUuid } from 'uuid';

import type { Message } from '../../types';

import Context from './Context';
import type { ContextType, Banner } from './types';

interface Props {
  children: React.ReactNode;
}

function NotificationsProvider({ children }: Props) {
  const [banners, setBanners] = useState<Banner[]>([]);
  const showToast = useToast();

  const showBanner = useCallback((message: Message) => {
    const dismissBanner = (key: string) => {
      setBanners((prevState) =>
        prevState.map((banner) => {
          if (banner.key === key) {
            return {
              ...banner,
              active: !banner.active,
            };
          }
          return banner;
        }),
      );
    };

    setBanners((prevState) => [
      ...prevState,
      {
        ...message,
        key: generateUuid(),
        active: true,
        onDismiss: dismissBanner,
      },
    ]);
  }, []);

  const context = useMemo<ContextType>(
    () => ({
      showBanner,
      showToast,
      getActiveBanner: () => banners.find(({ active }) => active) || null,
    }),
    [showToast, showBanner, banners],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default ({ children }: Props) => (
  <ToastProvider>
    <NotificationsProvider>{children}</NotificationsProvider>
  </ToastProvider>
);
