import { configureIntercom } from '@seek/hirer-intercom-adapter';
import { getLanguageFromLocale } from '@seek/melways-sites';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useAppConfig from '../hooks/useAppConfig';
import useImpersonation from '../hooks/useImpersonation';
import { selectors as userSelectors } from '../store/user';

export const IntercomConfiguration = () => {
  const { isImpersonating } = useImpersonation();
  const { SITE: site, LANGUAGE: language } = useAppConfig();
  const userFirstName = useSelector(userSelectors.getUserFirstNameSelector);
  const userLastName = useSelector(userSelectors.getUserLastNameSelector);
  const email = useSelector(userSelectors.getUserEmailSelector);

  const fullName = `${userFirstName ?? ''} ${userLastName ?? ''}`.trim();

  useEffect(() => {
    if (email && fullName && !isImpersonating) {
      configureIntercom({
        email,
        name: fullName,
        site,
        language: getLanguageFromLocale(language), // This strips the en-PSEUDO
      });
    }
  }, [email, fullName, isImpersonating, site, language]);

  return null;
};
