import { createContext } from 'react';

export interface Context {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

const Context = createContext<Context>({} as Context);

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default Context;
