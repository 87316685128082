import { Box, IconLanguage, Text } from 'braid-design-system';

import useMediaQuery from 'src/hooks/useMediaQuery';
import { LanguageToTextMap } from 'src/types';

import useAppConfig from '../../../../../../../hooks/useAppConfig';
import {
  useRoutes,
  getWindowPathWithoutLanguage,
} from '../../../../../../../hooks/useRoutes';
import MenuGroup from '../../MenuGroup/MenuGroup';

import * as styles from './MenuLanguageSwitcher.css';

const MenuLanguageSwitcher = () => {
  const { LANGUAGE, SUPPORTED_LANGUAGES } = useAppConfig();
  const { routes } = useRoutes();
  const { isDesktop } = useMediaQuery();

  if (SUPPORTED_LANGUAGES.length <= 1 || isDesktop) {
    return null;
  }

  return (
    <MenuGroup>
      <Box data-cy="language-selected">
        <MenuGroup.Title
          leftIcon={
            <Box display="inline" paddingRight="small">
              <IconLanguage data={{ testid: 'icon-language' }} />
            </Box>
          }
          text={LanguageToTextMap[LANGUAGE]}
        />
      </Box>
      <MenuGroup.Content>
        {SUPPORTED_LANGUAGES?.map((language) => {
          if (language === LANGUAGE) {
            return; // don't include if the language is current.
          }
          return (
            <Box
              key={language}
              data-name="language"
              cursor="pointer"
              padding="gutter"
              className={styles.option}
              onClick={() =>
                window.location.assign(
                  routes.urlResolver({
                    language,
                    path: getWindowPathWithoutLanguage(),
                  }),
                )
              }
            >
              <Box paddingLeft="medium">
                <Text>{LanguageToTextMap[language]}</Text>
              </Box>
            </Box>
          );
        })}
      </MenuGroup.Content>
    </MenuGroup>
  );
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default MenuLanguageSwitcher;
